import { objectToQueryString } from '@utils';

import { EApiEndpoint } from '@enums';

export const buildApiUrl = <TQueryStringObject>(
  apiEndpoint: EApiEndpoint,
  queryStringObject?: TQueryStringObject,
) => {
  const queryString = objectToQueryString<TQueryStringObject>(queryStringObject);

  return `${apiEndpoint}${queryString}`;
};
