import { Paper, Typography } from '@amperio/amperio-ui';
import { memo } from 'react';
import CountUp from 'react-countup';

import { ILeadsSummaryPaperProps } from './leads-summary-paper.types';

import './leads-summary-paper.styles.scss';

const LeadsSummaryPaperComponent = ({
  description,
  summaryCount,
}: ILeadsSummaryPaperProps): JSX.Element => (
  <Paper className="leads-summary-paper-wrapper">
    <CountUp useEasing delay={0} duration={3} end={summaryCount}>
      {({ countUpRef }) => (
        <Typography
          ref={countUpRef}
          strong
          as="span"
          className="leads-summary-paper-wrapper__lead-number"
          color="primary"
          data-testid="home-view-manager--leads-meetings-number"
        />
      )}
    </CountUp>
    <Typography strong as="h5">
      {description}
    </Typography>
  </Paper>
);

export const LeadsSummaryPaper = memo(LeadsSummaryPaperComponent);
