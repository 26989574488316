import { EEventStatus } from '@enums';

export const getEventStatusToTranslationsMapping = (
  translations: Record<string, string>,
): Record<string, string> => ({
  [EEventStatus.SALE]: translations.commonEventStatusSale,
  [EEventStatus.MISSED]: translations.commonEventStatusMissed,
  [EEventStatus.PLANNED]: translations.commonEventStatusPlanned,
  [EEventStatus.REJECTED]: translations.commonEventStatusRejected,
  [EEventStatus.RESIGNATION]: translations.commonEventStatusResignation,
  [EEventStatus.BID_SUBMITTED]: translations.commonEventStatusBidSubmitted,
  [EEventStatus.SALES_OPPORTUNITY]: translations.commonEventStatusSalesOpportunity,
});
