import { ArrowRightIcon, Button, Typography, useDeviceDetect } from '@amperio/amperio-ui';
import { useNavigate } from 'react-router-dom';

import { useQuery, useTranslation } from '@utils';

import { NoMeetingsIcon } from '@infrastructure/assets';

import { EDashboardEventsRoutes, EDashboardLeadsRoutes, EDashboardRoutes, ERoutes } from '@enums';

import { GET_DASHBOARD_SUMMARY_ACTION_KEY, getDashboardSummaryAction } from '../../api';
import { LeadsSummaryPaper, UnhandledMeetingsTable } from '../../molecules';
import { translationStrings } from './home-view-manager.defaults';

import './home-view-manager.styles.scss';

export const HomeViewManager = (): JSX.Element => {
  const navigate = useNavigate();
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  const { data: dashboardSummaryData } = useQuery(
    GET_DASHBOARD_SUMMARY_ACTION_KEY,
    getDashboardSummaryAction,
    { staleTime: Number.POSITIVE_INFINITY },
  );

  const {
    numberOfLeadsAcquiredByAmperio = 0,
    numberOfMeetings = 0,
    numberOfTodayLeads = 0,
  } = dashboardSummaryData?.data || {};

  const navigateToUpsertEventPage = (eventId: string) =>
    navigate(
      `/${ERoutes.DASHBOARD}/${EDashboardRoutes.EVENTS}/${EDashboardEventsRoutes.UPSERT_EVENT}?eventId=${eventId}`,
    );

  const navigateToLeadsList = () =>
    navigate(`/${ERoutes.DASHBOARD}/${EDashboardRoutes.LEADS}/${EDashboardLeadsRoutes.LIST}`, {
      replace: true,
    });

  return (
    <div className="home-view-manager-wrapper">
      <section>
        <Typography
          strong
          as={deviceData.isMobile ? 'h2' : 'h1'}
        >{`${translations.dashboardHomepageWelcomeMessage}!`}</Typography>
      </section>
      <section className="home-view-manager-wrapper__leads-section">
        {numberOfTodayLeads ? (
          <Typography strong as={deviceData.isMobile ? 'h4' : 'h3'}>
            {translations.dashboardHomepageManagerLeadsDescription.replace(
              '[number]',
              `${numberOfTodayLeads}`,
            )}
          </Typography>
        ) : (
          <div>
            <Typography strong as={deviceData.isMobile ? 'h4' : 'h3'}>
              {translations.dashboardHomepageManagerLeadsNoDescription}
            </Typography>
          </div>
        )}
        <Button
          className="leads-section__button"
          data-testid="home-view-manager--button-leads"
          endIcon={<ArrowRightIcon />}
          size="medium"
          variant="contained"
          onClick={navigateToLeadsList}
        >
          {translations.dashboardHomepageManagerLeadsGoTo}
        </Button>
        {!numberOfTodayLeads ? <NoMeetingsIcon className="leads-section__logo" /> : null}
        <div className="leads-section__summary">
          <Typography strong as={deviceData.isMobile ? 'h4' : 'h3'}>
            {translations.dashboardHomepageManagerLeadsSummaryLabel}
          </Typography>
          <div className="leads-section__summary-paper">
            <LeadsSummaryPaper
              description={translations.dashboardHomepageManagerLeadsSummaryLeads}
              summaryCount={numberOfLeadsAcquiredByAmperio}
            />
            <LeadsSummaryPaper
              description={translations.dashboardHomepageManagerLeadsSummaryMeetings}
              summaryCount={numberOfMeetings}
            />
          </div>
        </div>
      </section>
      <Typography strong as={deviceData.isMobile ? 'h3' : 'h4'}>
        {translations.dashboardHomepageManagerAlerts}
      </Typography>
      <section className="home-view-manager-wrapper__alerts-section">
        <Typography strong as={deviceData.isMobile ? 'h6' : 'h5'}>
          {translations.dashboardHomepageManagerAlertsMissedMeetings}
        </Typography>
        <UnhandledMeetingsTable
          className="alerts-section__table"
          onEventClick={navigateToUpsertEventPage}
        />
      </section>
    </div>
  );
};
