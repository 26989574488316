import {
  FormControlLabel,
  Switch,
  Typography,
  useDeviceDetect,
  useLocalStorage,
} from '@amperio/amperio-ui';
import { useNavigate } from 'react-router-dom';

import { MinimalCalendarWrapper } from '@ui';

import {
  transformCalendarApiResponse,
  useAccountsNamesActionQuery,
  useQuery,
  useTranslation,
} from '@utils';

import { HOMEPAGE_SHOULD_RENDER_WEEKENDS_KEY } from '@infrastructure';

import { EDashboardEventsRoutes, EDashboardRoutes, ERoutes } from '@enums';

import { GET_CALENDAR_EVENTS_ACTION_KEY, getCalendarEventsAction } from '../../api';
import { UnhandledMeetingsTable } from '../../molecules';
import { translationStrings } from './home-view-seller.defaults';

import './home-view-seller.styles.scss';

export const HomeViewSeller = (): JSX.Element => {
  const navigate = useNavigate();
  const { deviceData } = useDeviceDetect();

  const { findCustomerById, usersData } = useAccountsNamesActionQuery();
  const { data: calendarEventsData, isLoading: isCalendarEventsDataLoading } = useQuery(
    GET_CALENDAR_EVENTS_ACTION_KEY,
    getCalendarEventsAction,
  );

  const [shouldRenderWeekends, setShouldRenderWeekends] = useLocalStorage(
    HOMEPAGE_SHOULD_RENDER_WEEKENDS_KEY,
    true,
  );

  const translations = useTranslation(translationStrings);

  const navigateToUpsertEventPage = (eventId: string) =>
    navigate(
      `/${ERoutes.DASHBOARD}/${EDashboardRoutes.EVENTS}/${EDashboardEventsRoutes.UPSERT_EVENT}?eventId=${eventId}`,
    );

  const transformedCalendarData = transformCalendarApiResponse(
    calendarEventsData?.data,
    findCustomerById,
    translations.calendarReservationTitle,
    !usersData?.length,
  );

  return (
    <div className="home-view-seller-wrapper">
      <section>
        <Typography
          strong
          as={deviceData.isMobile ? 'h2' : 'h1'}
        >{`${translations.dashboardHomepageWelcomeMessage}!`}</Typography>
      </section>
      <section className="home-view-seller-wrapper__calendar-section">
        <div className="calendar-section__header">
          <Typography strong as={deviceData.isMobile ? 'h4' : 'h3'}>
            {translations.dashboardHomepageSellerCalendar}
          </Typography>
          <FormControlLabel
            strong
            control={
              <Switch
                checked={shouldRenderWeekends}
                className="calendar-section__switch"
                onChange={() => setShouldRenderWeekends(!shouldRenderWeekends)}
              />
            }
            label={translations.dashboardEventsListHeaderCalendarSwitch}
            labelPlacement="start"
          />
        </div>
        <MinimalCalendarWrapper
          hideAbsenceButton
          absences={transformedCalendarData.absenceData}
          displayWeekends={shouldRenderWeekends}
          enableDateSelection={false}
          events={transformedCalendarData.calendarEvents}
          isLoading={isCalendarEventsDataLoading}
          mode="week"
          onEventClick={navigateToUpsertEventPage}
        />
      </section>
      <section className="home-view-seller-wrapper__unhandled-events-section">
        <Typography strong as={deviceData.isMobile ? 'h4' : 'h3'}>
          {translations.dashboardHomepageSellerUnhandledEvents}
        </Typography>
        <UnhandledMeetingsTable
          className="home-view-seller-wrapper__unhandled-events-section-table"
          onEventClick={navigateToUpsertEventPage}
        />
      </section>
    </div>
  );
};
