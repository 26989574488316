import { FunctionComponent } from 'react';
import { QueryParamProvider as QueryParamProviderWrapper } from 'use-query-params';

import { RouteAdapter } from './parts';
import { IQueryParamProviderProps } from './query-param-provider.types';

export const QueryParamProvider = ({ children }: IQueryParamProviderProps): JSX.Element => (
  <QueryParamProviderWrapper ReactRouterRoute={RouteAdapter as FunctionComponent}>
    {children}
  </QueryParamProviderWrapper>
);
