import { AxiosResponse } from 'axios';

import { prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IApiAbsence } from '@interfaces';

import { ISetAbsencesAttributes } from './set-absences.types';

export const setAbsencesAction =
  (getAccessToken: TGetAccessToken, loggedUserId: string | undefined) =>
  async ({
    selectedDates,
    userId,
  }: ISetAbsencesAttributes): Promise<AxiosResponse<IApiAbsence[]>> => {
    const accessToken = await getAccessToken();

    const correctUserId = userId || loggedUserId;
    const formattedAbsences = selectedDates.map((_selectedDate) => ({
      durationInMinutes: null,
      start: new Date(_selectedDate).toISOString(),
    }));

    return axiosInstance.post(
      EApiEndpoint.ABSENCES,
      { userId: correctUserId, absences: formattedAbsences },
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
