import { CallIcon, Icon, ProfileIcon } from '@amperio/amperio-ui';

export const getFloatingActionMenuOptions = (
  translations: Record<string, string>,
  onCallIconClick: () => void,
  onAccountDetailsIconClick: () => void,
) => [
  {
    tooltipOpen: true,
    tooltipTitle: translations.dashboardEventsUpsertEventFloatingActionMenuCall,
    icon: (
      <Icon fill="none" stroke="primary" onClick={onCallIconClick}>
        <CallIcon />
      </Icon>
    ),
  },
  {
    tooltipOpen: true,
    tooltipTitle: translations.dashboardEventsUpsertEventFloatingActionMenuAccountDetails,
    icon: (
      <Icon fill="none" stroke="primary" onClick={onAccountDetailsIconClick}>
        <ProfileIcon />
      </Icon>
    ),
  },
];
