export const translationStrings = [
  'common.buttons.edit',
  'common.buttons.save',
  'common.buttons.back',
  'common.buttons.cancel',
  'dashboard.lead.header.form',
  'common.prompt.unsaved.leave',
  'common.prompt.unsaved.title',
  'common.prompt.unsaved.cancel',
  'common.prompt.something.went.wrong',
  'dashboard.lead.header.add.account.button',
  'notifications.success.lead.changes.saved',
] as const;
