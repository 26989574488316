import {
  Button,
  PASSWORD_REGEX,
  PasswordInputWithController,
  TextInputWithController,
  isString,
  useDeviceDetect,
} from '@amperio/amperio-ui';
import classnames from 'classnames';

import { useTranslation } from '@utils';
import { containEntersOrSpaces, startsOrEndsWithWhitespace } from '@utils/react-hook-form';

import { translationStrings } from './invitation-form.defaults';
import { IInvitationFormProps } from './invitation-form.types';

import './invitation-form.styles.scss';

export const InvitationForm = ({
  className,
  control,
  isLoading,
  ...restProps
}: IInvitationFormProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  return (
    <form className={classnames('invitation-form-wrapper', className)} {...restProps}>
      <TextInputWithController
        controllerProps={{
          control,
          name: 'name',
          rules: {
            required: true,
            maxLength: 100,
            validate: {
              startsOrEndsWithWhitespace: startsOrEndsWithWhitespace(),
              containEntersOrSpaces: containEntersOrSpaces(),
            },
          },
        }}
        inputProps={{
          variant: 'outlined',
          autoComplete: 'fullName',
          size: deviceData.isMobile ? 'small' : 'medium',
          label: translations.acceptInvitationFormFullNameLabel,
        }}
      />
      <TextInputWithController
        controllerProps={{
          control,
          name: 'email',
        }}
        inputProps={{
          disabled: true,
          variant: 'outlined',
          size: deviceData.isMobile ? 'small' : 'medium',
          label: translations.acceptInvitationFormEmailLabel,
        }}
      />
      <PasswordInputWithController
        controllerProps={{
          control,
          name: 'password',
          rules: {
            required: true,
            pattern: PASSWORD_REGEX,
          },
        }}
        inputProps={{
          autoComplete: 'password',
          size: deviceData.isMobile ? 'small' : 'medium',
          label: translations.acceptInvitationFormPasswordLabel,
          validators: [
            {
              message: translations.acceptInvitationFormPasswordLength,
              isValid: (value: unknown) =>
                isString(value) && value.length > 0 && value.length >= 8 && value.length <= 18,
            },
            {
              message: translations.acceptInvitationFormPasswordSpecialCharacters,
              isValid: (value: unknown) => isString(value) && /[!#$%&*@^]/.test(value),
            },
            {
              message: translations.acceptInvitationFormPasswordLowerAndUpperLetters,
              isValid: (value: unknown) => isString(value) && /(?=.*[a-z])(?=.*[A-Z])/.test(value),
            },
            {
              message: translations.acceptInvitationFormPasswordNumbers,
              isValid: (value: unknown) => isString(value) && /\d/.test(value),
            },
          ],
        }}
      />
      <Button borderRadius="xxs" isLoading={isLoading} type="submit" variant="contained">
        {translations.acceptInvitationFormSubmitButton}
      </Button>
    </form>
  );
};
