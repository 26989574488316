import { AxiosRequestConfig } from 'axios';

export const prepareAxiosConfigWithAccessToken = (
  accessToken: string | undefined,
  config: AxiosRequestConfig = {},
): AxiosRequestConfig => ({
  ...config,
  headers: {
    ...config.headers,
    authorization: `Bearer ${accessToken}`,
  },
});
