import { prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

export const deleteAbsencesAction =
  (getAccessToken: TGetAccessToken) => async (absenceIds: string[]) => {
    const accessToken = await getAccessToken();

    return axiosInstance.delete(EApiEndpoint.ABSENCES, {
      ...prepareAxiosConfigWithAccessToken(accessToken),
      data: {
        ids: absenceIds,
      },
    });
  };
