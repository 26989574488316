import { ELeadStatus } from '@enums';

import { ILeadsFormFields } from './leads-form.types';

export const translationStrings = [
  'common.buttons.save',
  'common.buttons.cancel',
  'common.prompt.unsaved.cancel',
  'common.prompt.unsaved.leave',
  'common.prompt.unsaved.title',
  'dashboard.lead.header.form',
  'common.form.field.error.required',
  'form.lead.name.label',
  'form.lead.name.max.length.error',
  'form.lead.phone.number.label',
  'form.lead.phone.number.pattern.match.error',
  'form.lead.status.label',
  'common.lead.status.new',
  'common.lead.status.invalid',
  'common.lead.status.callback',
  'common.lead.status.duplicate',
  'common.lead.status.interested',
  'common.lead.status.resignation',
  'common.form.field.error.contain.enters.or.spaces',
  'common.form.field.error.starts.or.ends.with.whitespace',
] as const;

export const getLeadStatusDropdownOptions = (translations: Record<string, string>) => [
  {
    value: ELeadStatus.INVALID,
    label: translations.commonLeadStatusInvalid,
  },
  {
    value: ELeadStatus.CALLBACK,
    label: translations.commonLeadStatusCallback,
  },
  {
    value: ELeadStatus.DUPLICATE,
    label: translations.commonLeadStatusDuplicate,
  },
  {
    value: ELeadStatus.INTERESTED,
    label: translations.commonLeadStatusInterested,
  },
  {
    value: ELeadStatus.RESIGNATION,
    label: translations.commonLeadStatusResignation,
  },
];

export const leadsFormDefaultFormFields: ILeadsFormFields = {
  name: '',
  phoneNumber: '',
  leadStatus: ELeadStatus.INVALID,
};
