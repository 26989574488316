export default {
  // list
  'dashboard.events.list.header.calendar.switch': 'Weekends',
  'dashboard.events.list.header.add.event.button': 'Add event',
  'dashboard.events.list.header.set.absence.button': 'Set absence',
  'dashboard.events.list.header.unset.absence.button': 'Unset absence',
  'dashboard.events.list.header.select.seller': 'Select sales advisor',

  // upsert event
  'dashboard.events.upsert.event.floating.action.menu.call': 'Call',
  'dashboard.events.upsert.event.floating.action.menu.account.details': 'Account',
};
