export default {
  // other
  'accept.invitation.header': 'Welcome to the Amperio Partners Panel',
  'accept.invitation.subheader.prefix': 'Accept invitation from',
  'accept.invitation.subheader.suffix': 'and fill out the form below.',

  // form
  'accept.invitation.form.full.name.label': 'Full name',
  'accept.invitation.form.email.label': 'Email',
  'accept.invitation.form.password.label': 'Password',
  'accept.invitation.form.password.length': 'Use from 8 to 18 characters',
  'accept.invitation.form.password.special.characters': 'Use special characters (!@#$%^&*)',
  'accept.invitation.form.password.lower.and.upper.letters': 'Use lower and upper letters (Aa-Zz)',
  'accept.invitation.form.password.numbers': 'Use numbers (0-9)',
  'accept.invitation.form.submit.button': 'Continue',
};
