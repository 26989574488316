import { CenterBlock, Paper, useDeviceDetect } from '@amperio/amperio-ui';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import { AcceptInvitation, FullScreenLoading } from '@domain';

import { useQuery } from '@utils';

import {
  GET_INVITATIONS_ACTION_KEY,
  GET_INVITATION_FLOW_ACTION_KEY,
  getInvitationFlowAction,
  getInvitationsAction,
} from './api';

import './login.styles.scss';

export const LoginPage = (): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const [invitationQueryParam] = useQueryParam('invitation', StringParam);
  const [organizationQueryParam] = useQueryParam('organization', StringParam);

  const {
    data: invitationFlowData,
    isLoading: isInvitationFlowDataLoading,
    isLoadingError: isInvitationFlowDataLoadingError,
  } = useQuery(
    GET_INVITATION_FLOW_ACTION_KEY,
    getInvitationFlowAction(organizationQueryParam!, invitationQueryParam!),
    { enabled: !!invitationQueryParam && !!organizationQueryParam && !isAuthenticated },
  );

  const {
    data: invitationsData,
    isLoading: isInvitationsDataLoading,
    isLoading: isInvitationsDataLoadingError,
  } = useQuery(
    GET_INVITATIONS_ACTION_KEY,
    getInvitationsAction(organizationQueryParam!, invitationQueryParam!),
    { enabled: !!invitationFlowData && !isInvitationFlowDataLoading && !isAuthenticated },
  );

  useEffect(() => {
    if (isAuthenticated) {
      loginWithRedirect();

      return;
    }

    if (isInvitationFlowDataLoading || isInvitationsDataLoading) {
      return;
    }

    if (isInvitationsDataLoadingError || isInvitationFlowDataLoadingError) {
      loginWithRedirect({
        organization: organizationQueryParam || undefined,
      });

      return;
    }

    if (invitationFlowData?.data === 'auth0' || !invitationFlowData || !invitationsData) {
      loginWithRedirect({
        invitation: invitationQueryParam || undefined,
        organization: organizationQueryParam || undefined,
      });
    }
  }, [
    isAuthenticated,
    invitationFlowData,
    isInvitationFlowDataLoading,
    invitationsData,
    isInvitationsDataLoading,
  ]);

  if (
    isAuthenticated ||
    isInvitationFlowDataLoading ||
    invitationFlowData?.data !== 'new-flow' ||
    isInvitationsDataLoading ||
    isInvitationsDataLoadingError ||
    isInvitationFlowDataLoadingError
  ) {
    return <FullScreenLoading />;
  }

  return (
    <CenterBlock className="login-wrapper">
      <Paper borderRadius="xxs" padding={deviceData.isMobile ? 'md' : 'xl'}>
        <AcceptInvitation
          invitationId={invitationsData?.data.invitationId}
          makeLoginWithRedirect={() => {
            loginWithRedirect({
              login_hint: invitationsData?.data.userEmail,
              organization: organizationQueryParam || undefined,
            });
          }}
          organizationId={organizationQueryParam || undefined}
          organizationName={invitationsData?.data.organizationName}
          userEmail={invitationsData?.data.userEmail}
        />
      </Paper>
    </CenterBlock>
  );
};
