import { DataGrid, Typography } from '@amperio/amperio-ui';
import { lightFormat } from 'date-fns';

import { getUnhandledEventsAction } from '@domain/home/api';

import { useMutation, useTranslation } from '@utils';

import { translationStrings } from './unhandled-meetings-table.defaults';
import { IUnhandledMeetingsTableProps } from './unhandled-meetings-table.types';

import './unhandled-meetings-table.styles.scss';

export const UnhandledMeetingsTable = ({
  className,
  onEventClick,
}: IUnhandledMeetingsTableProps): JSX.Element => {
  const translations = useTranslation(translationStrings);

  const { isLoading, mutateAsync } = useMutation(getUnhandledEventsAction);

  return (
    <div className={className}>
      <DataGrid
        className="unhandled-meetings-table-wrapper"
        columns={[
          {
            minWidth: 250,
            field: 'name',
            headerName: translations.dashboardHomepageUnhandledMeetingsNameColumnLabel,
            renderBodyCell: (value) => (
              <Typography ellipsis strong as="span" maxWidth={250}>
                {value || '-'}
              </Typography>
            ),
          },
          {
            minWidth: 250,
            field: 'location',
            headerName: translations.dashboardHomepageUnhandledMeetingsLocationColumnLabel,
            renderBodyCell: (value) => (
              <Typography ellipsis as="span" maxWidth={250}>
                {value || '-'}
              </Typography>
            ),
          },
          {
            minWidth: 150,
            field: 'modifiedOn',
            headerName: translations.dashboardHomepageUnhandledMeetingsDateColumnLabel,
            renderBodyCell: (value) => {
              if (!value) {
                return <span>-</span>;
              }

              return <Typography as="span">{lightFormat(new Date(value), 'dd/MM/yyy')}</Typography>;
            },
          },
        ]}
        getDataFromServer={async ({ filters, page, rowsPerPage, sorts }) => {
          const { data } = await mutateAsync({
            sorts,
            filters,
            pageNumber: page,
            pageSize: rowsPerPage,
          });

          return data;
        }}
        headerHeight={45}
        isLoading={isLoading}
        noDataMessage={translations.commonTableNoData}
        rowHeight={45}
        shouldRenderPagination={false}
        onBodyRow={(record: unknown) => ({
          pointer: true,
          // @ts-expect-error
          onClick: () => onEventClick(record.id),
        })}
      />
    </div>
  );
};
