import { AccountsIcon, HomeIcon, LeadsIcon, LockIcon, MeetingsIcon } from '@amperio/amperio-ui';

import { GHOST_MODE_SUFFIX } from '@infrastructure';

import {
  EDashboardAccountsRoutes,
  EDashboardEventsRoutes,
  EDashboardLeadsRoutes,
  EDashboardRoutes,
} from '@enums';

import { ISidebarOption } from '../../molecules';

export const translationStrings = [
  'dashboard.header.home',
  'dashboard.header.leads.list',
  'dashboard.header.events.list',
  'dashboard.header.accounts.list',
] as const;

const ghostSidebarOptions = [
  {
    label: 'Create lead',
    IconComponent: LockIcon,
    redirectTo: EDashboardRoutes.CREATE_LEAD,
  },
];

export const getSidebarOptions = (
  translations: Record<string, string>,
  name: string | undefined,
): ISidebarOption[] => {
  const baseSidebarOptions = [
    {
      IconComponent: HomeIcon,
      redirectTo: EDashboardRoutes.HOME,
      label: translations.dashboardHeaderHome,
    },
    {
      IconComponent: LeadsIcon,
      toMatchWith: EDashboardRoutes.LEADS,
      label: translations.dashboardHeaderLeadsList,
      redirectTo: `${EDashboardRoutes.LEADS}/${EDashboardLeadsRoutes.LIST}`,
    },
    {
      IconComponent: AccountsIcon,
      toMatchWith: EDashboardRoutes.ACCOUNTS,
      label: translations.dashboardHeaderAccountsList,
      redirectTo: `${EDashboardRoutes.ACCOUNTS}/${EDashboardAccountsRoutes.LIST}`,
    },
    {
      IconComponent: MeetingsIcon,
      toMatchWith: EDashboardRoutes.EVENTS,
      label: translations.dashboardHeaderEventsList,
      redirectTo: `${EDashboardRoutes.EVENTS}/${EDashboardEventsRoutes.LIST}`,
    },
  ];

  const isGhost = name?.includes(GHOST_MODE_SUFFIX);
  const ghostOptions = isGhost ? ghostSidebarOptions : [];

  return [...baseSidebarOptions, ...ghostOptions];
};
