export default {
  /// /////// PRODUCT CATEGORIES //////////

  // section header
  'form.product.categories.header': 'Productos',

  // Product categories dropdown
  'form.product.categories.label': 'Establecer productos',
  'form.product.categories.heating.foils': 'Láminas de calefacción',
  'form.product.categories.photovoltaics': 'Fotovoltaica',
  'form.product.categories.heating.pumps': 'Bombas de Calor',

  // Payment method dropdown
  'form.product.categories.payment.method.label': 'Forma de pago',
  'form.product.categories.payment.method.cash': 'Efectivo',
  'form.product.categories.payment.method.credit': 'Crédito',

  // House area input
  'form.product.categories.house.area.label': 'área de la casa',
  'form.product.categories.house.area.min.error': 'El valor mínimo es 80',
  'form.product.categories.house.area.max.error': 'El valor máximo es 5000',

  // Level of insulation dropdown
  'form.product.categories.level.of.insulation.label': 'Nivel de aislamiento',
  'form.product.categories.level.of.insulation.passive': 'Pasivo',
  'form.product.categories.level.of.insulation.energy.saving': 'El ahorro de energía',
  'form.product.categories.level.of.insulation.well.insulated': 'Bien aislado',
  'form.product.categories.level.of.insulation.medium.insulated': 'Aislamiento medio',
  'form.product.categories.level.of.insulation.poorly.insulated': 'Mal aislado',

  // The current type of heating dropdown
  'form.product.categories.current.type.of.heating.label': 'El tipo de calefacción actual',
  'form.product.categories.current.type.of.heating.coal.stove': 'Estufa de carbón',
  'form.product.categories.current.type.of.heating.gas.stove': 'Estufa de gas',
  'form.product.categories.current.type.of.heating.electric.heating': 'Calefacción eléctrica',
  'form.product.categories.current.type.of.heating.other': 'Otro',

  // Installation site dropdown
  'form.product.categories.installation.site.label': 'Sitio de instalación"',
  'form.product.categories.installation.site.north': 'Norte',
  'form.product.categories.installation.site.south': 'Sur',
  'form.product.categories.installation.site.east': 'Este',
  'form.product.categories.installation.site.west': 'Oeste',

  // Roof or ground dropdown
  'form.product.categories.roof.or.ground.label': 'Techo o suelo',
  'form.product.categories.roof.or.ground.roof': 'Techo',
  'form.product.categories.roof.or.ground.ground': 'Suelo',

  // Roof area input
  'form.product.categories.roof.area.label': 'El tamańo del techo',
  'form.product.categories.roof.area.min.error': 'El valor mínimo es 20',
  'form.product.categories.roof.area.max.error': 'El valor máximo es 1000',

  // Monthly electric bill input
  'form.product.categories.monthly.electric.bill.label': 'Mensual factura por energía',
  'form.product.categories.monthly.electric.bill.min.error': 'El valor mínimo es 20',
  'form.product.categories.monthly.electric.bill.max.error': 'El valor máximo es 5000',

  // Month of the given bill dropdown
  'form.product.categories.month.of.the.given.bill.label': 'Mes de la factura dada',

  /// /////// ADDRESS //////////

  // section header
  'form.address.header': 'Dirección',

  // region dropdown
  'form.address.region.label': 'Región',

  // district dropdown
  'form.address.district.label': 'Distrito',

  // city input
  'form.address.city.label': 'Ciudad',
  'form.address.city.max.length.error': 'La longitud máxima es 100',

  // street input
  'form.address.street.label': 'Calle',
  'form.address.street.max.length.error': 'La longitud máxima es 100',

  // zipcode input
  'form.address.zip.code.label': 'Código postal',
  'form.address.zip.code.pattern.match.error': 'Formato de código postal incorrecto',

  // house number input
  'form.address.house.number.label': 'Número de casa',
  'form.address.house.number.pattern.match.error': 'Incorrecto formato de numero de casa',

  /// /////// GENERAL //////////

  // section header
  'form.general.header': 'General',

  // customer dropdown
  'form.general.customer.label': 'Cliente',

  // phone number input
  'form.general.phone.number.label': 'Número de teléfono',

  // event type dropdown
  'form.general.event.type.label': 'Tipo de evento',
  'form.general.event.type.call': 'Llamar',
  'form.general.event.type.meeting': 'Reunión',

  // owner dropdown
  'form.general.owner.label': 'Dueño',

  // select date dropdown
  'form.general.select.meeting.date.label': 'Seleccionar fecha',

  // select hour dropdown
  'form.general.select.meeting.hour.label': 'Seleccionar hora',

  // select meeting length dropdown
  'form.general.select.meeting.length.label': 'Seleccione el tiempo de la reunión',

  /// /////// ACCOUNT //////////

  // section header
  'form.account.header': 'Cuenta',

  // name input
  'form.account.name.label': 'Nombre completo',
  'form.account.name.max.length.error': 'La longitud máxima es 100',

  // phone number input
  'form.account.phone.number.label': 'Número de teléfono',
  'form.account.phone.number.pattern.match.error': 'Formato de número de teléfono incorrecto',

  // email input
  'form.account.email.label': 'Correo electrónico',
  'form.account.email.pattern.match.error': 'Formato de correo electrónico incorrecto',
  'form.account.email.max.length.error': 'La longitud máxima es 100',

  /// /////// LEADS //////////

  // name input
  'form.lead.name.label': 'Nombre completo',
  'form.lead.name.max.length.error': 'La longitud máxima es 100',

  // phone number input
  'form.lead.phone.number.label': 'Número de teléfono',
  'form.lead.phone.number.pattern.match.error': 'Formato de número de teléfono incorrecto',

  // status dropdown
  'form.lead.status.label': 'Estatuto después del evento',

  /// /////// SUMMARY //////////

  // section header
  'form.summary.header': 'Resumen',

  // notes input
  'form.summary.notes.label': 'Cuaderno',
  'form.summary.notes.max.length.error': 'La longitud máxima es 500',

  // status dropdown
  'form.summary.status.label': 'Estatuto después del evento',
};
