export default {
  // buttons
  'common.buttons.ok': 'Ok',
  'common.buttons.back': 'Atrás',
  'common.buttons.edit': 'Editar',
  'common.buttons.call': 'Llamar',
  'common.buttons.clear': 'Claro',
  'common.buttons.save': 'Guardar',
  'common.buttons.cancel': 'Cancelar',
  'common.buttons.logout': 'Finalizar la sesión',

  // table
  'common.table.no.data': 'Falta de datos',

  // form
  'common.form.field.error.starts.or.ends.with.whitespace':
    'El campo no puede comenzar ni terminar con un espacio',
  'common.form.field.error.contain.enters.or.spaces':
    'El campo no puede contener líneas vacías o espacios dobles',
  'common.form.field.error.required': 'Se requiere campo',
  'common.form.field.error.negative.number': 'El valor no puede ser negativo',

  // prompt
  'common.prompt.unsaved.title': 'Cambios no guardados',
  'common.prompt.unsaved.cancel': '¿Estas seguro que quieres cancelar?',
  'common.prompt.unsaved.leave': '¿Estás seguro que quieres abandonar?',
  'common.prompt.something.went.wrong': 'Algo salió mal',
  'common.prompt.blocked.account.message':
    'Estimado Socio Instalador, Su Acceso al Panel del Socio Instalador Amperio, ha sido SUSPENDIDO. Para mayor información por favor contactarse con su Gerente de Cuenta',

  // months
  'common.month.january': 'Enero',
  'common.month.february': 'Febrero',
  'common.month.march': 'Marzo',
  'common.month.april': 'Abril',
  'common.month.may': 'Mayo',
  'common.month.june': 'Junio',
  'common.month.july': 'Julio',
  'common.month.august': 'Agosto',
  'common.month.september': 'Septiembre',
  'common.month.october': 'Octubre',
  'common.month.november': 'Noviembre',
  'common.month.december': 'Diciembre',

  // event status
  'common.event.status.sale': 'Rebajas',
  'common.event.status.missed': 'Omitido',
  'common.event.status.planned': 'Planificado',
  'common.event.status.rejected': 'Rechazado',
  'common.event.status.resignation': 'Resignación',
  'common.event.status.bid.submitted': 'Oferta enviada',
  'common.event.status.sales.opportunity': 'Oportunidad de venta',

  // lead status
  'common.lead.status.new': 'Nuevo',
  'common.lead.status.invalid': 'Inválido',
  'common.lead.status.callback': 'Llamar de vuelta"',
  'common.lead.status.duplicate': 'Duplicado',
  'common.lead.status.interested': 'Interesado',
  'common.lead.status.resignation': 'Resignación',

  // date picker
  'common.date.picker.today': 'Hoy',
  'common.date.picker.selected.date': 'Fecha seleccionada',

  // abbreviated weekdays
  'common.abbreviated.weekday.monday': 'lun',
  'common.abbreviated.weekday.tuesday': 'mar',
  'common.abbreviated.weekday.wednesday': 'mié',
  'common.abbreviated.weekday.thursday': 'jue',
  'common.abbreviated.weekday.friday': 'vie',
  'common.abbreviated.weekday.saturday': 'sáb',
  'common.abbreviated.weekday.sunday': 'dom',
};
