import { AxiosResponse } from 'axios';

import { buildApiUrl, prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint, ECountry } from '@enums';

import { IApiRegion } from '@interfaces';

export const GET_REGIONS_ACTION_KEY = 'get-regions-action-key';

export const getRegionsAction =
  (getAccessToken: TGetAccessToken) => async (): Promise<AxiosResponse<IApiRegion[]>> => {
    const accessToken = await getAccessToken();

    return axiosInstance.get(
      buildApiUrl(EApiEndpoint.REGIONS, { country: ECountry.SPAIN }),
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
