import {
  ArrowLeftIcon,
  Button,
  Fab,
  Icon,
  PlusIcon,
  Typography,
  toast,
  useConfirmationModal,
  useDeviceDetect,
  useDidMountEffect,
} from '@amperio/amperio-ui';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { StringParam, useQueryParams } from 'use-query-params';

import { AccountForm, AddressForm, EventsTable } from '@ui';

import {
  addPlusToPhoneNumber,
  removePlusFromPhoneNumber,
  useAxiosError,
  useMutation,
  usePrompt,
  useTranslation,
} from '@utils';

import { IUpsertAccountFormFields } from '@interfaces';

import { createAccountAction, getAccountByIdAction, updateAccountAction } from './api';
import { defaultFormFields, translationStrings } from './upsert-account.defaults';
import { IUpsertAccountProps } from './upsert-account.types';
import { transformAccountDataFromServer, transformUpsertAccountFormData } from './utils';

import './upsert-account.styles.scss';

export const UpsertAccount = ({
  onAddEventClick,
  onBackClick,
  onEventsTableRowClick,
}: IUpsertAccountProps): JSX.Element => {
  const [queryParams, setQueryParams] = useQueryParams({
    name: StringParam,
    leadId: StringParam,
    accountId: StringParam,
    phoneNumber: StringParam,
  });

  const { deviceData } = useDeviceDetect();
  const { confirm } = useConfirmationModal();
  const translations = useTranslation(translationStrings);
  const { receiveErrorMessageFromError } = useAxiosError();
  const [isEditMode, setIsEditMode] = useState(deviceData.isMobile || !queryParams.accountId);

  const { isLoading: isGetAccountByIdLoading, mutateAsync: mutateAsyncGetAccountByIdAction } =
    useMutation(getAccountByIdAction(queryParams.accountId!));

  const { isLoading: isCreateAccountLoading, mutateAsync: mutateAsyncCreateAccountAction } =
    useMutation(createAccountAction);
  const { isLoading: isUpdateAccountLoading, mutateAsync: mutateAsyncUpdateAccountAction } =
    useMutation(updateAccountAction);

  useDidMountEffect(() => {
    setIsEditMode(deviceData.isMobile);
  }, [JSON.stringify(deviceData)]);

  const { control, formState, handleSubmit, reset, setError, setValue, watch } =
    useForm<IUpsertAccountFormFields>({
      mode: 'onChange',
      shouldFocusError: true,
      defaultValues: {
        ...defaultFormFields,
        account: {
          ...defaultFormFields.account,
          fullName: queryParams.name || '',
          phoneNumber: removePlusFromPhoneNumber(queryParams.phoneNumber),
        },
      },
    });

  useEffect(() => {
    if (!queryParams.accountId) {
      return;
    }

    mutateAsyncGetAccountByIdAction({}).then((result) => {
      reset(transformAccountDataFromServer(result));
    });
  }, [queryParams.accountId]);

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  usePrompt(
    translations.commonPromptUnsavedTitle,
    translations.commonPromptUnsavedLeave,
    formState.isDirty,
  );

  const onSubmit = async (data: IUpsertAccountFormFields) => {
    if (Object.keys(formState.errors).length > 0) {
      return;
    }

    const phoneNumberWithPlus = addPlusToPhoneNumber(data.account.phoneNumber);

    if (!isValidPhoneNumber(phoneNumberWithPlus)) {
      return;
    }

    const transformedData = transformUpsertAccountFormData(data);

    if (!queryParams.accountId) {
      mutateAsyncCreateAccountAction({
        ...transformedData,
        leadId: queryParams.leadId || '',
      })
        .then((result) => {
          if (!deviceData.isMobile) {
            toggleEditMode();
          }

          reset(data);
          setTimeout(
            () => setQueryParams({ ...queryParams, accountId: result.data.id }, 'replace'),
            0,
          );

          toast.success(translations.notificationsSuccessAccountCreated);
        })
        .catch((error) => toast.error(receiveErrorMessageFromError(error)));

      return;
    }

    mutateAsyncUpdateAccountAction({
      requestData: transformedData,
      accountId: queryParams.accountId,
    })
      .then(() => {
        if (!deviceData.isMobile) {
          toggleEditMode();
        }

        reset(data);
        toast.success(translations.notificationsSuccessAccountChangesSaved);
      })
      .catch((error) => toast.error(receiveErrorMessageFromError(error)));
  };

  const handleCancel = () => {
    if (!formState.isDirty) {
      toggleEditMode();
      return;
    }

    confirm({
      title: translations.commonPromptUnsavedTitle,
      confirmationButtonProps: { autoFocus: true },
      description: translations.commonPromptUnsavedCancel,
    }).then(() => {
      reset(defaultFormFields);
      toggleEditMode();
    });
  };

  const handleAddEventClick = () => onAddEventClick && onAddEventClick(queryParams.accountId!);

  return (
    <form className="upsert-account-wrapper" onSubmit={handleSubmit(onSubmit)}>
      <header className="upsert-account-wrapper__header">
        {!deviceData.isMobile ? (
          <>
            <Button
              data-testid="upsert-account--button-back"
              startIcon={<ArrowLeftIcon />}
              variant="outlined"
              onClick={onBackClick}
            >
              {translations.commonButtonsBack}
            </Button>
            <span>
              {!isEditMode ? (
                <Button
                  data-testid="upsert-account--button-edit"
                  isLoading={isGetAccountByIdLoading}
                  variant="contained"
                  onClick={toggleEditMode}
                >
                  {translations.commonButtonsEdit}
                </Button>
              ) : (
                <>
                  <Button
                    data-testid="upsert-account--button-cancel"
                    disabled={!isEditMode || isCreateAccountLoading || isUpdateAccountLoading}
                    variant="contained"
                    onClick={handleCancel}
                  >
                    {translations.commonButtonsCancel}
                  </Button>
                  <Button
                    data-testid="upsert-account--button-save"
                    disabled={!isEditMode || !formState.isDirty}
                    isLoading={isCreateAccountLoading || isUpdateAccountLoading}
                    type="submit"
                    variant="contained"
                  >
                    {translations.commonButtonsSave}
                  </Button>
                </>
              )}
              {!!onAddEventClick && (
                <Button
                  data-testid="upsert-account--button-add-event"
                  disabled={isEditMode || !queryParams.accountId || isGetAccountByIdLoading}
                  startIcon={<PlusIcon />}
                  variant="contained"
                  onClick={handleAddEventClick}
                >
                  {translations.dashboardAccountsHeaderAddEventButton}
                </Button>
              )}
            </span>
          </>
        ) : null}
        {deviceData.isMobile && formState.isDirty ? (
          <Fab
            data-testid="upsert-account--button-add"
            disabled={isGetAccountByIdLoading || isCreateAccountLoading || isUpdateAccountLoading}
            type="submit"
            variant="extended"
          >
            <Icon stroke="white">
              <PlusIcon />
            </Icon>
            <Typography strong as="span" color="white">
              {translations.commonButtonsSave}
            </Typography>
          </Fab>
        ) : null}
        {!!onAddEventClick && deviceData.isMobile && !formState.isDirty && queryParams.accountId ? (
          <Fab data-testid="upsert-account--button-add-event" onClick={handleAddEventClick}>
            <Icon size="large" stroke="white">
              <PlusIcon />
            </Icon>
          </Fab>
        ) : null}
      </header>
      <section className="upsert-account-wrapper__content">
        <div className="upsert-account-wrapper__content-top">
          <AccountForm control={control} isEditMode={isEditMode} setError={setError} />
          <AddressForm
            control={control as never}
            isEditMode={isEditMode}
            setValue={setValue as never}
            watch={watch as never}
          />
        </div>
        {queryParams.accountId && (
          <EventsTable accountId={queryParams.accountId} onRowClick={onEventsTableRowClick} />
        )}
      </section>
    </form>
  );
};
