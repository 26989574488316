import { Button, Fab, Icon, PlusIcon, useDeviceDetect } from '@amperio/amperio-ui';

import { useTranslation } from '@utils';

import { AccountsTable } from '../../molecules';
import { IAccountsListProps } from './accounts-list.types';

import './accounts-list.styles.scss';

export const AccountsList = ({
  onAddNewAccountButtonClick,
  onTableRowClick,
}: IAccountsListProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(['dashboard.accounts.header.add.account.button'] as const);

  return (
    <div className="accounts-list-wrapper">
      {deviceData.isMobile ? (
        <Fab data-testid="accounts-list--button-add" onClick={onAddNewAccountButtonClick}>
          <Icon size="large" stroke="white">
            <PlusIcon />
          </Icon>
        </Fab>
      ) : (
        <div className="accounts-list-wrapper__header">
          <Button
            data-testid="accounts-list--button-add"
            startIcon={<PlusIcon />}
            variant="contained"
            onClick={onAddNewAccountButtonClick}
          >
            {translations.dashboardAccountsHeaderAddAccountButton}
          </Button>
        </div>
      )}
      <div className="accounts-list-wrapper__table-wrapper">
        <AccountsTable onRowClick={onTableRowClick} />
      </div>
    </div>
  );
};
