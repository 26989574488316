import { useAuth0 } from '@auth0/auth0-react';
import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  useQuery as useQueryReactQuery,
} from 'react-query';

import { useAccessToken } from '@utils';

export const useQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: (
    getAccessToken: TGetAccessToken,
    loggedUserId: string | undefined,
  ) => QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
) => {
  const { user } = useAuth0();
  const { getAccessToken } = useAccessToken();

  return useQueryReactQuery(queryKey, queryFn(getAccessToken, user?.sub), options);
};
