import { arrayOfStringsToLowerCase, useLocalStorage } from '@amperio/amperio-ui';
import { useAuth0 } from '@auth0/auth0-react';

import { AUTH0_CLAIMS_LOCAL_STORAGE_KEY } from '@infrastructure';

import { EUserClaim } from '@enums';

export const useUserClaims = () => {
  const { isAuthenticated, user } = useAuth0();
  const [userClaims] = useLocalStorage<string[]>(AUTH0_CLAIMS_LOCAL_STORAGE_KEY, []);

  const getUserClaimsAsArrayOfStrings = () => {
    if (!user || !userClaims || !isAuthenticated) {
      return [];
    }

    return arrayOfStringsToLowerCase(userClaims);
  };

  const getUserClaimsAsArrayOfUserClaimEnums = () => {
    const userClaimsAsArrayOfStrings = getUserClaimsAsArrayOfStrings();

    const userClaimValues = Object.values(EUserClaim);

    return userClaimValues.filter((_userClaim) => userClaimsAsArrayOfStrings.includes(_userClaim));
  };

  const getUserClaimsAsObject = () => {
    const userClaims = getUserClaimsAsArrayOfUserClaimEnums();

    return {
      getAccounts: userClaims.includes(EUserClaim.GET_ACCOUNTS),
      createAccounts: userClaims.includes(EUserClaim.CREATE_ACCOUNTS),
      updateAccounts: userClaims.includes(EUserClaim.UPDATE_ACCOUNTS),
      getAllAccounts: userClaims.includes(EUserClaim.GET_ALL_ACCOUNTS),
      assignUserAccounts: userClaims.includes(EUserClaim.ASSIGN_USER_ACCOUNTS),

      getLeads: userClaims.includes(EUserClaim.GET_LEADS),
      createLeads: userClaims.includes(EUserClaim.CREATE_LEADS),
      updateLeads: userClaims.includes(EUserClaim.UPDATE_LEADS),
      getAllLeads: userClaims.includes(EUserClaim.GET_ALL_LEADS),
      assignUserLeads: userClaims.includes(EUserClaim.ASSIGN_USER_LEADS),

      getAllByDateAndUserCalendarEvents: userClaims.includes(
        EUserClaim.GET_ALL_BY_DATE_AND_USER_CALENDAR_EVENTS,
      ),
      getCalendarEvents: userClaims.includes(EUserClaim.GET_CALENDAR_EVENTS),
      createCalendarEvents: userClaims.includes(EUserClaim.CREATE_CALENDAR_EVENTS),
      updateCalendarEvents: userClaims.includes(EUserClaim.UPDATE_CALENDAR_EVENTS),
      getAllCalendarEvents: userClaims.includes(EUserClaim.GET_ALL_CALENDAR_EVENTS),
      assignUserCalendarEvents: userClaims.includes(EUserClaim.ASSIGN_USER_CALENDAR_EVENTS),

      getDashboard: userClaims.includes(EUserClaim.GET_DASHBOARD),

      getUsers: userClaims.includes(EUserClaim.GET_USERS),
      getAllUsers: userClaims.includes(EUserClaim.GET_ALL_USERS),

      getProductSurveys: userClaims.includes(EUserClaim.GET_PRODUCT_SURVEYS),
      createProductSurveys: userClaims.includes(EUserClaim.CREATE_PRODUCT_SURVEYS),
      updateProductSurveys: userClaims.includes(EUserClaim.UPADTE_PRODUCT_SURVEYS),

      getAbsences: userClaims.includes(EUserClaim.GET_ABSENCES),
      createAbsences: userClaims.includes(EUserClaim.CREATE_ABSENCES),
      deleteAbsences: userClaims.includes(EUserClaim.DELETE_ABSENCES),
    };
  };

  const isSalesManager = () => {
    const userClaimsAsObject = getUserClaimsAsObject();

    return userClaimsAsObject.getAllUsers && userClaimsAsObject.getAllByDateAndUserCalendarEvents;
  };

  const doesUserHaveAnyClaim = () => getUserClaimsAsArrayOfUserClaimEnums().length > 0;

  const isSalesRepresentative = () => !isSalesManager() && doesUserHaveAnyClaim();

  const doesUserHaveRequiredClaim = (role: EUserClaim) =>
    getUserClaimsAsArrayOfUserClaimEnums().includes(role);

  return {
    isSalesManager,
    doesUserHaveAnyClaim,
    isSalesRepresentative,
    getUserClaimsAsObject,
    doesUserHaveRequiredClaim,
    getUserClaimsAsArrayOfStrings,
    getUserClaimsAsArrayOfUserClaimEnums,
  };
};
