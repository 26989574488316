import { isArray, isString } from '@amperio/amperio-ui';

import { IFilter } from '@interfaces';

export const generateFilterQueryParam = (filters: IFilter[]) =>
  filters
    ?.reduce<string[]>((_accumulator, _value) => {
      if (!_value.value || _value.value === 'undefined' || _value.value.length === 0) {
        return _accumulator;
      }

      if (isString(_value.value)) {
        const record = `${_value.field}:${_value.operator}:${_value.value}`;

        return [..._accumulator, record];
      }

      if (isArray(_value.value)) {
        // @ts-expect-error
        const recordsAsString = _value.value.map(
          // @ts-expect-error
          (_element) => `${_value.field}:${_value.operator}:${_element}`,
        );

        return [..._accumulator, recordsAsString];
      }

      const record = `${_value.field}:${_value.operator}:${_value.value}`;

      return [..._accumulator, record];
    }, [])
    .join(',') || null;
