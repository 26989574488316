import { IUpsertEventFormFields } from '@interfaces';

import {
  addressFormDefaultFormFields,
  generalFormDefaultFormFields,
  productCategoriesFormDefaultFormFields,
  summaryFormDefaultFormFields,
} from '../../molecules';

export const translationStrings = [
  'common.buttons.edit',
  'common.buttons.save',
  'common.buttons.back',
  'common.buttons.cancel',
  'common.prompt.unsaved.title',
  'common.prompt.unsaved.leave',
  'common.prompt.unsaved.cancel',
  'common.prompt.something.went.wrong',
  'notifications.success.event.created',
  'notifications.success.event.details.changes.saved',
  'dashboard.events.upsert.event.floating.action.menu.call',
  'dashboard.events.upsert.event.floating.action.menu.account.details',
] as const;

export const defaultFormFields: IUpsertEventFormFields = {
  address: addressFormDefaultFormFields,
  general: generalFormDefaultFormFields,
  summary: summaryFormDefaultFormFields,
  productCategories: productCategoriesFormDefaultFormFields,
};
