import { ICalendarEvent, MinimalCalendar, toast } from '@amperio/amperio-ui';
import { ReactText, memo, useState } from 'react';

import { useTranslation } from '@utils';

import { EEventType } from '@enums';

import { translationStrings } from './minimal-calendar-wrapper.defaults';
import { IMinimalCalendarWrapperProps } from './minimal-calendar-wrapper.types';

const handleClickAway = (event: MouseEvent | TouchEvent, clearSelectedDates: () => void) => {
  const element = event.target as HTMLElement;

  if (!['BUTTON', 'INPUT', 'svg', 'path'].includes(element.tagName)) {
    clearSelectedDates();
  }
};

const MinimalCalendarWrapperComponent = ({
  events,
  onEventClick,
  setAbsenceData,
  unsetAbsenceData,
  ...restProps
}: IMinimalCalendarWrapperProps): JSX.Element => {
  const translations = useTranslation(translationStrings);
  const [activeToastId, setActiveToastId] = useState<ReactText>('');

  const handleCalendarEventClick = (calendarEvent: ICalendarEvent) => {
    if (activeToastId) {
      return;
    }

    if (calendarEvent.type === EEventType.RESERVATION) {
      const toastId = toast.info(translations.calendarAppointmentReservationInProgress, {
        onClose: () => setActiveToastId(''),
      });

      return setActiveToastId(toastId);
    }

    if (onEventClick) {
      onEventClick(calendarEvent.id);
    }
  };

  const abbreviatedWeekdayNames: [string, string, string, string, string, string, string] = [
    translations.commonAbbreviatedWeekdayMonday,
    translations.commonAbbreviatedWeekdayTuesday,
    translations.commonAbbreviatedWeekdayWednesday,
    translations.commonAbbreviatedWeekdayThursday,
    translations.commonAbbreviatedWeekdayFriday,
    translations.commonAbbreviatedWeekdaySaturday,
    translations.commonAbbreviatedWeekdaySunday,
  ];

  return (
    <MinimalCalendar
      clickAwayListenerProps={{
        onClickAway: handleClickAway,
      }}
      events={events}
      mode="month"
      translations={{
        abbreviatedWeekdayNames,
        expandedLabel: translations.calendarMoreExpandedLabel,
        collapsedLabel: translations.calendarMoreCollapsedLabel,
      }}
      onCalendarEventClick={handleCalendarEventClick}
      onMobileAbsenceSet={(date: Date) => setAbsenceData && setAbsenceData([date])}
      onMobileAbsenceUnset={(date: Date) => unsetAbsenceData && unsetAbsenceData([date])}
      {...restProps}
    />
  );
};

export const MinimalCalendarWrapper = memo(MinimalCalendarWrapperComponent);
