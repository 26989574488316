import * as R from 'ramda';
import { ICalendarEvent } from '@amperio/amperio-ui';
import { compareAsc } from 'date-fns';

import { EEventType } from '@enums';

import { IApiAccountsNames } from '@interfaces';

import { ITransformCalendarApiResponseAttributes } from './transform-calendar-api-response.types';

const getUniqueFullDayAbsences = (attributes: ITransformCalendarApiResponseAttributes) =>
  R.uniq(attributes.absences.map((_absence) => new Date(_absence.start)));

const getCalendarEvents = (
  attributes: ITransformCalendarApiResponseAttributes,
  findCustomerById: (customerId: string) => IApiAccountsNames | undefined,
  reservationTranslation: string,
): ICalendarEvent[] =>
  attributes.events
    .map((_calendarEvent) => {
      const baseTitle = findCustomerById(_calendarEvent?.accountId)?.name || '-';
      const isReservation = _calendarEvent.type === EEventType.RESERVATION;

      const title = isReservation ? reservationTranslation : baseTitle;

      return {
        title,
        id: _calendarEvent.id,
        type: _calendarEvent.type,
        start: new Date(_calendarEvent.startDate),
      };
    })
    .sort((a, b) => compareAsc(a.start, b.start));

export const transformCalendarApiResponse = (
  attributes: ITransformCalendarApiResponseAttributes | undefined,
  findCustomerById: (customerId: string) => IApiAccountsNames | undefined,
  reservationTranslation: string,
  skip?: boolean,
) => {
  if (!attributes || skip) {
    return { absenceData: [], calendarEvents: [] };
  }

  const absenceData = getUniqueFullDayAbsences(attributes);
  const calendarEvents = getCalendarEvents(attributes, findCustomerById, reservationTranslation);

  return { absenceData, calendarEvents };
};
