import { ISort } from '@interfaces';

export const generateSortQueryParam = (sorts: ISort[]) =>
  sorts
    ?.reduce<string[]>((_accumulator, _value) => {
      if (_value.order === 'none' || _value.orderBy === '') {
        return _accumulator;
      }

      const record = `${_value.orderBy}:${_value.order}`;

      return [..._accumulator, record];
    }, [])
    .join(',') || null;
