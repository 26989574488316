import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Fab,
  FormControlLabel,
  Icon,
  IconButton,
  MEDIUM_SCREEN_BREAKPOINT,
  PlusIcon,
  Switch,
  Typography,
  useDeviceDetect,
} from '@amperio/amperio-ui';
import { memo } from 'react';

import { IEventsViewSellerHeaderProps } from './events-view-seller-header.types';

const EventsViewSellerHeaderComponent = ({
  disableAddEventButton,
  isAbsenceSelected,
  isWorkingDaySelected,
  monthNameTranslationKey,
  onAddEventClick,
  onArrowLeftClick,
  onArrowRightClick,
  onSetAbsenceClick,
  onUnsetAbsenceClick,
  onWeekendsSwitchClick,
  selectedDate,
  shouldRenderWeekends,
  translations,
}: IEventsViewSellerHeaderProps): JSX.Element => {
  const { deviceData, windowSize } = useDeviceDetect();

  const isMobileCalendar = windowSize.width < MEDIUM_SCREEN_BREAKPOINT;

  return (
    <section className="events-view-seller-wrapper__header">
      <div className="header__calendar-actions">
        <div className="calendar-actions__arrows">
          <IconButton
            background="primary"
            fill="none"
            size={deviceData.isMobile ? 'small' : 'medium'}
            stroke="white"
            onClick={onArrowLeftClick}
          >
            <ArrowLeftIcon />
          </IconButton>
          <IconButton
            background="primary"
            fill="none"
            size={deviceData.isMobile ? 'small' : 'medium'}
            stroke="white"
            onClick={onArrowRightClick}
          >
            <ArrowRightIcon />
          </IconButton>
        </div>
        <div className="calendar-actions__date">
          <Typography strong as={deviceData.isMobile ? 'h4' : 'h2'}>
            {selectedDate?.getFullYear()}
          </Typography>
          <Typography
            strong
            as={deviceData.isMobile ? 'h4' : 'h2'}
            className="calendar-actions__date"
          >
            {translations[monthNameTranslationKey as never]}
          </Typography>
        </div>
        <FormControlLabel
          strong
          className="header__switch"
          control={<Switch checked={shouldRenderWeekends} onChange={onWeekendsSwitchClick} />}
          label={translations.dashboardEventsListHeaderCalendarSwitch}
          labelPlacement="start"
        />
      </div>
      <div className="header__events-actions">
        {isWorkingDaySelected && !isMobileCalendar && (
          <Button color="error" variant="outlined" onClick={onSetAbsenceClick}>
            {translations.dashboardEventsListHeaderSetAbsenceButton}
          </Button>
        )}
        {isAbsenceSelected && !isMobileCalendar && (
          <Button color="error" variant="outlined" onClick={onUnsetAbsenceClick}>
            {translations.dashboardEventsListHeaderUnsetAbsenceButton}
          </Button>
        )}
        {!deviceData.isMobile ? (
          <Button
            disabled={disableAddEventButton}
            startIcon={<PlusIcon />}
            variant="contained"
            onClick={onAddEventClick}
          >
            {translations.dashboardEventsListHeaderAddEventButton}
          </Button>
        ) : (
          <Fab disabled={disableAddEventButton} onClick={onAddEventClick}>
            <Icon stroke="white">
              <PlusIcon />
            </Icon>
          </Fab>
        )}
      </div>
    </section>
  );
};

export const EventsViewSellerHeader = memo(EventsViewSellerHeaderComponent);
