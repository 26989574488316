import { useQuery } from '@utils';

import { GET_ACCOUNTS_NAMES_ACTION_KEY, getAccountsNamesAction } from '@infrastructure';

export const useAccountsNamesActionQuery = () => {
  const { data: accountsNamesData, isLoading: isAccountsNamesDataLoading } = useQuery(
    GET_ACCOUNTS_NAMES_ACTION_KEY,
    getAccountsNamesAction,
    {
      staleTime: 30_000,
    },
  );

  const customersDropdownOptions =
    accountsNamesData?.data?.map((_record) => ({
      value: _record.id,
      label: _record.name,
    })) || [];

  const findCustomerById = (customerId: string) =>
    accountsNamesData?.data?.find((_option) => _option.id === customerId);

  return {
    findCustomerById,
    customersDropdownOptions,
    isAccountsNamesDataLoading,
    usersData: accountsNamesData?.data,
  };
};
