import { useAuth0 } from '@auth0/auth0-react';
import {
  MutationFunction,
  UseMutationOptions,
  useMutation as useMutationReactQuery,
} from 'react-query';

import { useAccessToken } from '@utils';

export const useMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
>(
  mutateFn: (
    getAccessToken: TGetAccessToken,
    loggedUserId: string | undefined,
  ) => MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>,
) => {
  const { user } = useAuth0();
  const { getAccessToken } = useAccessToken();

  return useMutationReactQuery(mutateFn(getAccessToken, user?.sub), options);
};
