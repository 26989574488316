import { ECountry, EProductCategory } from '@enums';

import { ICreateLeadFormFields } from '../../create-lead.types';

export const productCategoriesDropdownOptions = [
  {
    label: 'Photovoltaics',
    value: EProductCategory.PHOTOVOLTAICS,
  },
  {
    label: 'Heating pumps',
    value: EProductCategory.HEATING_PUMPS,
  },
  {
    label: 'Heating foils',
    value: EProductCategory.HEATING_FOILS,
  },
];

export const countryDropdownOptions = [
  {
    label: 'Spain',
    value: ECountry.SPAIN,
  },
];

export const defaultFormFields: ICreateLeadFormFields = {
  name: '',
  entryId: '',
  zipCode: '',
  products: [],
  phoneNumber: '',
  country: ECountry.SPAIN,
};
