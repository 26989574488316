export const translationStrings = [
  'common.table.no.data',
  'common.lead.status.new',
  'common.lead.status.invalid',
  'common.lead.status.callback',
  'common.lead.status.duplicate',
  'common.lead.status.interested',
  'common.lead.status.resignation',
  'dashboard.leads.list.name.column.label',
  'dashboard.leads.list.date.column.label',
  'dashboard.leads.list.status.column.label',
] as const;
