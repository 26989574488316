import {
  ECurrentTypeOfHeating,
  EInstallationSite,
  ELevelOfInsulation,
  EMonth,
  EPaymentMethod,
  EProductCategory,
  ERoofOrGround,
} from '@enums';

import { IProductCategoriesFormFields } from '@interfaces';

export const translationStrings = [
  'common.form.field.error.required',

  'form.product.categories.label',
  'form.product.categories.header',
  'form.product.categories.heating.foils',
  'form.product.categories.photovoltaics',
  'form.product.categories.heating.pumps',

  'form.product.categories.payment.method.cash',
  'form.product.categories.payment.method.label',
  'form.product.categories.payment.method.credit',

  'form.product.categories.house.area.label',
  'form.product.categories.house.area.min.error',
  'form.product.categories.house.area.max.error',

  'form.product.categories.level.of.insulation.label',
  'form.product.categories.level.of.insulation.passive',
  'form.product.categories.level.of.insulation.energy.saving',
  'form.product.categories.level.of.insulation.well.insulated',
  'form.product.categories.level.of.insulation.medium.insulated',
  'form.product.categories.level.of.insulation.poorly.insulated',

  'form.product.categories.current.type.of.heating.label',
  'form.product.categories.current.type.of.heating.other',
  'form.product.categories.current.type.of.heating.gas.stove',
  'form.product.categories.current.type.of.heating.coal.stove',
  'form.product.categories.current.type.of.heating.electric.heating',

  'form.product.categories.installation.site.label',
  'form.product.categories.installation.site.north',
  'form.product.categories.installation.site.south',
  'form.product.categories.installation.site.east',
  'form.product.categories.installation.site.west',

  'form.product.categories.roof.or.ground.label',
  'form.product.categories.roof.or.ground.roof',
  'form.product.categories.roof.or.ground.ground',

  'form.product.categories.roof.area.label',
  'form.product.categories.roof.area.min.error',
  'form.product.categories.roof.area.max.error',

  'form.product.categories.monthly.electric.bill.label',
  'form.product.categories.monthly.electric.bill.min.error',
  'form.product.categories.monthly.electric.bill.max.error',

  'form.product.categories.month.of.the.given.bill.label',

  'common.month.january',
  'common.month.february',
  'common.month.march',
  'common.month.april',
  'common.month.may',
  'common.month.june',
  'common.month.july',
  'common.month.august',
  'common.month.september',
  'common.month.october',
  'common.month.november',
  'common.month.december',
] as const;

export const getProductCategoriesDropdownOptions = (translations: Record<string, string>) => [
  {
    value: EProductCategory.PHOTOVOLTAICS,
    label: translations.formProductCategoriesPhotovoltaics,
  },
  {
    value: EProductCategory.HEATING_PUMPS,
    label: translations.formProductCategoriesHeatingPumps,
  },
  {
    value: EProductCategory.HEATING_FOILS,
    label: translations.formProductCategoriesHeatingFoils,
  },
];

export const getPaymentMethodDropdownOptions = (translations: Record<string, string>) => [
  {
    value: EPaymentMethod.CASH,
    label: translations.formProductCategoriesPaymentMethodCash,
  },
  {
    value: EPaymentMethod.CREDIT,
    label: translations.formProductCategoriesPaymentMethodCredit,
  },
];

export const getLevelOfInsulationDropdownOptions = (translations: Record<string, string>) => [
  {
    value: ELevelOfInsulation.PASSIVE,
    label: translations.formProductCategoriesLevelOfInsulationPassive,
  },
  {
    value: ELevelOfInsulation.ENERGY_SAVING,
    label: translations.formProductCategoriesLevelOfInsulationEnergySaving,
  },
  {
    value: ELevelOfInsulation.WELL_INSULATED,
    label: translations.formProductCategoriesLevelOfInsulationWellInsulated,
  },
  {
    value: ELevelOfInsulation.MEDIUM_INSULATED,
    label: translations.formProductCategoriesLevelOfInsulationMediumInsulated,
  },
  {
    value: ELevelOfInsulation.POORLY_INSULATED,
    label: translations.formProductCategoriesLevelOfInsulationPoorlyInsulated,
  },
];

export const getCurrentTypeOfHeatingDropdownOptions = (translations: Record<string, string>) => [
  {
    value: ECurrentTypeOfHeating.COAL_STOVE,
    label: translations.formProductCategoriesCurrentTypeOfHeatingCoalStove,
  },
  {
    value: ECurrentTypeOfHeating.GAS_STOVE,
    label: translations.formProductCategoriesCurrentTypeOfHeatingGasStove,
  },
  {
    value: ECurrentTypeOfHeating.ELECTRIC_HEATING,
    label: translations.formProductCategoriesCurrentTypeOfHeatingElectricHeating,
  },
  {
    value: ECurrentTypeOfHeating.OTHER,
    label: translations.formProductCategoriesCurrentTypeOfHeatingOther,
  },
];

export const getInstallationSiteDropdownOptions = (translations: Record<string, string>) => [
  {
    value: EInstallationSite.NORTH,
    label: translations.formProductCategoriesInstallationSiteNorth,
  },
  {
    value: EInstallationSite.SOUTH,
    label: translations.formProductCategoriesInstallationSiteSouth,
  },
  {
    value: EInstallationSite.EAST,
    label: translations.formProductCategoriesInstallationSiteEast,
  },
  {
    value: EInstallationSite.WEST,
    label: translations.formProductCategoriesInstallationSiteWest,
  },
];

export const getRoofOrGroundDropdownOptions = (translations: Record<string, string>) => [
  {
    value: ERoofOrGround.ROOF,
    label: translations.formProductCategoriesRoofOrGroundRoof,
  },
  {
    value: ERoofOrGround.GROUND,
    label: translations.formProductCategoriesRoofOrGroundGround,
  },
];

export const getMonthOfTheGivenBillDropdownOptions = (translations: Record<string, string>) => [
  {
    value: EMonth.JANUARY,
    label: translations.commonMonthJanuary,
  },
  {
    value: EMonth.FEBRUARY,
    label: translations.commonMonthFebruary,
  },
  {
    value: EMonth.MARCH,
    label: translations.commonMonthMarch,
  },
  {
    value: EMonth.APRIL,
    label: translations.commonMonthApril,
  },
  {
    value: EMonth.MAY,
    label: translations.commonMonthMay,
  },
  {
    value: EMonth.JUNE,
    label: translations.commonMonthJune,
  },
  {
    value: EMonth.JULY,
    label: translations.commonMonthJuly,
  },
  {
    value: EMonth.AUGUST,
    label: translations.commonMonthAugust,
  },
  {
    value: EMonth.SEPTEMBER,
    label: translations.commonMonthSeptember,
  },
  {
    value: EMonth.OCTOBER,
    label: translations.commonMonthOctober,
  },
  {
    value: EMonth.NOVEMBER,
    label: translations.commonMonthNovember,
  },
  {
    value: EMonth.DECEMBER,
    label: translations.commonMonthDecember,
  },
];

export const productCategoriesFormDefaultFormFields: IProductCategoriesFormFields = {
  roofArea: '',
  houseArea: '',
  roofOrGround: '',
  paymentMethod: '',
  installationSite: [],
  productCategories: [],
  levelOfInsulation: '',
  monthlyElectricBill: '',
  monthOfTheGivenBill: '',
  currentTypeOfHeating: '',
};
