import { ConfirmationModalProvider, ToastProvider } from '@amperio/amperio-ui';
import { BrowserRouter } from 'react-router-dom';

import {
  Auth0ProviderWithHistory,
  HealthCheckProvider,
  LocaleProvider,
  QueryClientProvider,
  QueryParamProvider,
} from '..';
import { IAppProviderProps } from './app-provider.types';

export const AppProvider = ({ children }: IAppProviderProps): JSX.Element => (
  <BrowserRouter>
    <QueryParamProvider>
      <ToastProvider>
        <ConfirmationModalProvider>
          <Auth0ProviderWithHistory>
            <LocaleProvider>
              <QueryClientProvider>
                <HealthCheckProvider>{children}</HealthCheckProvider>
              </QueryClientProvider>
            </LocaleProvider>
          </Auth0ProviderWithHistory>
        </ConfirmationModalProvider>
      </ToastProvider>
    </QueryParamProvider>
  </BrowserRouter>
);
