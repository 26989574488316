import { AxiosResponse } from 'axios';

import { prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IApiAccount } from '@interfaces';

export const GET_ACCOUNT_BY_ID_ACTION_KEY = 'get-account-by-id-action-key';

export const getAccountByIdAction =
  (accountId: string) =>
  (getAccessToken: TGetAccessToken) =>
  async (): Promise<AxiosResponse<IApiAccount>> => {
    const accessToken = await getAccessToken();

    return axiosInstance.get(
      `${EApiEndpoint.ACCOUNTS}/${accountId}`,
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
