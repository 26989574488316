import { useLocalStorage } from '@amperio/amperio-ui';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { objectToQueryString, useAccessToken, useTranslation } from '@utils';

import { AUTH0_CLAIMS_LOCAL_STORAGE_KEY } from '@infrastructure';

import { ERoutes } from '@enums';

import { translationStrings } from './use-save-user-claims-to-local-storage.defaults';

export const useSaveUserClaimsToLocalStorage = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useAccessToken();
  const [isLoading, setIsLoading] = useState(true);
  const translations = useTranslation(translationStrings);
  const [, setUserClaims] = useLocalStorage<string[]>(AUTH0_CLAIMS_LOCAL_STORAGE_KEY, []);

  const fetchAndSaveUserClaims = async () => {
    const accessToken = await getAccessToken();
    const { permissions } = jwtDecode<{ permissions: string[] }>(accessToken || '');

    setUserClaims(permissions);
    setIsLoading(false);

    if (!permissions || permissions.length === 0) {
      const queryParams = {
        showLogoutButton: true,
        showBackToHomepageButton: true,
        message: translations.errorPageMessageNoRoles,
      };

      navigate(`${ERoutes.FORBIDDEN}${objectToQueryString(queryParams)}`);
    }
  };

  useEffect(() => {
    fetchAndSaveUserClaims();

    return () => {
      setUserClaims([]);
    };
  }, []);

  return { isLoading };
};
