export const translationStrings = [
  'calendar.more.expanded.label',
  'calendar.more.collapsed.label',
  'common.abbreviated.weekday.monday',
  'common.abbreviated.weekday.tuesday',
  'common.abbreviated.weekday.wednesday',
  'common.abbreviated.weekday.thursday',
  'common.abbreviated.weekday.friday',
  'common.abbreviated.weekday.saturday',
  'common.abbreviated.weekday.sunday',
  'calendar.appointment.reservation.in.progress',
] as const;
