import { Button, Paper, Typography, useDeviceDetect } from '@amperio/amperio-ui';
import { useEffect, useState } from 'react';

import { addPlusToPhoneNumber, useTranslation } from '@utils';

import { translationStrings } from './lead-info.defaults';
import { ILeadInfoProps } from './lead-info.types';

import './lead-info.styles.scss';

export const LeadInfo = ({
  hadPhoneCall,
  name,
  phoneNumber,
  setHadPhoneCall,
}: ILeadInfoProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);
  const [windowOpenObject, setWindowOpenObject] = useState<Window | null>(null);

  const parsedPhoneNumber = addPlusToPhoneNumber(phoneNumber);

  const openPhoneModal = () => setWindowOpenObject(window.open(`tel:${parsedPhoneNumber}`));

  useEffect(() => {
    if (windowOpenObject) {
      const loop = setInterval(() => {
        if (windowOpenObject?.closed) {
          clearInterval(loop);
          setHadPhoneCall(true);
        }
      }, 1000);

      return () => clearInterval(loop);
    }
  }, [windowOpenObject]);

  return (
    <Paper className="lead-info-wrapper" padding={deviceData.isMobile ? 'sm' : 'md'}>
      <Typography strong as={deviceData.isMobile ? 'h4' : 'h2'}>
        {`${translations.dashboardLeadSectionFormCallLabel} - ${name}`}
      </Typography>
      <div className="lead-info-wrapper__buttons-container">
        <Button
          data-testid="lead-info--button-phone"
          size={deviceData.isMobile ? 'small' : 'medium'}
          variant="contained"
          onClick={openPhoneModal}
        >
          {parsedPhoneNumber}
        </Button>
        <Button
          data-testid="lead-info--button-summary"
          disabled={hadPhoneCall}
          size={deviceData.isMobile ? 'small' : 'medium'}
          variant="contained"
          onClick={() => setHadPhoneCall(true)}
        >
          {translations.dashboardLeadSectionFormShowSummaryLabel}
        </Button>
      </div>
    </Paper>
  );
};
