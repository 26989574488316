import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { AUTH0_SCOPES } from '@infrastructure';

import { ERoutes } from '@enums';

import { IAuth0ProviderWithHistoryProps } from './auth0-provider-with-history.types';

const DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;

export const Auth0ProviderWithHistory = ({
  children,
}: IAuth0ProviderWithHistoryProps): JSX.Element => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState) =>
    navigate(appState?.returnTo || window.location.pathname);

  return (
    <Auth0Provider
      useRefreshTokens
      audience={AUDIENCE}
      cacheLocation={
        ['local', 'test'].includes(process.env.REACT_APP_ENV) ? 'localstorage' : 'memory'
      }
      clientId={CLIENT_ID}
      domain={DOMAIN}
      redirectUri={`${window.location.origin}/${ERoutes.DASHBOARD}`}
      scope={AUTH0_SCOPES}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
