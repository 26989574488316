// routing
export enum ERoutes {
  ANY = '*',
  DASHBOARD = 'dashboard',
  FORBIDDEN = '/403',
  INTERNAL_SERVER_ERROR = '/500',
  LOGIN = '/login',
  NOT_FOUND = '/404',
}

export enum EDashboardRoutes {
  ACCOUNTS = 'accounts',
  CREATE_LEAD = 'create-lead',
  EVENTS = 'events',
  HOME = 'home',
  LEADS = 'leads',
}

export enum EDashboardEventsRoutes {
  LIST = 'list',
  UPSERT_ACCOUNT = 'upsert-account',
  UPSERT_EVENT = 'upsert-event',
}

export enum EDashboardAccountsRoutes {
  LIST = 'list',
  UPSERT_ACCOUNT = 'upsert-account',
  UPSERT_EVENT = 'upsert-event',
}

export enum EDashboardLeadsRoutes {
  LIST = 'list',
  UPSERT_ACCOUNT = 'upsert-account',
  UPSERT_EVENT = 'upsert-event',
  UPSERT_LEAD = 'upsert-lead',
}

export enum ECountry {
  SPAIN = 'spain',
}

// api endpoint
export enum EApiEndpoint {
  ABSENCES = '/absences',
  ACCOUNTS = '/accounts',
  ACCOUNTS_NAMES = '/accounts/names',
  ACCOUNTS_OVERVIEW = '/accounts/overview',
  CALENDAR = '/calendar',
  CALENDAR_EVENTS = '/calendar-events',
  CALENDAR_EVENTS_BY_ACCOUNT = '/calendar-events/by-account',
  CALENDAR_EVENTS_UNHANDLED_EVENTS = '/calendar-events/unhandled',
  DASHBOARD = '/dashboard',
  HEALTH_CHECK = '/health-check',
  LEADS = '/leads',
  LEADS_OVERVIEW = '/leads/overview',
  LEADS_USER_ASSIGNMENTS = '/leads/user-assignments',
  REGIONS = '/regions',
  USERS = '/users',
}

// other
export enum EUserClaim {
  ASSIGN_USER_ACCOUNTS = 'assign-user:accounts',
  ASSIGN_USER_CALENDAR_EVENTS = 'assign-user:calendar-events',
  ASSIGN_USER_LEADS = 'assign-user:leads',
  CREATE_ABSENCES = 'create:absences',
  CREATE_ACCOUNTS = 'create:accounts',
  CREATE_CALENDAR_EVENTS = 'create:calendar-events',
  CREATE_LEADS = 'create:leads',
  CREATE_PRODUCT_SURVEYS = 'create:product-surveys',
  DELETE_ABSENCES = 'delete:absences',
  GET_ABSENCES = 'get:absences',
  GET_ACCOUNTS = 'get:accounts',
  GET_ALL_ACCOUNTS = 'get-all:accounts',
  GET_ALL_BY_DATE_AND_USER_CALENDAR_EVENTS = 'get-all-by-date-and-user:calendar-events',
  GET_ALL_CALENDAR_EVENTS = 'get-all:calendar-events',
  GET_ALL_LEADS = 'get-all:leads',
  GET_ALL_USERS = 'get-all:users',
  GET_CALENDAR_EVENTS = 'get:calendar-events',
  GET_DASHBOARD = 'get:dashboard',
  GET_LEADS = 'get:leads',
  GET_PRODUCT_SURVEYS = 'get:product-surveys',
  GET_USERS = 'get:users',
  UPADTE_PRODUCT_SURVEYS = 'update:product-surveys',
  UPDATE_ACCOUNTS = 'update:accounts',
  UPDATE_CALENDAR_EVENTS = 'update:calendar-events',
  UPDATE_LEADS = 'update:leads',
}

export enum ECustomHttpCode {
  ACCOUNT_ACCESS_ERROR = '40224_ACCOUNT_ACCESS_ERROR',
  ACCOUNT_ALREADY_EXIST = '40223_ACCOUNT_ALREADY_EXIST',
  ACCOUNT_FORM_INVALID_FORMAT = '40222_ACCOUNT_FORM_INVALID_FORMAT',
  ACCOUNT_NO_REQUIRED_DATA = '40221_ACCOUNT_NO_REQUIRED_DATA',
  EVENT_ACCESS_ERROR = '40324_EVENT_ACCESS_ERROR',
  EVENT_FORM_INVALID_FORMAT = '40322_EVENT_FORM_INVALID_FORMAT',
  EVENT_NO_REQUIRED_DATA = '40321_EVENT_NO_REQUIRED_DATA',
  LEAD_ACCESS_ERROR = '40124_LEAD_ACCESS_ERROR',
}

export enum ELocale {
  EN = 'en',
  ES = 'es',
}

export enum EUnit {
  SQUARE_METERS = 'squareMeters',
}

export enum ECurrency {
  EUR = 'eur',
  PLN = 'pln',
}

export enum ECalendarViews {
  MOBILE = 'mobile',
  MONTHLY = 'dayGridMonth',
  WEEKLY = 'dayGridWeek',
}

export enum ESortDirection {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
}

export enum EEventType {
  CALL = 'call',
  MEETING = 'meeting',
  RESERVATION = 'reservation',
}

export enum EPaymentMethod {
  CASH = 'cash',
  CREDIT = 'credit',
}

export enum EProductCategory {
  HEATING_FOILS = 'foilHeaters',
  HEATING_PUMPS = 'heatPumps',
  PHOTOVOLTAICS = 'photovoltaics',
}

export enum ELevelOfInsulation {
  ENERGY_SAVING = 'energySaving',
  MEDIUM_INSULATED = 'medium',
  PASSIVE = 'passive',
  POORLY_INSULATED = 'poor',
  WELL_INSULATED = 'well',
}

export enum ECurrentTypeOfHeating {
  COAL_STOVE = 'coalStove',
  ELECTRIC_HEATING = 'electricHeating',
  GAS_STOVE = 'gasStove',
  OTHER = 'other',
}

export enum EInstallationSite {
  EAST = 'east',
  NORTH = 'north',
  SOUTH = 'south',
  WEST = 'west',
}

export enum ERoofOrGround {
  GROUND = 'ground',
  ROOF = 'roof',
}

export enum EMonth {
  APRIL = 'april',
  AUGUST = 'august',
  DECEMBER = 'december',
  FEBRUARY = 'february',
  JANUARY = 'january',
  JULY = 'july',
  JUNE = 'june',
  MARCH = 'march',
  MAY = 'may',
  NOVEMBER = 'november',
  OCTOBER = 'october',
  SEPTEMBER = 'september',
}

export enum ELeadStatus {
  CALLBACK = 'callback',
  DUPLICATE = 'duplicate',
  INTERESTED = 'interested',
  INVALID = 'invalid',
  NEW = 'new',
  RESIGNATION = 'resignation',
}

export enum EEventStatus {
  BID_SUBMITTED = 'bidSubmitted',
  MISSED = 'missed',
  PLANNED = 'planned',
  REJECTED = 'rejected',
  RESIGNATION = 'resignation',
  SALE = 'sale',
  SALES_OPPORTUNITY = 'salesOpportunity',
}
