import { DataGrid } from '@amperio/amperio-ui';

import { IApiLead } from '@interfaces';

import { ILeadsTableProps } from './leads-table.types';

import './leads-table.styles.scss';

export const LeadsTable = ({
  checkboxSelection = false,
  isLoading,
  onRowClick,
  setSelectedRowKeys,
  ...restProps
}: ILeadsTableProps): JSX.Element => (
  <DataGrid
    shouldCalculateRowsPerPageDynamically
    shouldHandleFiltering
    checkboxSelection={checkboxSelection}
    className="leads-table-wrapper"
    isLoading={isLoading}
    onBodyRow={(record: unknown) => ({
      pointer: true,
      onClick: () => onRowClick(record as IApiLead),
      'data-testid': 'leads-table--row',
    })}
    onSelectionChange={(rowKeys) => {
      if (setSelectedRowKeys) setSelectedRowKeys(rowKeys);
    }}
    {...restProps}
  />
);
