import { EEventType } from '@enums';

export const translationStrings = [
  'dashboard.events.list.header.calendar.switch',
  'dashboard.events.list.header.add.event.button',
  'dashboard.events.list.header.set.absence.button',
  'dashboard.events.list.header.unset.absence.button',

  'common.month.january',
  'common.month.february',
  'common.month.march',
  'common.month.april',
  'common.month.may',
  'common.month.june',
  'common.month.july',
  'common.month.august',
  'common.month.september',
  'common.month.october',
  'common.month.november',
  'common.month.december',
  'calendar.reservation.title',
  'common.prompt.something.went.wrong',
  'notifications.success.absence.set',
  'notifications.success.absence.delete',
] as const;

export const calendarMockup = [
  {
    events: [
      {
        title: 'Maegan Skinner',
        start: '2022-01-08T14:30:00',
        extendedProps: {
          start: '2022-01-08T14:30:00',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Skylah Austin',
        start: '2022-01-08T15:30:00',
        extendedProps: {
          start: '2022-01-08T15:30:00',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Skylah Austin',
        start: '2022-01-08T16:20:00',
        extendedProps: {
          start: '2022-01-08T16:20:00',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2022-01-08T16:30:00',
        extendedProps: {
          start: '2022-01-08T16:30:00',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2022-01-08T17:30:00',
        extendedProps: {
          start: '2022-01-08T17:30:00',
          product: 'Solar Panels',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2022-01-07T17:30:00',
        extendedProps: {
          start: '2022-01-07T17:30:00',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2021-01-06T17:30:00',
        extendedProps: {
          start: '2021-01-06T17:30:00',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2021-12-19T17:30:00',
        extendedProps: {
          start: '2021-12-19T17:30:00',
          product: 'Solar Panels',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Skylah Austin',
        start: '2021-12-21T13:30:00',
        extendedProps: {
          start: '2021-12-21T13:30:00',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2021-12-18T17:30:00',
        extendedProps: {
          start: '2021-12-18T17:30:00',
          product: 'Solar Panels',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2021-12-19T17:30:00',
        extendedProps: {
          start: '2021-12-19T17:30:00',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2021-12-18T09:30:00',
        extendedProps: {
          start: '2021-12-18T09:30:00',
          product: 'Solar Panels',
          type: EEventType.CALL,
        },
      },
      {
        title: 'Antoni Barton',
        start: '2021-12-18T12:30:00',
        extendedProps: {
          start: '2021-12-18T12:30:00',
          product: 'Solar Panels',
          type: EEventType.CALL,
        },
      },
      {
        title: 'Skylah Austin',
        start: '2021-01-06T12:30:00',
        extendedProps: {
          start: '2021-01-06T12:30:00',
          type: EEventType.CALL,
        },
      },
      {
        start: '2021-01-07T08:30:00',
        extendedProps: {
          start: '2021-01-07T08:30:00',
          type: EEventType.CALL,
        },
      },
      {
        title: 'Antoni Barton',
        start: '2022-01-08T08:30:00',
        extendedProps: {
          start: '2022-01-08T08:30:00',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Antoni Barton',
        start: '2021-12-29T18:30:00',
        extendedProps: {
          start: '2021-12-29T18:30:00',
          type: EEventType.CALL,
        },
      },
      {
        title: 'Antoni Barton',
        start: '2021-12-06T16:30:00',
        extendedProps: {
          start: '2021-12-29T16:30:00',
          type: EEventType.CALL,
        },
      },
    ],
  },
];
