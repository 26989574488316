import { AxiosResponse } from 'axios';

import { prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IApiUpsertResponse } from '../../upsert-event.types';

export const createCalendarEventAction =
  (getAccessToken: TGetAccessToken) =>
  async (variables: unknown): Promise<AxiosResponse<IApiUpsertResponse>> => {
    const accessToken = await getAccessToken();

    return axiosInstance.post(
      EApiEndpoint.CALENDAR,
      variables,
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
