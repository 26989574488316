import { useConfirmationModal } from '@amperio/amperio-ui';
import { useCallback } from 'react';

import { useBlocker } from '@utils';

export function usePrompt(title: string, message: string, when = true) {
  const { confirm } = useConfirmationModal();
  const blocker = useCallback(
    async (tx) => {
      confirm({
        title: title || '',
        description: message || '',
        confirmationButtonProps: { autoFocus: true },
      }).then(() => tx.retry());
    },
    [message],
  );

  useBlocker(blocker, when);
}
