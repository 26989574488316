import { AxiosResponse } from 'axios';

import { prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IGetDashboardSummaryResponse } from './get-dashboard-summary.types';

export const GET_DASHBOARD_SUMMARY_ACTION_KEY = 'get-dashboard-summary-action-key';

export const getDashboardSummaryAction =
  (getAccessToken: TGetAccessToken) =>
  async (): Promise<AxiosResponse<IGetDashboardSummaryResponse>> => {
    const accessToken = await getAccessToken();

    return axiosInstance.get(
      EApiEndpoint.DASHBOARD,
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
