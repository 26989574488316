import { TEXT_INPUT_REGEX, isString } from '@amperio/amperio-ui';

export const containEntersOrSpaces =
  (message: string = '') =>
  (value: unknown) => {
    if (!isString(value) || value.trim().length === 0) {
      return true;
    }

    return TEXT_INPUT_REGEX.test(String(value)) || message;
  };
