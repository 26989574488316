import { Typography, useDeviceDetect } from '@amperio/amperio-ui';
import { useForm } from 'react-hook-form';

import { useMutation, useTranslation } from '@utils';

import { translationStrings } from './accept-invitation.defaults';
import { IAcceptInvitationProps } from './accept-invitation.types';
import { acceptInvitationAction } from './api';
import { IAcceptInvitationFromFields, InvitationForm, defaultFormFields } from './molecules';

import './accept-invitation.styles.scss';

export const AcceptInvitation = ({
  invitationId,
  makeLoginWithRedirect,
  organizationId,
  organizationName,
  userEmail,
}: IAcceptInvitationProps): JSX.Element => {
  const { control, handleSubmit } = useForm<IAcceptInvitationFromFields>({
    reValidateMode: 'onChange',
    defaultValues: { ...defaultFormFields, email: userEmail || defaultFormFields.email },
  });

  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  const { isLoading: isAcceptInvitationLoading, mutateAsync: mutateAcceptInvitationAsync } =
    useMutation(acceptInvitationAction(organizationId!, invitationId!));

  const onSubmit = handleSubmit(async (data: IAcceptInvitationFromFields) => {
    await mutateAcceptInvitationAsync(data);

    makeLoginWithRedirect();
  });

  return (
    <div className="accept-invitation-wrapper">
      <Typography
        unselectable
        as={deviceData.isMobile ? 'h4' : 'h3'}
        className="accept-invitation-wrapper__header"
      >
        {translations.acceptInvitationHeader}
      </Typography>
      <Typography
        unselectable
        as="span"
        className="accept-invitation-wrapper__subheader"
        size={deviceData.isMobile ? 'sm' : 'md'}
      >
        {translations.acceptInvitationSubheaderPrefix} {organizationName}{' '}
        {translations.acceptInvitationSubheaderSuffix}
      </Typography>
      <InvitationForm control={control} isLoading={isAcceptInvitationLoading} onSubmit={onSubmit} />
    </div>
  );
};
