import { AxiosResponse } from 'axios';

import { prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IApiAccount } from '@interfaces';

export const getAccountByIdAction =
  (getAccessToken: TGetAccessToken) =>
  async (accountId: string): Promise<AxiosResponse<IApiAccount>> => {
    const accessToken = await getAccessToken();

    return axiosInstance.get(
      `${EApiEndpoint.ACCOUNTS}/${accountId}`,
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
