import {
  Avatar,
  Icon,
  LogoutIcon,
  OfflineIcon,
  Spinner,
  ToggleMenuButton,
  Typography,
} from '@amperio/amperio-ui';
import { PartnerLogoIcon } from '@assets';
import { useAuth0 } from '@auth0/auth0-react';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { Offline } from 'react-detect-offline';
import { useIsFetching, useIsMutating } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGhostMode, useTranslation } from '@utils';

import { EDashboardRoutes } from '@enums';

import { translationStrings } from './mobile-topbar.defaults';
import { IMobileTopbarProps } from './mobile-topbar.types';

import './mobile-topbar.styles.scss';

export const MobileTopbar = ({ options }: IMobileTopbarProps): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMutating = useIsMutating();
  const isFetching = useIsFetching();
  const { logout, user } = useAuth0();
  const { isGhostMode } = useGhostMode();
  const [isOpen, setIsOpen] = useState(false);
  const translations = useTranslation(translationStrings);

  const toggleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (isOpen) {
      toggleMenu();
    }
  }, [pathname]);

  const redirect = (isActive: boolean, path: string) => () => {
    if (!isActive) {
      navigate(path);
    }

    toggleMenu();
  };

  return (
    <div className="mobile-topbar-wrapper">
      <PartnerLogoIcon
        className="mobile-topbar-wrapper__logo"
        onClick={() => navigate(EDashboardRoutes.HOME, { replace: true })}
      />
      <div className="mobile-topbar-wrapper__right-container" data-testid="mobile-topbar--button">
        <Offline polling={false}>
          <Icon fill="none" size="small" stroke="white">
            <OfflineIcon />
          </Icon>
        </Offline>
        {isFetching || isMutating ? <Spinner color="white" size="small" /> : null}
        {isGhostMode() && user?.family_name && (
          <Typography strong as="span" color="white">
            {user?.family_name}
          </Typography>
        )}
        <Avatar size="small" src={user?.picture} />
        <ToggleMenuButton isActive={isOpen} onClick={toggleMenu} />
      </div>
      <div
        className={classnames('mobile-topbar-wrapper__sidebar', {
          'mobile-topbar-wrapper__sidebar--hidden': !isOpen,
        })}
      >
        <span>
          {options.map(({ IconComponent, label, redirectTo, toMatchWith }) => {
            const shouldBlockRoute = pathname.includes(redirectTo);
            const isActive = pathname.includes(toMatchWith || redirectTo);

            return (
              <div
                key={redirectTo}
                className={classnames('mobile-topbar-wrapper__sidebar__option', {
                  'mobile-topbar-wrapper__sidebar__option--active': isActive,
                })}
                data-testid={`mobile-tobpar--button-${label}`}
                onClick={redirect(shouldBlockRoute, redirectTo)}
              >
                <IconComponent className="mobile-topbar-wrapper__sidebar__icon" />
                <Typography as="h6" color="white">
                  {label}
                </Typography>
              </div>
            );
          })}
        </span>
        <div
          key="logout-button"
          className="mobile-topbar-wrapper__sidebar__option"
          onClick={() => logout()}
        >
          <LogoutIcon className="mobile-topbar-wrapper__sidebar__icon mobile-topbar-wrapper__sidebar__icon--logout" />
          <Typography as="h6" color="white">
            {translations.commonButtonsLogout}
          </Typography>
        </div>
      </div>
    </div>
  );
};
