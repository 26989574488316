import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Accounts, CreateLead, Events, FullScreenLoading, Home, Leads } from '@domain';

import { useModal } from '@ui';

import { useQuery, useSaveUserClaimsToLocalStorage } from '@utils';

import { EDashboardRoutes, ERoutes } from '@enums';

import { isBlockedAction } from './api';
import { BlockModalContent } from './molecules';
import { DashboardTemplate } from './templates';

export const DashboardPage = (): JSX.Element => {
  const { user } = useAuth0();
  const { Modal, show } = useModal(false, true);
  const { isLoading: isSaveUserClaimsToLocalStorageLoading } = useSaveUserClaimsToLocalStorage();

  const { data: blockedActionData, isLoading: isBlockedActionLoading } = useQuery(
    ['is-blocked', user?.sub, user?.org_id],
    isBlockedAction(user?.sub!, user?.org_id!),
  );

  useEffect(() => {
    if (blockedActionData?.data?.isBlocked) {
      show();
    }
  }, [blockedActionData?.data]);

  if (isSaveUserClaimsToLocalStorageLoading || isBlockedActionLoading) {
    return <FullScreenLoading />;
  }

  return (
    <DashboardTemplate>
      <Modal disablePortal>
        <BlockModalContent
          kamName={blockedActionData?.data?.kamName}
          kamPhone={blockedActionData?.data?.kamPhone}
        />
      </Modal>
      <Routes>
        <Route element={<Home />} path={EDashboardRoutes.HOME} />
        <Route element={<Leads />} path={`${EDashboardRoutes.LEADS}/*`} />
        <Route element={<Events />} path={`${EDashboardRoutes.EVENTS}/*`} />
        <Route element={<Accounts />} path={`${EDashboardRoutes.ACCOUNTS}/*`} />
        <Route element={<CreateLead />} path={EDashboardRoutes.CREATE_LEAD} />
        <Route index element={<Navigate to={EDashboardRoutes.HOME} />} />
        <Route element={<Navigate replace to={ERoutes.NOT_FOUND} />} path={ERoutes.ANY} />
      </Routes>
    </DashboardTemplate>
  );
};
