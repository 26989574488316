import { Children } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ERoutes } from '@enums';

import { routingConfig } from './routing.config';
import { getConditionalRoute } from './utils';

export const Routing = (): JSX.Element => (
  <Routes>
    {Children.toArray(
      Object.keys(routingConfig).map((path) => {
        const currentRoute = routingConfig[path as ERoutes];

        let parsedPath = path;

        if (parsedPath !== ERoutes.ANY) {
          parsedPath = currentRoute.isDescendantWrapper ? `${parsedPath}/*` : parsedPath;
        }

        const element = getConditionalRoute({ ...currentRoute, path: parsedPath });

        return <Route {...currentRoute} element={element} path={parsedPath} />;
      }),
    )}
    <Route element={<Navigate to={ERoutes.DASHBOARD} />} path="/" />
  </Routes>
);
