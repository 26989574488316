import { prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IApiAccount } from '@interfaces';

export const updateAccountAction =
  (getAccessToken: TGetAccessToken) =>
  async ({ accountId, requestData }: { accountId: string; requestData: IApiAccount }) => {
    const accessToken = await getAccessToken();

    return axiosInstance.put(
      `${EApiEndpoint.ACCOUNTS}/${accountId}`,
      requestData,
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
