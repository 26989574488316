import { isString } from '@amperio/amperio-ui';

import { removePlusFromPhoneNumber } from '@utils';

export const addPlusToPhoneNumber = (phoneNumber: string | null | undefined) => {
  if (!isString(phoneNumber)) {
    return '';
  }

  const parsedPhoneNumber = removePlusFromPhoneNumber(phoneNumber);

  return `+${parsedPhoneNumber}`;
};
