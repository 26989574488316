import {
  buildApiUrl,
  generateFilterQueryParam,
  generateSortQueryParam,
  prepareAxiosConfigWithAccessToken,
} from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IGetLeadsTableDataAttributes } from './get-leads-table-data.types';

export const getLeadsTableDataAction =
  (getAccessToken: TGetAccessToken) =>
  async ({ filters, sorts, ...restProps }: IGetLeadsTableDataAttributes) => {
    const accessToken = await getAccessToken();

    return axiosInstance.get(
      buildApiUrl(EApiEndpoint.LEADS_OVERVIEW, {
        ...restProps,
        sort: generateSortQueryParam(sorts),
        filter: generateFilterQueryParam(filters),
      }),
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
