import {
  generateArrayWithHoursAndMinutes,
  stringArrayToDropdownOptions,
} from '@amperio/amperio-ui';
import { getHours, getMinutes } from 'date-fns';

import { EEventType } from '@enums';

import { IGeneralFormFields } from '@interfaces';

export const translationStrings = [
  'common.buttons.ok',
  'common.buttons.clear',
  'common.buttons.cancel',
  'common.date.picker.today',
  'common.date.picker.selected.date',
  'common.form.field.error.required',
  'form.general.header',
  'form.general.customer.label',
  'form.general.event.type.label',
  'form.general.event.type.call',
  'form.general.event.type.meeting',
  'form.general.owner.label',
  'form.general.select.meeting.date.label',
  'form.general.select.meeting.hour.label',
  'form.general.select.meeting.length.label',
  'form.general.phone.number.label',
] as const;

export const getEventTypeDropdownOptions = (translations: Record<string, string>) => [
  { label: translations.formGeneralEventTypeMeeting, value: EEventType.MEETING },
  { label: translations.formGeneralEventTypeCall, value: EEventType.CALL },
];

export const meetingLengthDropdownOptions = [
  {
    value: 15,
    label: '15m',
  },
  {
    value: 30,
    label: '30m',
  },
  {
    value: 45,
    label: '45m',
  },
  {
    value: 60,
    label: '1h',
  },
  {
    value: 75,
    label: '1h 15m',
  },
  {
    value: 90,
    label: '1h 30m',
  },
  {
    value: 105,
    label: '1h 45m',
  },
  {
    value: 120,
    label: '2h',
  },
];

const MIN_HOUR = 6;
const MAX_HOUR = 22;
const arrayWithHoursAndMinutes = generateArrayWithHoursAndMinutes(MIN_HOUR, MAX_HOUR);

export const meetingHourDropdownOptions = stringArrayToDropdownOptions(arrayWithHoursAndMinutes);

const getNearestMeetingHour = () => {
  const currentDate = new Date();
  const currentHour = getHours(currentDate);

  if (currentHour <= MIN_HOUR || currentHour > MAX_HOUR - 1) {
    return arrayWithHoursAndMinutes[0];
  }

  const currentMinutes = getMinutes(currentDate);
  const roundedMinutes = (Math.round(currentMinutes / 30) * 30) % 60;

  return `${currentHour}:${roundedMinutes.toString().padStart(2, '0')}`;
};

export const generalFormDefaultFormFields: IGeneralFormFields = {
  owner: '',
  entryId: '',
  customer: '',
  phoneNumber: '',
  date: new Date(),
  eventType: EEventType.MEETING,
  meetingHour: getNearestMeetingHour(),
  meetingLengthInMinutes: meetingLengthDropdownOptions[0].value,
};
