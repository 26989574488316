export const translationStrings = [
  'common.lead.status.new',
  'common.lead.status.invalid',
  'common.lead.status.callback',
  'common.lead.status.duplicate',
  'common.lead.status.interested',
  'common.lead.status.resignation',

  'common.buttons.edit',
  'common.buttons.back',
  'common.buttons.save',
  'common.table.no.data',
  'common.buttons.cancel',
  'common.prompt.unsaved.title',
  'common.prompt.unsaved.leave',
  'common.prompt.unsaved.cancel',
  'common.prompt.something.went.wrong',
  'notifications.success.lead.assigned',

  'dashboard.leads.list.prompt.title',
  'dashboard.leads.list.dropdown.header',
  'dashboard.leads.list.enable.selection',
  'dashboard.leads.list.name.column.label',
  'dashboard.leads.list.date.column.label',
  'dashboard.leads.list.prompt.description',
  'dashboard.leads.list.status.column.label',
  'dashboard.leads.list.seller.column.label',
] as const;
