import {
  Button,
  Chip,
  Dropdown,
  FormControl,
  InputLabel,
  LabelWithAvatar,
  Typography,
  toast,
  useConfirmationModal,
  useDeviceDetect,
} from '@amperio/amperio-ui';
import { useAuth0 } from '@auth0/auth0-react';
import { lightFormat } from 'date-fns';
import { useEffect, useState } from 'react';

import {
  getAvatarApiUrl,
  getLeadStatusToTranslationsMapping,
  useAxiosError,
  useMutation,
  useTranslation,
  useUsersActionQuery,
} from '@utils';

import { leadStatusToChipColorMapping } from '@infrastructure';

import { IApiLead } from '@interfaces';

import { assignUserToLeadsAction, getLeadsTableDataAction } from '../../api';
import { LeadsTable } from '../../molecules';
import { translationStrings } from './leads-list-manager.defaults';
import { ILeadsListManagerProps } from './leads-list-manager.types';

import './leads-list-manager.styles.scss';

export const LeadsListManager = ({ onTableRowClick }: ILeadsListManagerProps): JSX.Element => {
  const { user } = useAuth0();
  const { deviceData } = useDeviceDetect();
  const { confirm } = useConfirmationModal();
  const [isEditMode, setIsEditMode] = useState(false);
  const translations = useTranslation(translationStrings);
  const { receiveErrorMessageFromError } = useAxiosError();
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const {
    findUserById,
    isUsersDataLoading,
    ownersDropdownOptions,
    ownersDropdownOptionsWithoutAvatars,
  } = useUsersActionQuery();

  const {
    isLoading: isAssignUserToLeadsLoading,
    mutateAsync: mutateAsyncAssignUserToLeads,
    reset: resetAssignUserToLeads,
    status: statusAssignUserToLeads,
  } = useMutation(assignUserToLeadsAction);

  const { isLoading: isGetLeadsTableDataLoading, mutateAsync: mutateAsyncGetLeadsTableData } =
    useMutation(getLeadsTableDataAction);

  const leadStatusToTranslationsMapping = getLeadStatusToTranslationsMapping(translations);

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  useEffect(() => {
    const userSub = user?.sub || '';

    if (ownersDropdownOptions.length > 0 && findUserById(userSub)) {
      setSelectedUserId(userSub);
    }
  }, [ownersDropdownOptions.length]);

  useEffect(() => {
    if (statusAssignUserToLeads === 'success') {
      resetAssignUserToLeads();
    }
  }, [statusAssignUserToLeads === 'success']);

  const handleCancel = () => {
    if (selectedRowKeys.length === 0) {
      toggleEditMode();

      return;
    }

    confirm({
      confirmationButtonProps: {
        autoFocus: true,
        'data-testid': 'leads-list-manager--button-confirm-cancel',
      },
      title: translations.commonPromptUnsavedTitle,
      description: translations.commonPromptUnsavedCancel,
    }).then(() => {
      toggleEditMode();
      setSelectedRowKeys([]);
    });
  };

  const handleSave = async () => {
    mutateAsyncAssignUserToLeads({
      userId: selectedUserId,
      leadsIds: selectedRowKeys,
    })
      .then(() => {
        toggleEditMode();
        setSelectedRowKeys([]);

        toast.success(translations.notificationsSuccessLeadAssigned);
      })
      .catch((error) => toast.error(receiveErrorMessageFromError(error)));
  };

  const handleRowClick = (lead: IApiLead) => {
    if (!isEditMode) {
      return onTableRowClick(lead);
    }

    const isAlreadySelected = selectedRowKeys.includes(lead.id);

    if (isAlreadySelected) {
      return setSelectedRowKeys(selectedRowKeys.filter((_key) => _key !== lead.id));
    }

    setSelectedRowKeys([...selectedRowKeys, lead.id]);
  };

  return (
    <div className="leads-list-manager-wrapper">
      <header className="leads-list-manager-wrapper__header">
        {!isEditMode ? (
          <Button
            data-testid="leads-list-manager--button-edit"
            isLoading={isUsersDataLoading || isGetLeadsTableDataLoading}
            size={deviceData.isMobile ? 'small' : 'medium'}
            variant="contained"
            onClick={() => setIsEditMode(!isEditMode)}
          >
            {translations.commonButtonsEdit}
          </Button>
        ) : (
          <span>
            <Button
              data-testid="leads-list-manager--button-cancel"
              disabled={isAssignUserToLeadsLoading}
              size={deviceData.isMobile ? 'small' : 'medium'}
              variant="contained"
              onClick={handleCancel}
            >
              {translations.commonButtonsCancel}
            </Button>
            <Button
              data-testid="leads-list-manager--button-save"
              disabled={selectedUserId === '' || selectedRowKeys.length === 0}
              isLoading={isAssignUserToLeadsLoading}
              size={deviceData.isMobile ? 'small' : 'medium'}
              variant="contained"
              onClick={handleSave}
            >
              {translations.commonButtonsSave}
            </Button>
          </span>
        )}
        <FormControl className="dropdown" disabled={!isEditMode} variant="standard">
          <InputLabel id="standard-label">
            {translations.dashboardLeadsListDropdownHeader}
          </InputLabel>
          <Dropdown
            data-testid="leads-list--dropdown"
            labelId="standard-label"
            options={ownersDropdownOptions}
            value={selectedUserId}
            onChange={(event) => setSelectedUserId(event.target.value as string)}
          />
        </FormControl>
      </header>
      <LeadsTable
        checkboxSelection={isEditMode}
        columns={[
          {
            minWidth: 250,
            field: 'name',
            sortable: true,
            filterOptions: {
              operator: 'like',
              type: 'search-input',
            },
            headerName: translations.dashboardLeadsListNameColumnLabel,
            renderBodyCell: (value: string) => (
              <Typography
                ellipsis
                strong
                as="span"
                data-testid="leads-list-manager--name"
                maxWidth={250}
              >
                {value || '-'}
              </Typography>
            ),
          },
          {
            minWidth: 150,
            sortable: true,
            field: 'status',
            filterOptions: {
              operator: 'eq',
              type: 'radio-group',
              radioGroupOptions: [
                leadStatusToTranslationsMapping.new,
                leadStatusToTranslationsMapping.invalid,
                leadStatusToTranslationsMapping.callback,
                leadStatusToTranslationsMapping.duplicate,
                leadStatusToTranslationsMapping.interested,
                leadStatusToTranslationsMapping.resignation,
              ],
            },
            headerName: translations.dashboardLeadsListStatusColumnLabel,
            renderBodyCell: (value: string) => {
              if (!leadStatusToTranslationsMapping[value]) {
                return <span data-testid="leads-list-manager--chip">-</span>;
              }

              return (
                <Chip
                  color={leadStatusToChipColorMapping[value]}
                  data-testid="leads-list-manager--chip"
                  label={leadStatusToTranslationsMapping[value]}
                  size="small"
                />
              );
            },
          },
          {
            minWidth: 150,
            field: 'modifiedOn',
            headerName: translations.dashboardLeadsListDateColumnLabel,
            renderBodyCell: (value) => {
              if (!value) {
                return <span>-</span>;
              }

              return (
                <Typography as="span" data-testid="leads-list-manager--date">
                  {lightFormat(new Date(value), 'dd/MM/yyy')}
                </Typography>
              );
            },
          },
          {
            minWidth: 400,
            sortable: true,
            field: 'assignedTo',
            filterOptions: {
              operator: 'eq',
              type: 'single-autocomplete',
              dropdownOptions: ownersDropdownOptionsWithoutAvatars,
            },
            headerName: translations.dashboardLeadsListSellerColumnLabel,
            renderBodyCell: (value: string) => {
              const foundOwner = findUserById(value);

              if (!foundOwner) {
                return <span data-testid="leads-list-manager--owner">-</span>;
              }

              return (
                <LabelWithAvatar
                  strong
                  as="span"
                  data-testid="leads-list-manager--owner"
                  src={getAvatarApiUrl(foundOwner.name)}
                >
                  {foundOwner.name}
                </LabelWithAvatar>
              );
            },
          },
        ]}
        getDataFromServer={async ({ filters, page, rowsPerPage, sorts }) => {
          const { data } = await mutateAsyncGetLeadsTableData({
            sorts,
            filters,
            pageNumber: page,
            pageSize: rowsPerPage,
          });

          return { records: data.data, recordsCount: data.totalCount };
        }}
        headerHeight={50}
        isLoading={isGetLeadsTableDataLoading}
        noDataMessage={translations.commonTableNoData}
        rowHeight={50}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        shouldRefreshTableData={statusAssignUserToLeads === 'success'}
        onRowClick={handleRowClick}
      />
    </div>
  );
};
