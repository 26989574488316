export default {
  // header
  'dashboard.lead.header.form': 'How was your call?',
  'dashboard.lead.header.add.account.button': 'Create an account',

  // form
  'dashboard.lead.section.form.call.label': 'Call',
  'dashboard.lead.section.form.show.summary.label': 'Show summary',

  // list
  'dashboard.leads.list.name.column.label': 'Full name',
  'dashboard.leads.list.status.column.label': 'Status',
  'dashboard.leads.list.date.column.label': 'Date',
  'dashboard.leads.list.seller.column.label': 'Sales advisor',
  'dashboard.leads.list.dropdown.header': 'Assign to',
  'dashboard.leads.list.prompt.title': 'Do you wanna make that action?',
  'dashboard.leads.list.prompt.description': 'Assign user to selected leads',
  'dashboard.leads.list.enable.selection': 'Enable selection',
};
