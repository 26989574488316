import { add, endOfMonth, startOfMonth, sub } from 'date-fns';

import { buildApiUrl, prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

export const GET_CALENDAR_EVENTS_ACTION_KEY = 'get-calendar-events-action-key';

export const getCalendarEventsAction =
  (getAccessToken: TGetAccessToken, loggedUserId: string | undefined) => async () => {
    const accessToken = await getAccessToken();

    const currentDate = new Date();
    const end = add(endOfMonth(currentDate), { weeks: 1 }).toISOString();
    const start = sub(startOfMonth(currentDate), { weeks: 1 }).toISOString();

    return axiosInstance.get(
      buildApiUrl(EApiEndpoint.CALENDAR, { end, start, userId: loggedUserId }),
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
