import {
  ArrowLeftIcon,
  Button,
  Fab,
  Icon,
  PlusIcon,
  Typography,
  toast,
  useConfirmationModal,
  useDeviceDetect,
  useDidMountEffect,
} from '@amperio/amperio-ui';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';

import {
  addPlusToPhoneNumber,
  isSomeEnum,
  removePlusFromPhoneNumber,
  useAxiosError,
  useMutation,
  usePrompt,
  useTranslation,
} from '@utils';

import { ELeadStatus, ERoutes } from '@enums';

import { updateLeadAction } from '../../api';
import { ILeadsFormFields, LeadInfo, LeadsForm, leadsFormDefaultFormFields } from '../../molecules';
import { translationStrings } from './upsert-lead.defaults';
import { IUpsertLeadProps } from './upsert-lead.types';

import './upsert-lead.styles.scss';

export const UpsertLead = ({
  onAddAccountClick,
  onBackClick,
  setSelectedLead,
}: IUpsertLeadProps): JSX.Element => {
  const [queryParams] = useQueryParams({
    id: StringParam,
    name: StringParam,
    status: StringParam,
    phoneNumber: StringParam,
  });

  const { deviceData } = useDeviceDetect();
  const { confirm } = useConfirmationModal();
  const [hadPhoneCall, setHadPhoneCall] = useState(false);
  const translations = useTranslation(translationStrings);
  const { receiveErrorMessageFromError } = useAxiosError();
  const [isEditMode, setIsEditMode] = useState(deviceData.isMobile || !queryParams.id);

  const { isLoading: isUpdateLeadActionLoading, mutateAsync: mutateAsyncUpdateLeadAction } =
    useMutation(updateLeadAction);

  useDidMountEffect(() => {
    setIsEditMode(deviceData.isMobile);
  }, [JSON.stringify(deviceData)]);

  const getDefaultLeadStatus = () => {
    if (!isSomeEnum(ELeadStatus)(queryParams.status)) {
      return undefined;
    }

    return queryParams.status !== ELeadStatus.NEW ? queryParams.status : '';
  };

  const { control, formState, handleSubmit, reset, setError } = useForm<ILeadsFormFields>({
    mode: 'onChange',
    defaultValues: {
      name: queryParams.name || '',
      leadStatus: getDefaultLeadStatus(),
      phoneNumber: removePlusFromPhoneNumber(queryParams.phoneNumber),
    },
  });

  usePrompt(
    translations.commonPromptUnsavedTitle,
    translations.commonPromptUnsavedLeave,
    formState.isDirty,
  );

  const onSubmit = async (data: ILeadsFormFields) => {
    if (Object.keys(formState.errors).length > 0) {
      return;
    }

    const phoneNumberWithPlus = addPlusToPhoneNumber(data.phoneNumber);

    if (!isValidPhoneNumber(phoneNumberWithPlus)) {
      return;
    }

    if (!queryParams.id) {
      return;
    }

    mutateAsyncUpdateLeadAction({ leadId: queryParams.id, ...data })
      .then((result) => {
        if (!deviceData.isMobile) {
          setIsEditMode(false);
        }

        reset(data);
        setTimeout(() => setSelectedLead(result.data), 0);

        toast.success(translations.notificationsSuccessLeadChangesSaved);
      })
      .catch((error) => toast.error(receiveErrorMessageFromError(error)));
  };

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const handleCancel = () => {
    if (!formState.isDirty) {
      toggleEditMode();
      return;
    }

    confirm({
      title: translations.commonPromptUnsavedTitle,
      confirmationButtonProps: { autoFocus: true },
      description: translations.commonPromptUnsavedCancel,
    }).then(() => {
      reset(leadsFormDefaultFormFields);
      toggleEditMode();
    });
  };

  const handleAddAccountClick = () =>
    onAddAccountClick(queryParams.id!, queryParams.phoneNumber!, queryParams.name!);

  if (
    queryParams.id == null ||
    queryParams.name == null ||
    queryParams.phoneNumber == null ||
    !isSomeEnum(ELeadStatus)(queryParams.status)
  ) {
    return <Navigate to={ERoutes.NOT_FOUND} />;
  }

  return (
    <form className="upsert-lead-wrapper" onSubmit={handleSubmit(onSubmit)}>
      <header className="upsert-lead-wrapper__header">
        {!deviceData.isMobile ? (
          <>
            <Button startIcon={<ArrowLeftIcon />} variant="outlined" onClick={onBackClick}>
              {translations.commonButtonsBack}
            </Button>
            {hadPhoneCall ? (
              <span>
                {!isEditMode ? (
                  <Button
                    data-testid="upsert-lead--button-edit"
                    variant="contained"
                    onClick={toggleEditMode}
                  >
                    {translations.commonButtonsEdit}
                  </Button>
                ) : (
                  <>
                    <Button
                      data-testid="upsert-lead--button-cancel"
                      disabled={!isEditMode || isUpdateLeadActionLoading}
                      variant="contained"
                      onClick={handleCancel}
                    >
                      {translations.commonButtonsCancel}
                    </Button>
                    <Button
                      data-testid="upsert-lead--button-save"
                      disabled={!isEditMode || !formState.isDirty || !queryParams.id}
                      isLoading={isUpdateLeadActionLoading}
                      type="submit"
                      variant="contained"
                    >
                      {translations.commonButtonsSave}
                    </Button>
                  </>
                )}
                <Button
                  disabled={isEditMode || !queryParams.id || !queryParams.name}
                  startIcon={<PlusIcon />}
                  variant="contained"
                  onClick={handleAddAccountClick}
                >
                  {translations.dashboardLeadHeaderAddAccountButton}
                </Button>
              </span>
            ) : null}
          </>
        ) : null}
        {deviceData.isMobile && formState.isDirty ? (
          <Fab data-testid="upsert-lead--button-save" type="submit" variant="extended">
            <Icon stroke="white">
              <PlusIcon />
            </Icon>
            <Typography strong as="span" color="white">
              {translations.commonButtonsSave}
            </Typography>
          </Fab>
        ) : null}
      </header>
      <section className="upsert-lead-wrapper__content">
        {queryParams.phoneNumber && (
          <div className="upsert-lead-wrapper__content-box upsert-lead-wrapper__content-top">
            <LeadInfo
              hadPhoneCall={hadPhoneCall}
              name={queryParams.name!}
              phoneNumber={queryParams.phoneNumber}
              setHadPhoneCall={setHadPhoneCall}
            />
          </div>
        )}
        {hadPhoneCall && (
          <div className="upsert-lead-wrapper__content-box">
            <LeadsForm control={control} isEditMode={isEditMode} setError={setError} />
          </div>
        )}
        {hadPhoneCall && deviceData.isMobile && !formState.isDirty && (
          <Fab
            data-testid="upsert-lead--fab"
            disabled={isUpdateLeadActionLoading}
            variant="extended"
            onClick={handleAddAccountClick}
          >
            <Icon stroke="white">
              <PlusIcon />
            </Icon>
            <Typography strong as="span" color="white">
              {translations.dashboardLeadHeaderAddAccountButton}
            </Typography>
          </Fab>
        )}
      </section>
    </form>
  );
};
