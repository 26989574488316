export const translationStrings = [
  'dashboard.events.list.header.select.seller',
  'dashboard.events.list.header.calendar.switch',
  'dashboard.events.list.header.add.event.button',
  'dashboard.events.list.header.set.absence.button',
  'dashboard.events.list.header.unset.absence.button',

  'common.month.january',
  'common.month.february',
  'common.month.march',
  'common.month.april',
  'common.month.may',
  'common.month.june',
  'common.month.july',
  'common.month.august',
  'common.month.september',
  'common.month.october',
  'common.month.november',
  'common.month.december',
  'common.prompt.something.went.wrong',
  'calendar.reservation.title',
  'notifications.success.absence.set',
  'notifications.success.absence.delete',
] as const;
