import { Button, Icon, LogoutIcon, OfflineIcon, Spinner, Typography } from '@amperio/amperio-ui';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { Offline } from 'react-detect-offline';
import { useIsFetching, useIsMutating } from 'react-query';
import { useLocation } from 'react-router-dom';

import { getTranslationKeyAndPathForHeaderFromUrl, useGhostMode, useTranslation } from '@utils';

import './desktop-header.styles.scss';

export const DesktopHeader = (): JSX.Element => {
  const { pathname } = useLocation();
  const isMutating = useIsMutating();
  const isFetching = useIsFetching();
  const { logout, user } = useAuth0();
  const { isGhostMode } = useGhostMode();

  const translation = useMemo(() => getTranslationKeyAndPathForHeaderFromUrl(pathname), [pathname]);
  const translations = useTranslation([
    'common.buttons.logout',
    `dashboard.header.${translation.path}`,
  ]);

  const handleLogout = () => logout();

  return (
    <div className="desktop-header-wrapper">
      <Typography strong as="h4">
        {translations[translation.key]}
      </Typography>
      <div className="desktop-header-wrapper__right-container">
        <Offline polling={false}>
          <Icon fill="none" stroke="black">
            <OfflineIcon />
          </Icon>
        </Offline>
        {isFetching || isMutating ? <Spinner size="small" /> : null}
        {isGhostMode() && user?.family_name && (
          <Typography strong as="span">
            {user?.family_name}
          </Typography>
        )}
        <Button endIcon={<LogoutIcon />} size="small" variant="outlined" onClick={handleLogout}>
          {translations.commonButtonsLogout}
        </Button>
      </div>
    </div>
  );
};
