export default {
  'dashboard.homepage.welcome.message': 'Hello',

  'dashboard.homepage.seller.calendar': 'Calendar',
  'dashboard.homepage.seller.unhandled.events': 'Unhandled events',
  'dashboard.homepage.seller.meetings.no.description': 'You have no meetings today :)',
  'dashboard.homepage.seller.meetings.description': 'You have [number] events today :)',

  'dashboard.homepage.manager.leads.leads': 'Leads',
  'dashboard.homepage.manager.leads.summary.leads':
    'number of leads accquired thanks to the Amperio',
  'dashboard.homepage.manager.leads.summary.meetings':
    'number of meetings accquired thanks to the Amperio',
  'dashboard.homepage.manager.leads.go.to': 'Go to the leads',
  'dashboard.homepage.manager.leads.summary.label': 'Daily summary',
  'dashboard.homepage.manager.leads.no.description': 'You have no leads today :)',
  'dashboard.homepage.manager.leads.description': 'You have [number] new leads today :)',

  'dashboard.homepage.manager.alerts': 'Alerts',
  'dashboard.homepage.manager.alerts.absent': 'Absent today',
  'dashboard.homepage.manager.alerts.missed.meetings': 'Missed meetings',

  // list
  'dashboard.homepage.unhandled.meetings.name.column.label': 'Full name',
  'dashboard.homepage.unhandled.meetings.location.column.label': 'Location',
  'dashboard.homepage.unhandled.meetings.date.column.label': 'Date',
};
