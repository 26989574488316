import { Button } from '@amperio/amperio-ui';

import { Error } from '@domain';

import { useTranslation } from '@utils';

import { ERoutes } from '@enums';

import { translationStrings } from './internal-server-error.defaults';

const redirectToHomepage = () => window.location.assign(`/${ERoutes.DASHBOARD}`);

export const InternalServerErrorPage = (): JSX.Element => {
  const translations = useTranslation(translationStrings);

  return (
    <Error code="500" message={translations.errorPageInternalServerErrorMessage}>
      <Button variant="contained" onClick={redirectToHomepage}>
        {translations.errorPageInternalServerErrorBackToHomepageButton}
      </Button>
    </Error>
  );
};
