import { asyncTryCatchWrapper, isString } from '@amperio/amperio-ui';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { objectToQueryString } from '@utils';

import { ERoutes } from '@enums';

export const useAccessToken = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated } = useAuth0();

  const getAccessToken = useCallback(async () => {
    if (!isAuthenticated) {
      const queryParams = {
        showLogoutButton: true,
      };

      navigate(`${ERoutes.FORBIDDEN}${objectToQueryString(queryParams)}`);

      return;
    }

    const silentlyFetchedAccessToken = await asyncTryCatchWrapper(getAccessTokenSilently);

    if (isString(silentlyFetchedAccessToken)) {
      return silentlyFetchedAccessToken;
    }

    if (silentlyFetchedAccessToken) {
      return silentlyFetchedAccessToken.access_token;
    }

    const accessTokenFromPopup = await asyncTryCatchWrapper(getAccessTokenWithPopup);

    if (accessTokenFromPopup) {
      return accessTokenFromPopup;
    }

    throw new Error('[getAccessToken] The authorization token could not be retrieved');
  }, []);

  return { getAccessToken };
};
