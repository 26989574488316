import { Button, Typography } from '@amperio/amperio-ui';
import { useAuth0 } from '@auth0/auth0-react';

import { useTranslation } from '@utils';

import { translationStrings } from './block-modal-content.defaults';
import { IBlockModalContentProps } from './block-modal-content.types';

import './block-modal-content.styles.scss';

export const BlockModalContent = ({ kamName, kamPhone }: IBlockModalContentProps): JSX.Element => {
  const { logout } = useAuth0();

  const translations = useTranslation(translationStrings);

  const hasKamDetails = kamName || kamPhone;

  const onCallClick = () => window.open(`tel:${kamPhone}`);

  return (
    <div className="block-modal-content-wrapper">
      <Typography strong as="h5">
        {translations.commonPromptBlockedAccountMessage}
        {hasKamDetails ? ':' : '.'}
      </Typography>
      {hasKamDetails && (
        <span>
          {kamName && <Typography>{kamName}</Typography>}
          {kamPhone && <Typography>{kamPhone}</Typography>}
        </span>
      )}
      <div className="block-modal-content-wrapper__buttons">
        <Button fullWidth variant="contained" onClick={() => logout()}>
          {translations.commonButtonsLogout}
        </Button>
        {kamPhone && (
          <Button fullWidth variant="contained" onClick={onCallClick}>
            {translations.commonButtonsCall}
          </Button>
        )}
      </div>
    </div>
  );
};
