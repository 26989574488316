import { AxiosError } from 'axios';

import {
  isAxiosError,
  isSomeEnum,
  mapErrorCodeToTranslationKey,
  useLocale,
  useTranslation,
} from '@utils';

import { ECustomHttpCode } from '@enums';

import { IApiCustomHttpError } from '@interfaces';

import { translationStrings } from './use-axios-error.defaults';

export const useAxiosError = () => {
  const { formatMessage } = useLocale();
  const translations = useTranslation(translationStrings);

  function receiveErrorMessageFromError(error: Error | AxiosError<IApiCustomHttpError>) {
    if (isAxiosError(error) && error.response) {
      const isValidErrorCode = isSomeEnum(ECustomHttpCode)(error.response.data?.errorCode);
      const errorCodeTranslation = isValidErrorCode
        ? formatMessage({
            id: `error.page.message.${mapErrorCodeToTranslationKey(
              error.response.data?.errorCode,
            )}`,
          })
        : '';

      return errorCodeTranslation || translations.commonPromptSomethingWentWrong;
    }

    return translations.commonPromptSomethingWentWrong;
  }

  return {
    receiveErrorMessageFromError,
  };
};
