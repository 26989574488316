import {
  DropdownWithController,
  HOUSE_NUMBER_REGEX,
  Paper,
  TextInputWithController,
  Typography,
  ZIPCODE_SPAIN_REGEX,
  useDeviceDetect,
} from '@amperio/amperio-ui';
import { useEffect } from 'react';

import { useTranslation } from '@utils';
import { containEntersOrSpaces, startsOrEndsWithWhitespace } from '@utils/react-hook-form';

import { translationStrings } from './address-form.defaults';
import { IAddressFormProps } from './address-form.types';
import { useRegionsQuery } from './utils';

import './address-form.styles.scss';

export const AddressForm = ({
  control,
  isEditMode,
  setValue,
  watch,
}: IAddressFormProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  const selectedRegion = watch('address.region');
  const selectedDistrict = watch('address.district');

  const { districtsDropdownOptions, isRegionsDataLoading, regionsDropdownOptions } =
    useRegionsQuery(selectedRegion);

  useEffect(() => {
    const isNewRegion = districtsDropdownOptions.filter((x) => x.value === selectedDistrict);

    if (isEditMode && districtsDropdownOptions.length > 0 && isNewRegion.length === 0) {
      setValue('address.district', districtsDropdownOptions[0].value, { shouldValidate: true });
    }
  }, [districtsDropdownOptions, selectedRegion]);

  return (
    <Paper className="address-form-wrapper" padding={deviceData.isMobile ? 'sm' : 'md'}>
      <Typography strong as={deviceData.isMobile ? 'h3' : 'h2'}>
        {translations.formAddressHeader}
      </Typography>
      <div className="address-form-wrapper__form">
        <DropdownWithController
          controllerProps={{
            control,
            name: 'address.region',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
          dropdownProps={{
            options: regionsDropdownOptions,
            labelId: 'regions-dropdown-label',
            label: translations.formAddressRegionLabel,
          }}
          formControlProps={{
            disabled: isRegionsDataLoading || !isEditMode,
          }}
        />
        <DropdownWithController
          controllerProps={{
            control,
            name: 'address.district',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
          dropdownProps={{
            options: districtsDropdownOptions,
            labelId: 'districts-dropdown-label',
            label: translations.formAddressDistrictLabel,
          }}
          formControlProps={{
            disabled: isRegionsDataLoading || !isEditMode || !selectedRegion,
          }}
        />
        <div className="form__grid">
          <TextInputWithController
            controllerProps={{
              control,
              name: 'address.city',
              rules: {
                required: {
                  value: true,
                  message: translations.commonFormFieldErrorRequired,
                },
                maxLength: {
                  value: 100,
                  message: translations.formAddressCityMaxLengthError,
                },
                validate: {
                  startsOrEndsWithWhitespace: startsOrEndsWithWhitespace(
                    translations.commonFormFieldErrorStartsOrEndsWithWhitespace,
                  ),
                  containEntersOrSpaces: containEntersOrSpaces(
                    translations.commonFormFieldErrorContainEntersOrSpaces,
                  ),
                },
              },
            }}
            inputProps={{
              autoComplete: 'address.city',
              label: translations.formAddressCityLabel,
              disabled: !isEditMode || !selectedDistrict,
            }}
          />
          <TextInputWithController
            controllerProps={{
              control,
              name: 'address.street',
              rules: {
                required: {
                  value: true,
                  message: translations.commonFormFieldErrorRequired,
                },
                maxLength: {
                  value: 100,
                  message: translations.formAddressStreetMaxLengthError,
                },
                validate: {
                  startsOrEndsWithWhitespace: startsOrEndsWithWhitespace(
                    translations.commonFormFieldErrorStartsOrEndsWithWhitespace,
                  ),
                  containEntersOrSpaces: containEntersOrSpaces(
                    translations.commonFormFieldErrorContainEntersOrSpaces,
                  ),
                },
              },
            }}
            inputProps={{
              autoComplete: 'address.street',
              label: translations.formAddressStreetLabel,
              disabled: !isEditMode || !selectedDistrict,
            }}
          />
          <TextInputWithController
            controllerProps={{
              control,
              name: 'address.zipCode',
              rules: {
                required: {
                  value: true,
                  message: translations.commonFormFieldErrorRequired,
                },
                pattern: {
                  value: ZIPCODE_SPAIN_REGEX,
                  message: translations.formAddressZipCodePatternMatchError,
                },
              },
            }}
            inputProps={{
              autoComplete: 'address.zipCode',
              inputProps: { inputMode: 'numeric' },
              disabled: !isEditMode || !selectedDistrict,
              label: translations.formAddressZipCodeLabel,
            }}
          />
          <TextInputWithController
            controllerProps={{
              control,
              name: 'address.houseNumber',
              rules: {
                required: {
                  value: true,
                  message: translations.commonFormFieldErrorRequired,
                },
                pattern: {
                  value: HOUSE_NUMBER_REGEX,
                  message: translations.formAddressHouseNumberPatternMatchError,
                },
              },
            }}
            inputProps={{
              autoComplete: 'address.houseNumber',
              disabled: !isEditMode || !selectedDistrict,
              label: translations.formAddressHouseNumberLabel,
            }}
          />
        </div>
      </div>
    </Paper>
  );
};
