export const translationStrings = [
  'dashboard.accounts.list.name.column.label',
  'dashboard.accounts.list.location.column.label',
  'dashboard.accounts.list.products.column.label',
  'dashboard.accounts.list.date.column.label',
  'dashboard.accounts.list.status.column.label',
  'common.table.no.data',
  'common.event.status.sale',
  'common.event.status.missed',
  'common.event.status.planned',
  'common.event.status.rejected',
  'common.event.status.resignation',
  'common.event.status.bid.submitted',
  'common.event.status.sales.opportunity',
] as const;
