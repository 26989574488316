import { tryCatchWrapper } from '@amperio/amperio-ui';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { UpsertAccount, UpsertEvent } from '@ui';

import { objectToQueryString, useUserClaims } from '@utils';

import { EVENTS_MANAGER_SELECTED_USER_ID_KEY } from '@infrastructure';

import { EDashboardEventsRoutes, ERoutes } from '@enums';

import { EventsViewManager, EventsViewSeller } from './organisms';

export const Events = (): JSX.Element => {
  const navigate = useNavigate();
  const { isSalesManager, isSalesRepresentative } = useUserClaims();

  useEffect(
    () => () => {
      tryCatchWrapper(() => window.localStorage.removeItem(EVENTS_MANAGER_SELECTED_USER_ID_KEY));
    },
    [],
  );

  const onBackButtonClick = () => navigate(-1);

  // events view - actions
  const onCalendarEventClick = (eventId: string) =>
    navigate(`${EDashboardEventsRoutes.UPSERT_EVENT}?eventId=${eventId}`);

  const onAddNewEventButtonClick = (selectedEventDate: Date, userId?: string) =>
    navigate(
      `${EDashboardEventsRoutes.UPSERT_EVENT}${objectToQueryString({ userId, selectedEventDate })}`,
    );

  const onEventsTableRowClick = (eventId: string) =>
    navigate(`${EDashboardEventsRoutes.UPSERT_EVENT}?eventId=${eventId}`);

  // upsert event - actions
  const onUpsertEventProfileIconClick = (accountId: string) =>
    navigate(`${EDashboardEventsRoutes.UPSERT_ACCOUNT}${objectToQueryString({ accountId })}`);

  return (
    <Routes>
      <Route
        element={
          <>
            {isSalesRepresentative() && (
              <EventsViewSeller
                onAddNewEventButtonClick={onAddNewEventButtonClick}
                onCalendarEventClick={onCalendarEventClick}
              />
            )}
            {isSalesManager() && (
              <EventsViewManager
                onAddNewEventButtonClick={onAddNewEventButtonClick}
                onCalendarEventClick={onCalendarEventClick}
              />
            )}
          </>
        }
        path={EDashboardEventsRoutes.LIST}
      />
      <Route
        element={
          <UpsertEvent
            onBackClick={onBackButtonClick}
            onProfileIconClick={onUpsertEventProfileIconClick}
          />
        }
        path={EDashboardEventsRoutes.UPSERT_EVENT}
      />
      <Route
        element={
          <UpsertAccount
            onBackClick={onBackButtonClick}
            onEventsTableRowClick={onEventsTableRowClick}
          />
        }
        path={EDashboardEventsRoutes.UPSERT_ACCOUNT}
      />
      <Route element={<Navigate replace to={ERoutes.NOT_FOUND} />} path={ERoutes.ANY} />
    </Routes>
  );
};
