export default {
  // FORBIDDEN
  'error.page.forbidden.message': 'Forbidden',
  'error.page.forbidden.back.to.homepage.button': 'Back to homepage',

  // NOT_FOUND
  'error.page.not.found.message': 'Page not found',
  'error.page.not.found.back.to.homepage.button': 'Back to homepage',

  // INTERNAL_SERVER_ERROR
  'error.page.internal.server.error.back.to.homepage.button': 'Back to homepage',
  'error.page.internal.server.error.message':
    'Internal server error - please try again in a moment',

  // messages
  'error.page.message.access.error': "You don't have access to '[apiUrl]'",
  'error.page.message.no.roles': 'The user has no role assigned',
  'error.page.message.lead.access.error': 'No access to choosen leads',
  'error.page.message.account.no.required.data':
    'Some mandatory fields are missing. Unable to save changes',
  'error.page.message.account.form.invalid.format': 'Invalid Format. Unable to save changes',
  'error.page.message.account.already.exist':
    'The account linked to this telephone number already exists',
  'error.page.message.account.access.error': 'Lack of access to selected accounts',
  'error.page.message.event.no.required.data':
    'Some mandatory fields are missing. Unable to save changes',
  'error.page.message.event.form.invalid.format': 'Wrong field format. Unable to save changes',
  'error.page.message.event.access.error': 'No access to selected events',
};
