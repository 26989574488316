import { withAuthenticationRequired } from '@amperio/amperio-ui';
import { Navigate } from 'react-router-dom';

import { FullScreenLoading } from '@domain';

import { objectToQueryString } from '@utils';

import { ERoutes } from '@enums';

import { IGetConditionalRouteAttributes } from './get-conditional-route.types';

export const getConditionalRoute = ({
  authorize,
  Component,
  element,
  ...routerProps
}: IGetConditionalRouteAttributes) => {
  if (!Component) {
    return <Navigate to={ERoutes.NOT_FOUND} />;
  }

  const onError = (error: Error | undefined) => {
    const queryParams = {
      showLogoutButton: true,
      message: error?.message,
    };

    return <Navigate to={`${ERoutes.FORBIDDEN}${objectToQueryString(queryParams)}`} />;
  };

  if (authorize) {
    return withAuthenticationRequired(Component, {
      onError,
      onRedirecting: () => <FullScreenLoading />,
      targetRoute: routerProps.targetRouteToMatch,
    })(routerProps);
  }

  return <Component {...routerProps} />;
};
