import { AxiosResponse } from 'axios';

import { prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IApiUpsertResponse } from '../../upsert-event.types';

export const updateCalendarEventAction =
  (getAccessToken: TGetAccessToken) =>
  async ({
    data,
    eventId,
  }: {
    data: IApiUpsertResponse;
    eventId: string;
  }): Promise<AxiosResponse<IApiUpsertResponse>> => {
    const accessToken = await getAccessToken();

    return axiosInstance.put(
      `${EApiEndpoint.CALENDAR}/${eventId}`,
      data,
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
