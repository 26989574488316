import {
  Chip,
  DataGrid,
  HeatPlatesIcon,
  HeatPumpsIcon,
  Icon,
  LeadsIcon,
  MeetingsIcon,
  SolarPanelsIcon,
  Typography,
  isArray,
} from '@amperio/amperio-ui';
import { lightFormat } from 'date-fns';
import { Children } from 'react';

import { getEventStatusToTranslationsMapping, useMutation, useTranslation } from '@utils';

import { eventStatusToChipColorMapping } from '@infrastructure';

import { EEventType, EProductCategory } from '@enums';

import { IApiComment } from '@interfaces';

import { getCalendarEventsByAccountAction } from './api';
import { translationStrings } from './events-table.defaults';
import { IEventsTableProps } from './events-table.types';

import './events-table.styles.scss';

export const EventsTable = ({ accountId, onRowClick }: IEventsTableProps): JSX.Element => {
  const translations = useTranslation(translationStrings);

  const { isLoading, mutateAsync } = useMutation(getCalendarEventsByAccountAction);

  const eventStatusToTranslationsMapping = getEventStatusToTranslationsMapping(translations);

  return (
    <div className="events-table-wrapper" data-testid="events-table">
      <Typography strong as="h3" className="events-table-wrapper__header">
        {translations.dashboardAccountsSectionEventsTableHeader}
      </Typography>
      <div className="events-table-wrapper__table">
        <DataGrid
          columns={[
            {
              minWidth: 150,
              field: 'modifiedOn',
              headerName: translations.dashboardAccountsSectionEventsTableDate,
              renderBodyCell: (value) => {
                if (!value) {
                  return <span>-</span>;
                }

                return (
                  <Typography as="span">{lightFormat(new Date(value), 'dd/MM/yyy')}</Typography>
                );
              },
            },
            {
              minWidth: 150,
              field: 'productCategories',
              headerName: translations.dashboardAccountsSectionEventsTableProducts,
              renderBodyCell: (value: unknown) => {
                if (!isArray(value) || value.length === 0) {
                  return <span>-</span>;
                }

                return (
                  <div className="table__products-cell">
                    {Children.toArray(
                      value.map((_element: string) => (
                        <Icon fill="none" stroke="primary">
                          {_element === EProductCategory.HEATING_FOILS ? <HeatPlatesIcon /> : null}
                          {_element === EProductCategory.HEATING_PUMPS ? <HeatPumpsIcon /> : null}
                          {_element === EProductCategory.PHOTOVOLTAICS ? <SolarPanelsIcon /> : null}
                        </Icon>
                      )),
                    )}
                  </div>
                );
              },
            },
            {
              minWidth: 100,
              field: 'type',
              headerName: translations.dashboardAccountsSectionEventsTableType,
              renderBodyCell: (value) => {
                if (!value) {
                  return <span>-</span>;
                }

                return (
                  <Icon fill="none" stroke="primary">
                    {(value as never) === EEventType.CALL ? <LeadsIcon /> : null}
                    {(value as never) === EEventType.MEETING ? <MeetingsIcon /> : null}
                  </Icon>
                );
              },
            },
            {
              minWidth: 200,
              field: 'status',
              headerName: translations.dashboardAccountsSectionEventsTableStatus,
              renderBodyCell: (value) => {
                const label = eventStatusToTranslationsMapping[value];

                if (!label) {
                  return <span>-</span>;
                }

                return (
                  <Chip color={eventStatusToChipColorMapping[value]} label={label} size="small" />
                );
              },
            },
            {
              minWidth: 400,
              field: 'comment',
              headerName: translations.dashboardAccountsSectionEventsTableNotes,
              renderBodyCell: (value) => (
                <Typography ellipsis as="span" maxWidth={400}>
                  {(value as unknown as IApiComment)?.text ?? '-'}
                </Typography>
              ),
            },
          ]}
          getDataFromServer={async ({ filters, page, rowsPerPage, sorts }) => {
            const { data } = await mutateAsync({
              sorts,
              filters,
              accountId,
              pageNumber: page,
              pageSize: rowsPerPage,
            });

            return { records: data.data, recordsCount: data.totalCount };
          }}
          isLoading={isLoading}
          noDataMessage={translations.commonTableNoData}
          onBodyRow={(record) => ({
            pointer: true,
            // @ts-expect-error
            onClick: () => onRowClick(record.id),
          })}
        />
      </div>
    </div>
  );
};
