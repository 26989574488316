import { IAcceptInvitationFromFields } from './invitation-form.types';

export const translationStrings = [
  'accept.invitation.form.full.name.label',
  'accept.invitation.form.email.label',
  'accept.invitation.form.password.label',
  'accept.invitation.form.password.length',
  'accept.invitation.form.password.special.characters',
  'accept.invitation.form.password.lower.and.upper.letters',
  'accept.invitation.form.password.numbers',
  'accept.invitation.form.submit.button',
] as const;

export const defaultFormFields: IAcceptInvitationFromFields = { name: '', email: '', password: '' };
