import { isSomeEnum } from '@utils';

import { ECustomHttpCode } from '@enums';

export const mapErrorCodeToTranslationKey = (value: unknown) => {
  if (!isSomeEnum(ECustomHttpCode)(value)) {
    return '';
  }

  const valueWithoutNumber = value.replace(/\d/g, '');
  const valueAsLowerCase = valueWithoutNumber.toLowerCase();

  return valueAsLowerCase.replace(/_/g, '.').slice(1);
};
