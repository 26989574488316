import { camelCase, capitalize } from '@amperio/amperio-ui';

import { EDashboardRoutes, ERoutes } from '@enums';

export const getTranslationKeyAndPathForHeaderFromUrl = (pathname: string) => {
  const indexOfDashboard = pathname.indexOf(ERoutes.DASHBOARD);
  const dashboardRouteLength = ERoutes.DASHBOARD.length;

  const subdomainRoute = pathname.slice(indexOfDashboard + dashboardRouteLength + 1);
  const translationPath = subdomainRoute.replaceAll('/', '.');

  return {
    path: translationPath || EDashboardRoutes.HOME,
    key: camelCase(`${ERoutes.DASHBOARD}Header${capitalize(translationPath)}`),
  };
};
