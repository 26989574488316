export default {
  'dashboard.homepage.welcome.message': '¡Hola',

  'dashboard.homepage.seller.calendar': 'Calendario',
  'dashboard.homepage.seller.unhandled.events': 'Eventos no manejados',
  'dashboard.homepage.seller.meetings.no.description': 'No tienes reuniones hoy :)',
  'dashboard.homepage.seller.meetings.description': 'Tienes [number] eventos hoy :)',

  'dashboard.homepage.manager.leads.leads': 'Contactos',
  'dashboard.homepage.manager.leads.summary.leads':
    'número de contactos adquiridos gracias a Amperio',
  'dashboard.homepage.manager.leads.summary.meetings':
    'número de citas adquiridos gracias a Amperio',
  'dashboard.homepage.manager.leads.go.to': 'Ir a los clientes potenciales',
  'dashboard.homepage.manager.leads.summary.label': 'Resumen diario',
  'dashboard.homepage.manager.leads.no.description': 'No tienes contactos hoy :)',
  'dashboard.homepage.manager.leads.description':
    'Tienes [number] nuevos contactos potenciales hoy :)',

  'dashboard.homepage.manager.alerts': 'Alertas',
  'dashboard.homepage.manager.alerts.absent': 'Ausente hoy',
  'dashboard.homepage.manager.alerts.missed.meetings': 'Reuniones perdidas',

  // list
  'dashboard.homepage.unhandled.meetings.name.column.label': 'Nombre completo',
  'dashboard.homepage.unhandled.meetings.location.column.label': 'Ubicación',
  'dashboard.homepage.unhandled.meetings.date.column.label': 'Fecha',
};
