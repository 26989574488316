import { AxiosResponse } from 'axios';

import { prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IApiUser } from '@interfaces';

export const GET_USERS_NAMES_ACTION_KEY = 'get-users-names-action-key';

export const getUsersAction =
  (getAccessToken: TGetAccessToken) => async (): Promise<AxiosResponse<IApiUser[]>> => {
    const accessToken = await getAccessToken();

    return axiosInstance.get(EApiEndpoint.USERS, prepareAxiosConfigWithAccessToken(accessToken));
  };
