import { IAddressFormFields } from '@interfaces';

export const translationStrings = [
  'common.form.field.error.required',
  'common.form.field.error.contain.enters.or.spaces',
  'common.form.field.error.starts.or.ends.with.whitespace',

  'form.address.header',
  'form.address.region.label',
  'form.address.district.label',
  'form.address.city.label',
  'form.address.city.max.length.error',
  'form.address.street.label',
  'form.address.street.max.length.error',
  'form.address.zip.code.label',
  'form.address.zip.code.pattern.match.error',
  'form.address.house.number.label',
  'form.address.house.number.pattern.match.error',
] as const;

export const addressFormDefaultFormFields: IAddressFormFields = {
  city: '',
  region: '',
  street: '',
  zipCode: '',
  district: '',
  houseNumber: '',
};
