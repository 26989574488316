import { prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IApiAccount } from '@interfaces';

export const createAccountAction =
  (getAccessToken: TGetAccessToken) => async (requestData: IApiAccount) => {
    const accessToken = await getAccessToken();

    return axiosInstance.post(
      EApiEndpoint.ACCOUNTS,
      requestData,
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
