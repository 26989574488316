import { AxiosResponse } from 'axios';

import { objectToQueryString, prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosCloudflareAuth0Instance } from '@infrastructure';

import { IIsBlockedResponse } from './is-blocked.types';

const cloudflareApiKey = process.env.REACT_APP_CLOUDFLARE_AUTH0_API_KEY;

export const isBlockedAction =
  (userId: string, organizationId: string) =>
  () =>
  // eslint-disable-next-line unicorn/consistent-function-scoping
  async (): Promise<AxiosResponse<IIsBlockedResponse>> =>
    axiosCloudflareAuth0Instance.get(
      `/is-blocked${objectToQueryString({ userId, organizationId })}`,
      prepareAxiosConfigWithAccessToken(cloudflareApiKey),
    );
