export default {
  // header
  'dashboard.lead.header.form': '¿Cómo estuvo tu llamada?',
  'dashboard.lead.header.add.account.button': 'Crear una cuenta',

  // form
  'dashboard.lead.section.form.call.label': 'Llamar',
  'dashboard.lead.section.form.show.summary.label': 'Mostrar resumen',

  // list
  'dashboard.leads.list.name.column.label': 'Nombre completo',
  'dashboard.leads.list.status.column.label': 'Estatuto',
  'dashboard.leads.list.date.column.label': 'Fecha',
  'dashboard.leads.list.seller.column.label': 'Asesor de ventas',
  'dashboard.leads.list.dropdown.header': 'Asignar a',
  'dashboard.leads.list.prompt.title': '¿Quieres hacer esta acción?',
  'dashboard.leads.list.prompt.description':
    'Asignar usuario a contactos potenciales seleccionados',
  'dashboard.leads.list.enable.selection': 'Habilitar selección',
};
