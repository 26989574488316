import { removePlusFromPhoneNumber } from '@utils';

import { IApiAccount } from '@interfaces';

export const transformAccountDataFromServer = (accountData: { data: IApiAccount }) => {
  const { fullName, phoneNumber } = accountData?.data.contactInfo;

  const parsedPhoneNumber = removePlusFromPhoneNumber(phoneNumber);

  return {
    address: {
      region: accountData?.data.primaryAddress.region || '',
      street: accountData?.data.primaryAddress.street || '',
      zipCode: accountData?.data.primaryAddress.zipCode || '',
      district: accountData?.data.primaryAddress.district || '',
      city: accountData?.data.primaryAddress.postOfficeCity || '',
      houseNumber: accountData?.data.primaryAddress.houseNo || '',
    },
    account: {
      fullName: fullName || '',
      phoneNumber: parsedPhoneNumber,
      email: accountData?.data.contactInfo.email || '',
    },
  };
};
