import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { isAxiosError, isSomeEnum, objectToQueryString } from '@utils';

import { ECustomHttpCode, ERoutes } from '@enums';

import { IApiCustomHttpError } from '@interfaces';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';
const CLOUDFLARE_AUTH0_API_ENDPOINT = process.env.REACT_APP_CLOUDFLARE_AUTH0_API_URL || '';

export const axiosConfig: AxiosRequestConfig = {
  timeout: 15_000,
  baseURL: API_ENDPOINT,
  timeoutErrorMessage: 'Request timeout.',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
};

export const axiosCloudflareAuth0Config: AxiosRequestConfig = {
  ...axiosConfig,
  baseURL: CLOUDFLARE_AUTH0_API_ENDPOINT,
};

const instance = axios.create(axiosConfig);
const cloudflareAuth0Instance = axios.create(axiosCloudflareAuth0Config);

const isErrorWithCustomHttpCode = (axiosResponse: AxiosResponse<IApiCustomHttpError>) =>
  isSomeEnum(ECustomHttpCode)(axiosResponse.data.errorCode);

const defaultResponseOptions = {
  showLogoutButton: true,
  showBackToHomepageButton: true,
};

const responseToQueryStringObject = (axiosResponse: AxiosResponse<IApiCustomHttpError>) => {
  if (isErrorWithCustomHttpCode(axiosResponse)) {
    return {
      ...defaultResponseOptions,
      errorCode: axiosResponse.data.errorCode,
    };
  }

  const message =
    axiosResponse.data instanceof Error ? axiosResponse.data.message : axiosResponse.data;

  if ([401, 403].includes(axiosResponse.status)) {
    return {
      message,
      ...defaultResponseOptions,
      apiUrl: axiosResponse.request.responseURL,
    };
  }

  return {
    message,
    ...defaultResponseOptions,
  };
};

instance.interceptors.response.use(
  (config) => config,
  (error: AxiosError) => {
    if (!isAxiosError(error) || !error.response) {
      return Promise.reject(error);
    }

    switch (error.response.status) {
      case 403:
        {
          const queryStringObject = responseToQueryStringObject(error.response);

          window.location.href = `${ERoutes.FORBIDDEN}${objectToQueryString(queryStringObject)}`;
        }
        break;

      case 401:
        {
          const queryStringObject = responseToQueryStringObject(error.response);

          window.location.href = `${ERoutes.FORBIDDEN}${objectToQueryString(queryStringObject)}`;
        }
        break;

      case 500:
        {
          const queryStringObject = responseToQueryStringObject(error.response);

          window.location.href = `${ERoutes.INTERNAL_SERVER_ERROR}${objectToQueryString(
            queryStringObject,
          )}`;
        }
        break;

      default:
        return Promise.reject(error);
    }
  },
);

export const axiosInstance = instance;
export const axiosCloudflareAuth0Instance = cloudflareAuth0Instance;
