import { AxiosResponse } from 'axios';

import {
  buildApiUrl,
  generateFilterQueryParam,
  generateSortQueryParam,
  prepareAxiosConfigWithAccessToken,
} from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IApiResponseWithPagination } from '@interfaces';

import { IGetCalendarEventsByAccountAttributes } from './get-calendar-events-by-account.types';

export const getCalendarEventsByAccountAction =
  (getAccessToken: TGetAccessToken) =>
  async ({
    filters,
    sorts,
    ...restProps
  }: IGetCalendarEventsByAccountAttributes): Promise<
    AxiosResponse<IApiResponseWithPagination<Record<string, string>[]>>
  > => {
    const accessToken = await getAccessToken();

    return axiosInstance.get(
      buildApiUrl(EApiEndpoint.CALENDAR_EVENTS_BY_ACCOUNT, {
        ...restProps,
        sort: generateSortQueryParam(sorts),
        filter: generateFilterQueryParam(filters),
      }),
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
