import { AxiosResponse } from 'axios';

import { prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IApiLead } from '@interfaces';

import { IUpdateLeadAttributes } from './update-lead.types';

export const updateLeadAction =
  (getAccessToken: TGetAccessToken) =>
  async ({
    leadId,
    leadStatus,
    name,
    phoneNumber,
  }: IUpdateLeadAttributes): Promise<AxiosResponse<IApiLead>> => {
    const accessToken = await getAccessToken();

    return axiosInstance.put(
      `${EApiEndpoint.LEADS}/${leadId}`,
      {
        name,
        phoneNumber,
        status: leadStatus,
      },
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
