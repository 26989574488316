import { Scrollbar, useDeviceDetect } from '@amperio/amperio-ui';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLocation } from 'react-router-dom';

import { useTranslation } from '@utils';

import { DesktopHeader, DesktopSidebar, MobileHeader, MobileTopbar } from '../../molecules';
import { getSidebarOptions, translationStrings } from './dashboard.defaults';
import { IDashboardProps } from './dashboard.types';

import './dashboard.styles.scss';

export const DashboardTemplate = ({ children }: IDashboardProps): JSX.Element => {
  const { user } = useAuth0();
  const location = useLocation();
  const { deviceData } = useDeviceDetect();
  const scrollRef = useRef<Scrollbars | null>(null);

  const translations = useTranslation(translationStrings);
  const sidebarOptions = useMemo(
    () => getSidebarOptions(translations, user?.name),
    [user?.name, translations],
  );

  useEffect(() => {
    const ref = scrollRef.current;

    if (ref && ref.getScrollTop() > 0) {
      ref.scrollToTop();
    }
  }, [scrollRef.current, location.pathname]);

  return (
    <div className="dashboard-wrapper">
      {deviceData.isMobile ? (
        <MobileTopbar options={sidebarOptions} />
      ) : (
        <DesktopSidebar options={sidebarOptions} />
      )}
      <section className="dashboard-wrapper__container">
        {deviceData.isMobile ? <MobileHeader /> : <DesktopHeader />}
        <div className="dashboard-wrapper__content">
          <Scrollbar ref={scrollRef} height="100%">
            <div className="dashboard-wrapper__content-padding">{children}</div>
          </Scrollbar>
        </div>
      </section>
    </div>
  );
};
