import { prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { ICreateLeadFormFields } from '../../create-lead.types';

export const createLeadAction =
  (getAccessToken: TGetAccessToken) => async (requestData: ICreateLeadFormFields | null) => {
    const accessToken = await getAccessToken();

    return axiosInstance.post(
      EApiEndpoint.LEADS,
      requestData,
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
