import {
  Button,
  Fab,
  Icon,
  PlusIcon,
  Typography,
  getCountryCodeFromPhoneNumber,
  toast,
  useConfirmationModal,
  useDeviceDetect,
} from '@amperio/amperio-ui';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';

import { addPlusToPhoneNumber, useGhostMode, useMutation, usePrompt } from '@utils';

import { EDashboardLeadsRoutes, EDashboardRoutes, ERoutes } from '@enums';

import { createLeadAction } from './api';
import { ICreateLeadFormFields } from './create-lead.types';
import { CreateLeadForm, defaultFormFields } from './molecules';

import './create-lead.styles.scss';

export const CreateLead = (): JSX.Element => {
  const navigate = useNavigate();
  const { deviceData } = useDeviceDetect();
  const { confirm } = useConfirmationModal();
  const { isGhostMode, user } = useGhostMode();
  const [isEditMode, setIsEditMode] = useState(true);

  const { isLoading: isCreateLeadLoading, mutateAsync: mutateAsyncCreateLeadAction } =
    useMutation(createLeadAction);

  const { control, formState, handleSubmit, reset, setError, watch } =
    useForm<ICreateLeadFormFields>({
      mode: 'onChange',
      shouldFocusError: true,
      defaultValues: defaultFormFields,
    });

  const phoneNumberWatch = watch('phoneNumber');

  usePrompt('Unsaved changes', 'Are you sure you want to leave?', formState.isDirty);

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const onSubmit = (data: ICreateLeadFormFields) => {
    if (Object.keys(formState.errors).length > 0) {
      return;
    }

    const phoneNumberWithPlus = addPlusToPhoneNumber(data.phoneNumber);

    if (!isValidPhoneNumber(phoneNumberWithPlus)) {
      return;
    }

    mutateAsyncCreateLeadAction({
      ...data,
      phoneNumber: phoneNumberWithPlus,
    })
      .then(() => {
        if (!deviceData.isMobile) {
          toggleEditMode();
        }

        reset(data);
        toast.success('Lead has been created');

        setTimeout(
          () =>
            navigate(
              `/${ERoutes.DASHBOARD}/${EDashboardRoutes.LEADS}/${EDashboardLeadsRoutes.LIST}`,
            ),
          0,
        );
      })
      .catch(() => toast.error('Something went wrong'));
  };

  const handleCancel = () => {
    if (!formState.isDirty) {
      toggleEditMode();
      return;
    }

    confirm({
      title: 'Unsaved changes',
      confirmationButtonProps: { autoFocus: true },
      description: 'Are you sure you want to cancel?',
    }).then(() => {
      reset({
        ...defaultFormFields,
        phoneNumber: getCountryCodeFromPhoneNumber(phoneNumberWatch),
      });
      toggleEditMode();
    });
  };

  if (!user?.name || !isGhostMode()) {
    return <Navigate to={ERoutes.NOT_FOUND} />;
  }

  return (
    <form className="create-lead-wrapper" onSubmit={handleSubmit(onSubmit)}>
      <header className="create-lead-wrapper__header">
        {!deviceData.isMobile ? (
          <>
            {!isEditMode ? (
              <Button variant="contained" onClick={() => setIsEditMode(!isEditMode)}>
                Edit
              </Button>
            ) : (
              <span>
                <Button
                  disabled={!isEditMode || isCreateLeadLoading}
                  variant="contained"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!isEditMode || !formState.isDirty}
                  isLoading={isCreateLeadLoading}
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              </span>
            )}
          </>
        ) : null}
        {deviceData.isMobile && formState.isDirty ? (
          <Fab disabled={isCreateLeadLoading} type="submit" variant="extended">
            <Icon stroke="white">
              <PlusIcon />
            </Icon>
            <Typography strong as="span" color="white">
              Save
            </Typography>
          </Fab>
        ) : null}
      </header>
      <section className="create-lead-wrapper__content">
        <CreateLeadForm control={control} isEditMode={isEditMode} setError={setError} />
      </section>
    </form>
  );
};
