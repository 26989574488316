import { isObject } from '@amperio/amperio-ui';
import { addMinutes, setHours, setMinutes, setSeconds } from 'date-fns';

import { isSomeEnum, removeUnnecessaryValuesFromObject } from '@utils';

import { ECurrency, EPaymentMethod, EUnit } from '@enums';

import { IUpsertEventFormFields } from '@interfaces';

const prepareAddressObject = (formData: IUpsertEventFormFields) => ({
  street: formData.address.street,
  region: formData.address.region,
  zipCode: formData.address.zipCode,
  district: formData.address.district,
  postOfficeCity: formData.address.city,
  houseNo: formData.address.houseNumber,
});

const prepareCalendarEventObject = (formData: IUpsertEventFormFields) => {
  const { customer, date, entryId, eventType, meetingHour, meetingLengthInMinutes, owner } =
    formData.general;

  const [hours, minutes] = meetingHour.split(':').map((value) => Number(value));

  const startDate = setSeconds(setMinutes(setHours(new Date(date), hours), minutes), 0);
  const endDate = addMinutes(startDate, meetingLengthInMinutes);

  const commentValue = formData.summary.notes;
  const comment = commentValue
    ? {
        text: commentValue,
        createdOn: new Date().toISOString(),
      }
    : null;

  return {
    comment,
    entryId,
    type: eventType,
    assignedTo: owner,
    accountId: customer,
    endDate: endDate.toISOString(),
    startDate: startDate.toISOString(),
    status: formData.summary.eventStatus,
    productCategories: formData.productCategories.productCategories,
  };
};

const prepareProductSurveyObject = (formData: IUpsertEventFormFields) => {
  const houseAreaValue = formData.productCategories.houseArea;
  const paymentMethodValue = formData.productCategories.paymentMethod;

  const houseArea = houseAreaValue
    ? {
        value: houseAreaValue,
        unit: EUnit.SQUARE_METERS,
      }
    : null;

  const paymentMethod = isSomeEnum(EPaymentMethod)(paymentMethodValue) ? paymentMethodValue : '';

  return {
    houseArea,
    paymentMethod,
    roofArea: {
      unit: EUnit.SQUARE_METERS,
      value: formData.productCategories.roofArea,
    },
    electricityBill: {
      amount: {
        currency: ECurrency.EUR,
        amount: formData.productCategories.monthlyElectricBill,
      },
      month: formData.productCategories.monthOfTheGivenBill,
    },
    installationSite: formData.productCategories.roofOrGround,
    roofDirections: formData.productCategories.installationSite,
    insulationLevel: formData.productCategories.levelOfInsulation,
    currentHeatingType: formData.productCategories.currentTypeOfHeating,
  };
};

export const transformFormDataToApiContract = (formData: IUpsertEventFormFields) => {
  if (!isObject(formData)) {
    return null;
  }

  const address = prepareAddressObject(formData);
  const productSurvey = prepareProductSurveyObject(formData);
  const calendarEvent = prepareCalendarEventObject(formData);

  return removeUnnecessaryValuesFromObject({
    address,
    calendarEvent,
    productSurvey,
  });
};
