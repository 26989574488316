export default {
  // home
  'dashboard.header.home': 'Inicio',
  'dashboard.header.create-lead': 'Crear plomo',

  // events
  'dashboard.header.events.list': 'Eventos',
  'dashboard.header.events.upsert-event': 'Eventos / Evento Actualizar-Insertar',
  'dashboard.header.events.upsert-account':
    'Eventos / Evento Actualizar-Insertar / Actualizar-insertar cuenta',

  // accounts
  'dashboard.header.accounts.list': 'Cuentas',
  'dashboard.header.accounts.upsert-account': 'Cuentas / Actualizar-insertar cuenta',
  'dashboard.header.accounts.upsert-event': 'Cuentas / Cuenta Upsert / Evento Upsert',

  // leads
  'dashboard.header.leads.list': 'Contactos',
  'dashboard.header.leads.upsert-lead': 'Contactos / Actualizar-Insertar contactos',
  'dashboard.header.leads.upsert-account':
    'Clientes potenciales / Cliente potencial Upsert / Cuenta Upsert',
  'dashboard.header.leads.upsert-event':
    'Clientes potenciales / Cliente potencial Upsert / Cuenta Upsert / Evento Upsert',
};
