import {
  toast,
  useConfirmationModal,
  useDeviceDetect,
  useDidMountEffect,
} from '@amperio/amperio-ui';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DateTimeParam, StringParam, useQueryParams } from 'use-query-params';

import { AddressForm, GeneralForm, ProductCategoriesForm, SummaryForm } from '@ui';

import {
  addPlusToPhoneNumber,
  removePlusFromPhoneNumber,
  useAxiosError,
  useGhostMode,
  useMutation,
  usePrompt,
  useTranslation,
} from '@utils';

import { IUpsertEventFormFields } from '@interfaces';

import {
  createCalendarEventAction,
  getAccountByIdAction,
  getCalendarEventByIdAction,
  updateCalendarEventAction,
} from './api';
import { UpsertEventHeader } from './parts';
import { defaultFormFields, translationStrings } from './upsert-event.defaults';
import { IUpsertEventProps } from './upsert-event.types';
import { transformApiContractToFormData, transformFormDataToApiContract } from './utils';

import './upsert-event.styles.scss';

export const UpsertEvent = ({
  onBackClick,
  onProfileIconClick,
}: IUpsertEventProps): JSX.Element => {
  const [queryParams, setQueryParams] = useQueryParams({
    userId: StringParam,
    eventId: StringParam,
    accountId: StringParam,
    selectedEventDate: DateTimeParam,
  });

  const { deviceData } = useDeviceDetect();
  const { confirm } = useConfirmationModal();
  const { isGhostModeAccountId, user } = useGhostMode();
  const { receiveErrorMessageFromError } = useAxiosError();
  const [productSurveyId, setProductSurveyId] = useState('');
  const [isEditMode, setIsEditMode] = useState(deviceData.isMobile || !queryParams.eventId);

  const translations = useTranslation(translationStrings);
  const isNewEvent = !queryParams.eventId;

  const {
    isLoading: isGetCalendarEventByIdLoading,
    mutateAsync: mutateAsyncGetCalendarEventByIdAction,
  } = useMutation(getCalendarEventByIdAction(queryParams.eventId!));

  const {
    isLoading: isGetAddressByAccountIdLoading,
    mutateAsync: mutateAsyncGetAddressByAccountId,
  } = useMutation(getAccountByIdAction);

  const { isLoading: isUpdateEventLoading, mutateAsync: mutateAsyncUpdateCalendarEventAction } =
    useMutation(updateCalendarEventAction);

  const { isLoading: isCreateEventLoading, mutateAsync: mutateAsyncCreateCalendarEventAction } =
    useMutation(createCalendarEventAction);

  useDidMountEffect(() => {
    setIsEditMode(deviceData.isMobile);
  }, [deviceData.isMobile]);

  const auth0UserId = isGhostModeAccountId(user?.sub) ? '' : user?.sub;

  const { control, formState, handleSubmit, reset, setError, setValue, watch } =
    useForm<IUpsertEventFormFields>({
      mode: 'onChange',
      shouldFocusError: true,
      defaultValues: {
        ...defaultFormFields,
        general: {
          ...defaultFormFields.general,
          customer: queryParams.accountId || '',
          owner: queryParams.userId || auth0UserId || '',
          date: queryParams.selectedEventDate
            ? new Date(queryParams.selectedEventDate)
            : new Date(),
        },
      },
    });

  useEffect(() => {
    if (!queryParams.eventId) {
      return;
    }

    mutateAsyncGetCalendarEventByIdAction({}).then((result) => {
      // @ts-expect-error
      reset(transformApiContractToFormData(result.data));

      if (!productSurveyId) {
        // @ts-expect-error
        setProductSurveyId(result.data.productSurvey.id);
      }
    });
  }, [queryParams.eventId]);

  const selectedCustomerId = watch('general.customer');
  const phoneNumberFieldValue = watch('general.phoneNumber');

  useEffect(() => {
    if (!selectedCustomerId) {
      return;
    }

    mutateAsyncGetAddressByAccountId(selectedCustomerId).then(({ data }) => {
      setValue('general.phoneNumber', removePlusFromPhoneNumber(data.contactInfo.phoneNumber));

      setValue('address.street', data.primaryAddress.street, { shouldValidate: true });
      setValue('address.region', data.primaryAddress.region, { shouldValidate: true });
      setValue('address.zipCode', data.primaryAddress.zipCode, { shouldValidate: true });
      setValue('address.district', data.primaryAddress.district, { shouldValidate: true });
      setValue('address.city', data.primaryAddress.postOfficeCity, { shouldValidate: true });
      setValue('address.houseNumber', data.primaryAddress.houseNo, { shouldValidate: true });
    });
  }, [selectedCustomerId]);

  usePrompt(
    translations.commonPromptUnsavedTitle,
    translations.commonPromptUnsavedLeave,
    formState.isDirty,
  );

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const onSubmit = async (data: IUpsertEventFormFields) => {
    if (Object.keys(formState.errors).length > 0) {
      return;
    }

    const phoneNumberWithPlus = addPlusToPhoneNumber(data.general.phoneNumber);

    if (!isValidPhoneNumber(phoneNumberWithPlus)) {
      return;
    }

    const transformedData = transformFormDataToApiContract(data);

    if (!queryParams.eventId) {
      mutateAsyncCreateCalendarEventAction(transformedData)
        .then((result) => {
          if (!deviceData.isMobile) {
            toggleEditMode();
          }

          reset(data);
          setProductSurveyId(result.data.productSurvey.id);
          setTimeout(
            () =>
              setQueryParams({ ...queryParams, eventId: result.data.calendarEvent.id }, 'replace'),
            0,
          );

          toast.success(translations.notificationsSuccessEventCreated);
        })
        .catch((error) => toast.error(receiveErrorMessageFromError(error)));

      return;
    }

    mutateAsyncUpdateCalendarEventAction({
      eventId: queryParams.eventId,
      // @ts-expect-error
      data: {
        ...transformedData,
        productSurvey: {
          id: productSurveyId,
          // @ts-expect-error
          ...transformedData?.productSurvey,
        },
      },
    })
      .then((result) => {
        if (!deviceData.isMobile) {
          toggleEditMode();
        }

        reset(data);
        setProductSurveyId(result.data.productSurvey.id);

        toast.success(translations.notificationsSuccessEventDetailsChangesSaved);
      })
      .catch((error) => toast.error(receiveErrorMessageFromError(error)));
  };

  const handleCancel = () => {
    if (!formState.isDirty) {
      toggleEditMode();
      return;
    }

    confirm({
      confirmationButtonProps: { autoFocus: true },
      title: translations.commonPromptUnsavedTitle,
      description: translations.commonPromptUnsavedCancel,
    }).then(() => {
      reset(defaultFormFields);
      toggleEditMode();
    });
  };

  const handleProfileIconClick = () => {
    if (!selectedCustomerId || !onProfileIconClick) {
      return;
    }

    onProfileIconClick(selectedCustomerId);
  };

  return (
    <form className="upsert-event-wrapper" onSubmit={handleSubmit(onSubmit)}>
      <UpsertEventHeader
        handleProfileIconClick={handleProfileIconClick}
        isCreateEventLoading={isCreateEventLoading}
        isDirtyForm={formState.isDirty}
        isEditMode={isEditMode}
        isGetCalendarEventByIdLoading={isGetCalendarEventByIdLoading}
        isUpdateEventLoading={isUpdateEventLoading}
        phoneNumberFieldValue={phoneNumberFieldValue}
        shouldRenderFloatingActionButton={
          !!queryParams.eventId && deviceData.isMobile && !formState.isDirty
        }
        toggleEditMode={toggleEditMode}
        translations={translations}
        onBackClick={onBackClick}
        onCancelClick={handleCancel}
      />
      <section className="upsert-event-wrapper__content">
        <div className="upsert-event-wrapper__content-left">
          <GeneralForm
            control={control}
            disableProfileIcon={!onProfileIconClick}
            isEditMode={isEditMode}
            isNewEvent={isNewEvent}
            setError={setError}
            onProfileIconClick={handleProfileIconClick}
          />
          <AddressForm
            control={control as never}
            isEditMode={!isGetAddressByAccountIdLoading && !!selectedCustomerId && isEditMode}
            setValue={setValue as never}
            watch={watch as never}
          />
          {queryParams.eventId ? <SummaryForm control={control} isEditMode={isEditMode} /> : null}
        </div>
        <ProductCategoriesForm control={control} isEditMode={isEditMode} watch={watch} />
      </section>
    </form>
  );
};
