import { IUpsertAccountFormFields } from '@interfaces';

import { accountFormDefaultFormFields, addressFormDefaultFormFields } from '../../molecules';

export const translationStrings = [
  'common.buttons.edit',
  'common.buttons.save',
  'common.buttons.cancel',
  'common.buttons.back',
  'common.prompt.unsaved.leave',
  'common.prompt.unsaved.title',
  'common.prompt.unsaved.cancel',
  'common.prompt.something.went.wrong',
  'dashboard.accounts.header.call.button',
  'dashboard.accounts.header.add.event.button',
  'dashboard.accounts.header.assign.to.button',
  'notifications.success.account.created',
  'notifications.success.account.changes.saved',
] as const;

export const defaultFormFields: IUpsertAccountFormFields = {
  account: accountFormDefaultFormFields,
  address: addressFormDefaultFormFields,
};
