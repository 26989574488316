import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { UpsertAccount, UpsertEvent } from '@ui';

import { objectToQueryString, useUserClaims } from '@utils';

import { EDashboardAccountsRoutes, EDashboardLeadsRoutes, EDashboardRoutes, ERoutes } from '@enums';

import { IApiLead } from '@interfaces';

import { LeadsListManager, LeadsListSeller, UpsertLead } from './organisms';

export const Leads = (): JSX.Element => {
  const navigate = useNavigate();
  const { isSalesManager, isSalesRepresentative } = useUserClaims();

  const onBackButtonClick = () => navigate(-1);

  // leads list - actions
  const onLeadsListRowClick = (lead: IApiLead) =>
    navigate(
      `${EDashboardLeadsRoutes.UPSERT_LEAD}${objectToQueryString({
        id: lead.id || '',
        name: lead.name || '',
        status: lead.status || '',
        phoneNumber: lead.phoneNumber || '',
      })}`,
    );

  // upsert lead - actions
  const onUpsertLeadAddAccountClick = (leadId: string, phoneNumber: string, name: string) =>
    navigate(
      `${EDashboardLeadsRoutes.UPSERT_ACCOUNT}${objectToQueryString({
        name,
        leadId,
        phoneNumber,
      })}`,
    );

  const onUpsertLeadSetSelectedLead = (lead: IApiLead) =>
    navigate(
      `${EDashboardLeadsRoutes.UPSERT_LEAD}${objectToQueryString({
        id: lead.id,
        name: lead.name,
        status: lead.status,
        phoneNumber: lead.phoneNumber,
      })}`,
      { replace: true },
    );

  // upsert account - actions
  const onUpsertAccountAddEventClick = (accountId: string) =>
    navigate(`${EDashboardLeadsRoutes.UPSERT_EVENT}${objectToQueryString({ accountId })}`);

  const onEventsTableRowClick = (eventId: string) =>
    navigate(
      `/${ERoutes.DASHBOARD}/${EDashboardRoutes.ACCOUNTS}/${EDashboardAccountsRoutes.UPSERT_EVENT}?eventId=${eventId}`,
    );

  return (
    <Routes>
      <Route
        element={
          <>
            {isSalesManager() && <LeadsListManager onTableRowClick={onLeadsListRowClick} />}
            {isSalesRepresentative() && <LeadsListSeller onTableRowClick={onLeadsListRowClick} />}
          </>
        }
        path={EDashboardLeadsRoutes.LIST}
      />
      <Route
        element={
          <UpsertLead
            setSelectedLead={onUpsertLeadSetSelectedLead}
            onAddAccountClick={onUpsertLeadAddAccountClick}
            onBackClick={onBackButtonClick}
          />
        }
        path={EDashboardLeadsRoutes.UPSERT_LEAD}
      />
      <Route
        element={
          <UpsertAccount
            onAddEventClick={onUpsertAccountAddEventClick}
            onBackClick={onBackButtonClick}
            onEventsTableRowClick={onEventsTableRowClick}
          />
        }
        path={EDashboardLeadsRoutes.UPSERT_ACCOUNT}
      />
      <Route
        element={<UpsertEvent onBackClick={onBackButtonClick} />}
        path={EDashboardLeadsRoutes.UPSERT_EVENT}
      />
      <Route element={<Navigate replace to={ERoutes.NOT_FOUND} />} path={ERoutes.ANY} />
    </Routes>
  );
};
