import { useMemo } from 'react';

import { useQuery } from '@utils';

import { IApiDistrict, IApiRegion } from '@interfaces';

import { GET_REGIONS_ACTION_KEY, getRegionsAction } from '../../../api';

const mapToDropdownOptions = (data: IApiRegion[] | IApiDistrict[] | undefined) =>
  data?.map((_region) => ({
    label: _region.name,
    value: _region.name,
  })) || [];

export const useRegionsQuery = (selectedRegion: string) => {
  const { data: regionsData, isLoading: isRegionsDataLoading } = useQuery(
    GET_REGIONS_ACTION_KEY,
    getRegionsAction,
    {
      staleTime: Number.POSITIVE_INFINITY,
    },
  );

  const regionsDropdownOptions = useMemo(
    () => mapToDropdownOptions(regionsData?.data),
    [regionsData?.data],
  );

  const districtsDropdownOptions = useMemo(
    () =>
      mapToDropdownOptions(
        regionsData?.data?.find((_region) => _region.name === selectedRegion)?.districts,
      ),
    [regionsData?.data, selectedRegion],
  );

  return { isRegionsDataLoading, regionsDropdownOptions, districtsDropdownOptions };
};
