import { CenterBlock, Typography } from '@amperio/amperio-ui';
import classnames from 'classnames';

import { IErrorProps } from './error.types';

import './error.styles.scss';

export const Error = ({ children, className, code, message }: IErrorProps): JSX.Element => (
  <CenterBlock className={classnames('error-wrapper', className)}>
    <Typography as="h1">{code}</Typography>
    <Typography as="h2">{message}</Typography>
    <section className="error-wrapper__actions">{children}</section>
  </CenterBlock>
);
