import { EEventStatus, ELeadStatus, ELocale } from '@enums';

export const DEFAULT_COUNTRY_CODE = 'ES';
export const GHOST_MODE_SUFFIX = '#GHOST';
export const DEFAULT_PHONE_INPUT_COUNTRY = 'es';
export const PHONE_INPUT_ONLY_COUNTRIES = ['pl', 'es', 'gb', 'lt', 'fr', 'sk'];

export const LARGE_SCREEN_BREAKPOINT = 1600;
export const MEDIUM_SCREEN_BREAKPOINT = 1200;
export const SMALL_SCREEN_BREAKPOINT = 992;
export const MOBILE_SCREEN_BREAKPOINT = 768;
export const XMOBILE_SCREEN_BREAKPOINT = 480;

export const DEFAULT_LOCALE: ELocale =
  (process.env.REACT_APP_DEFAULT_LOCALE as ELocale) ?? ELocale.EN;

/**
 * openid: This scope informs the Auth0 Authorization Server that the Client is making
 * an OpenID Connect (OIDC) request to verify the user's identity. OpenID Connect is
 * an authentication protocol.
 *
 * profile: This scope value requests access to the user's default profile information,
 * such as name, nickname, and picture.
 *
 * email: This scope value requests access to the email and email_verified information.
 */
export const AUTH0_SCOPES = 'email openid profile';
export const AUTH0_CLAIMS_LOCAL_STORAGE_KEY = 'amperio-partners-auth0-claims-key';

// localStorage keys
export const EVENTS_MANAGER_SELECTED_USER_ID_KEY =
  'amperio-partners-events-manager-selected-user-id';
export const EVENTS_SHOULD_RENDER_WEEKENDS_KEY = 'amperio-partners-events-should-render-weekends';
export const HOMEPAGE_SHOULD_RENDER_WEEKENDS_KEY =
  'amperio-partners-homepage-should-render-weekends';

export const leadStatusToChipColorMapping: Record<string, TChipColor> = {
  [ELeadStatus.NEW]: 'blue',
  [ELeadStatus.INVALID]: 'gray',
  [ELeadStatus.RESIGNATION]: 'red',
  [ELeadStatus.CALLBACK]: 'orange',
  [ELeadStatus.DUPLICATE]: 'brown',
  [ELeadStatus.INTERESTED]: 'green',
};

export const eventStatusToChipColorMapping: Record<string, TChipColor> = {
  [EEventStatus.SALE]: 'green',
  [EEventStatus.MISSED]: 'gray',
  [EEventStatus.PLANNED]: 'purple',
  [EEventStatus.RESIGNATION]: 'red',
  [EEventStatus.REJECTED]: 'orange',
  [EEventStatus.BID_SUBMITTED]: 'blue',
  [EEventStatus.SALES_OPPORTUNITY]: 'brown',
};
