import { objectToQueryString, prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosCloudflareAuth0Instance } from '@infrastructure';

const cloudflareApiKey = process.env.REACT_APP_CLOUDFLARE_AUTH0_API_KEY;

export const acceptInvitationAction =
  // eslint-disable-next-line unicorn/consistent-function-scoping
  (organization: string, invitation: string) => () => async (variables: unknown) =>
    axiosCloudflareAuth0Instance.post(
      `/accept-invitation${objectToQueryString({ organization, invitation })}`,
      variables,
      prepareAxiosConfigWithAccessToken(cloudflareApiKey),
    );
