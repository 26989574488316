export default {
  /// /////// PRODUCT CATEGORIES //////////

  // section header
  'form.product.categories.header': 'Products',

  // Product categories dropdown
  'form.product.categories.label': 'Set products',
  'form.product.categories.heating.foils': 'Heating foils',
  'form.product.categories.photovoltaics': 'Photovoltaics',
  'form.product.categories.heating.pumps': 'Heating pumps',

  // Payment method dropdown
  'form.product.categories.payment.method.label': 'Payment method',
  'form.product.categories.payment.method.cash': 'Cash',
  'form.product.categories.payment.method.credit': 'Credit',

  // House area input
  'form.product.categories.house.area.label': 'House area',
  'form.product.categories.house.area.min.error': 'Minimum value is 80',
  'form.product.categories.house.area.max.error': 'Maximum value is 5000',

  // Level of insulation dropdown
  'form.product.categories.level.of.insulation.label': 'Level of insulation',
  'form.product.categories.level.of.insulation.passive': 'Passive',
  'form.product.categories.level.of.insulation.energy.saving': 'Energy saving',
  'form.product.categories.level.of.insulation.well.insulated': 'Well insulated',
  'form.product.categories.level.of.insulation.medium.insulated': 'Medium insulated',
  'form.product.categories.level.of.insulation.poorly.insulated': 'Poorly insulated',

  // The current type of heating dropdown
  'form.product.categories.current.type.of.heating.label': 'The current type of heating',
  'form.product.categories.current.type.of.heating.coal.stove': 'Coal stove',
  'form.product.categories.current.type.of.heating.gas.stove': 'Gas stove',
  'form.product.categories.current.type.of.heating.electric.heating': 'Electric heating',
  'form.product.categories.current.type.of.heating.other': 'Other',

  // Installation site dropdown
  'form.product.categories.installation.site.label': 'Installation site',
  'form.product.categories.installation.site.north': 'North',
  'form.product.categories.installation.site.south': 'South',
  'form.product.categories.installation.site.east': 'East',
  'form.product.categories.installation.site.west': 'West',

  // Roof or ground dropdown
  'form.product.categories.roof.or.ground.label': 'Roof or ground',
  'form.product.categories.roof.or.ground.roof': 'Roof',
  'form.product.categories.roof.or.ground.ground': 'Ground',

  // Roof area input
  'form.product.categories.roof.area.label': 'Roof area',
  'form.product.categories.roof.area.min.error': 'Minimum value is 20',
  'form.product.categories.roof.area.max.error': 'Maximum value is 1000',

  // Monthly electric bill input
  'form.product.categories.monthly.electric.bill.label': 'Monthly electric bill',
  'form.product.categories.monthly.electric.bill.min.error': 'Minimum value is 20',
  'form.product.categories.monthly.electric.bill.max.error': 'Maximum value is 5000',

  // Month of the given bill dropdown
  'form.product.categories.month.of.the.given.bill.label': 'Month of the given bill',

  /// /////// ADDRESS //////////

  // section header
  'form.address.header': 'Address',

  // region dropdown
  'form.address.region.label': 'Region',

  // district dropdown
  'form.address.district.label': 'District',

  // city input
  'form.address.city.label': 'City',
  'form.address.city.max.length.error': 'Maximum length is 100',

  // street input
  'form.address.street.label': 'Street',
  'form.address.street.max.length.error': 'Maximum length is 100',

  // zipcode input
  'form.address.zip.code.label': 'Zip code',
  'form.address.zip.code.pattern.match.error': 'Incorrect zipcode format',

  // house number input
  'form.address.house.number.label': 'House number',
  'form.address.house.number.pattern.match.error': 'Incorrect house number format',

  /// /////// GENERAL //////////

  // section header
  'form.general.header': 'General',

  // customer dropdown
  'form.general.customer.label': 'Customer',

  // phone number input
  'form.general.phone.number.label': 'Phone number',

  // event type dropdown
  'form.general.event.type.label': 'Type of event',
  'form.general.event.type.call': 'Call',
  'form.general.event.type.meeting': 'Meeting',

  // owner dropdown
  'form.general.owner.label': 'Owner',

  // select date dropdown
  'form.general.select.meeting.date.label': 'Select date',

  // select hour dropdown
  'form.general.select.meeting.hour.label': 'Select hour',

  // select meeting length dropdown
  'form.general.select.meeting.length.label': 'Select event duration',

  /// /////// ACCOUNT //////////

  // section header
  'form.account.header': 'Account',

  // name input
  'form.account.name.label': 'Full name',
  'form.account.name.max.length.error': 'Maximum length is 100',

  // phone number input
  'form.account.phone.number.label': 'Phone number',
  'form.account.phone.number.pattern.match.error': 'Incorrect phone number format',

  // email input
  'form.account.email.label': 'Email',
  'form.account.email.pattern.match.error': 'Incorrect email format',
  'form.account.email.max.length.error': 'Maximum length is 100',

  /// /////// LEADS //////////

  // name input
  'form.lead.name.label': 'Full name',
  'form.lead.name.max.length.error': 'Maximum length is 100',

  // phone number input
  'form.lead.phone.number.label': 'Phone number',
  'form.lead.phone.number.pattern.match.error': 'Incorrect phone number format',

  // status dropdown
  'form.lead.status.label': 'Status after event',

  /// /////// SUMMARY //////////

  // section header
  'form.summary.header': 'Summary',

  // notes input
  'form.summary.notes.label': 'Notes',
  'form.summary.notes.max.length.error': 'Maximum length is 500',

  // status dropdown
  'form.summary.status.label': 'Status after event',
};
