export default {
  // other
  'accept.invitation.header': 'Bienvenido al Panel de Socios de Amperio',
  'accept.invitation.subheader.prefix': 'Acepta la invitación de',
  'accept.invitation.subheader.suffix': 'y rellena el formulario.',

  // form
  'accept.invitation.form.full.name.label': 'Nombre completo',
  'accept.invitation.form.email.label': 'Correo electrónico',
  'accept.invitation.form.password.label': 'Contraseña',
  'accept.invitation.form.password.length': 'Usa entre 8 y 18 caracteres',
  'accept.invitation.form.password.special.characters': 'Usa caracteres especiales (!@#$%^&*)',
  'accept.invitation.form.password.lower.and.upper.letters': 'Usa mayúsculas y minúsculas (Aa-Zz)',
  'accept.invitation.form.password.numbers': 'Usa cifras (0-9)',
  'accept.invitation.form.submit.button': 'Continuar',
};
