import { WHITESPACES_REGEX, isString } from '@amperio/amperio-ui';

export const startsOrEndsWithWhitespace =
  (message: string = '') =>
  (value: unknown) => {
    if (!isString(value) || value.length === 0) {
      return true;
    }

    return WHITESPACES_REGEX.test(String(value)) || message;
  };
