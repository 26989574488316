import {
  Chip,
  DataGrid,
  HeatPlatesIcon,
  HeatPumpsIcon,
  Icon,
  SolarPanelsIcon,
  Typography,
  isArray,
} from '@amperio/amperio-ui';
import { lightFormat } from 'date-fns';
import { Children } from 'react';

import { getEventStatusToTranslationsMapping, useMutation, useTranslation } from '@utils';

import { eventStatusToChipColorMapping } from '@infrastructure';

import { EProductCategory } from '@enums';

import { getAccountsTableDataAction } from '../../api';
import { translationStrings } from './accounts-table.defaults';
import { IAccountsTableProps } from './accounts-table.types';

import './accounts-table.styles.scss';

export const AccountsTable = ({ onRowClick }: IAccountsTableProps): JSX.Element => {
  const translations = useTranslation(translationStrings);
  const { isLoading, mutateAsync } = useMutation(getAccountsTableDataAction);

  const eventStatusToTranslationsMapping = getEventStatusToTranslationsMapping(translations);

  return (
    <DataGrid
      shouldCalculateRowsPerPageDynamically
      className="accounts-table-wrapper"
      columns={[
        {
          minWidth: 250,
          field: 'name',
          headerName: translations.dashboardAccountsListNameColumnLabel,
          renderBodyCell: (value) => (
            <Typography
              ellipsis
              strong
              as="span"
              data-testid="account-table--fullname"
              maxWidth={250}
            >
              {value || '-'}
            </Typography>
          ),
        },
        {
          minWidth: 250,
          field: 'location',
          headerName: translations.dashboardAccountsListLocationColumnLabel,
          renderBodyCell: (value) => (
            <Typography ellipsis as="span" maxWidth={250}>
              {value || '-'}
            </Typography>
          ),
        },
        {
          minWidth: 150,
          field: 'products',
          headerName: translations.dashboardAccountsListProductsColumnLabel,
          renderBodyCell: (value: unknown) => {
            if (!isArray(value) || value.length === 0) {
              return <span>-</span>;
            }

            return (
              <div className="accounts-table-wrapper__products-cell">
                {Children.toArray(
                  value.map((_element: string) => (
                    <Icon fill="none" stroke="primary">
                      {_element === EProductCategory.HEATING_FOILS ? <HeatPlatesIcon /> : null}
                      {_element === EProductCategory.HEATING_PUMPS ? <HeatPumpsIcon /> : null}
                      {_element === EProductCategory.PHOTOVOLTAICS ? <SolarPanelsIcon /> : null}
                    </Icon>
                  )),
                )}
              </div>
            );
          },
        },
        {
          minWidth: 150,
          field: 'modifiedOn',
          headerName: translations.dashboardAccountsListDateColumnLabel,
          renderBodyCell: (value) => {
            if (!value) {
              return <span>-</span>;
            }

            return <Typography as="span">{lightFormat(new Date(value), 'dd/MM/yyy')}</Typography>;
          },
        },
        {
          minWidth: 150,
          field: 'status',
          headerName: translations.dashboardAccountsListStatusColumnLabel,
          renderBodyCell: (value: string) => {
            const label = eventStatusToTranslationsMapping[value];

            if (!label) {
              return <span>-</span>;
            }

            return <Chip color={eventStatusToChipColorMapping[value]} label={label} size="small" />;
          },
        },
      ]}
      getDataFromServer={async ({ filters, page, rowsPerPage, sorts }) => {
        const { data } = await mutateAsync({
          sorts,
          filters,
          pageNumber: page,
          pageSize: rowsPerPage,
        });

        return { records: data.data, recordsCount: data.totalCount };
      }}
      headerHeight={50}
      isLoading={isLoading}
      noDataMessage={translations.commonTableNoData}
      rowHeight={50}
      onBodyRow={(record) => ({
        pointer: true,
        // @ts-expect-error
        onClick: () => onRowClick(record.id),
      })}
    />
  );
};
