import { differenceInMinutes, getHours, getMinutes } from 'date-fns';

import { isSomeEnum, removePlusFromPhoneNumber } from '@utils';

import { EPaymentMethod } from '@enums';

import { IUpsertEventFormFields } from '@interfaces';

import { IApiUpsertResponse } from '../../../upsert-event.types';

const prepareGeneralObject = (upsertResponse: IApiUpsertResponse) => {
  const { accountId, assignedTo, endDate, startDate, type } = upsertResponse.calendarEvent;

  const end = new Date(endDate);
  const start = new Date(startDate);

  const meetingLengthInMinutes = Math.abs(differenceInMinutes(start, end));
  const meetingHour = `${getHours(start)}:${getMinutes(start).toString().padEnd(2, '0')}`;

  return {
    date: start,
    meetingHour,
    eventType: type || '',
    owner: assignedTo || '',
    meetingLengthInMinutes,
    customer: accountId || '',
    phoneNumber: removePlusFromPhoneNumber(upsertResponse?.account?.contactInfo?.phoneNumber),
  };
};

const prepareProductCategoriesObject = (upsertResponse: IApiUpsertResponse) => {
  const paymentMethodValue = upsertResponse.productSurvey?.paymentMethod;

  const paymentMethod = isSomeEnum(EPaymentMethod)(paymentMethodValue) ? paymentMethodValue : '';

  return {
    paymentMethod,
    roofArea: upsertResponse.productSurvey?.roofArea?.value || '',
    houseArea: upsertResponse.productSurvey?.houseArea?.value || '',
    roofOrGround: upsertResponse.productSurvey?.installationSite || '',
    installationSite: upsertResponse.productSurvey?.roofDirections || [],
    levelOfInsulation: upsertResponse.productSurvey?.insulationLevel || '',
    productCategories: upsertResponse.calendarEvent.productCategories || '',
    currentTypeOfHeating: upsertResponse.productSurvey?.currentHeatingType || '',
    monthOfTheGivenBill: upsertResponse.productSurvey?.electricityBill?.month || '',
    monthlyElectricBill: upsertResponse.productSurvey?.electricityBill?.amount?.amount || '',
  };
};

const prepareSummaryObject = (upsertResponse: IApiUpsertResponse) => ({
  eventStatus: upsertResponse.calendarEvent.status || '',
  notes: upsertResponse.calendarEvent?.comment?.text || '',
});

const prepareAddressObject = (upsertResponse: IApiUpsertResponse) => ({
  region: upsertResponse.account.primaryAddress.region || '',
  street: upsertResponse.account.primaryAddress.street || '',
  zipCode: upsertResponse.account.primaryAddress.zipCode || '',
  district: upsertResponse.account.primaryAddress.district || '',
  houseNumber: upsertResponse.account.primaryAddress.houseNo || '',
  city: upsertResponse.account.primaryAddress.postOfficeCity || '',
});

export const transformApiContractToFormData = (
  upsertResponse: IApiUpsertResponse,
): IUpsertEventFormFields => {
  const address = prepareAddressObject(upsertResponse);
  const summary = prepareSummaryObject(upsertResponse);
  const general = prepareGeneralObject(upsertResponse);
  const productCategories = prepareProductCategoriesObject(upsertResponse);

  return {
    general,
    summary,
    address,
    productCategories,
  } as never;
};
