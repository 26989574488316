import { useAuth0 } from '@auth0/auth0-react';

import { GHOST_MODE_SUFFIX } from '@infrastructure';

export const useGhostMode = () => {
  const { user } = useAuth0();

  const isGhostMode = () => user?.name?.includes(GHOST_MODE_SUFFIX);

  const isGhostModeAccountId = (accountId: string | undefined) =>
    isGhostMode() && accountId === user?.sub;

  return {
    user,
    isGhostMode,
    isGhostModeAccountId,
  };
};
