import { useEffect } from 'react';

import { FullScreenLoading } from '@domain';

import { useHealthCheckActionQuery } from '@utils';

import { InternalServerErrorPage } from '../../pages';
import { IHealthCheckProviderProps } from './health-check-provider.types';

export const HealthCheckProvider = ({ children }: IHealthCheckProviderProps): JSX.Element => {
  const { isError, isLoading, mutate } = useHealthCheckActionQuery();

  useEffect(() => {
    mutate({});
  }, []);

  if (isLoading) {
    return <FullScreenLoading />;
  }

  if (isError) {
    return <InternalServerErrorPage />;
  }

  return <>{children}</>;
};
