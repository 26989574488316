import { isString } from '@amperio/amperio-ui';

import { removeSpacesFromString, removeSpecialCharactersFromString } from '@utils';

export const removePlusFromPhoneNumber = (phoneNumber: string | null | undefined) => {
  if (!isString(phoneNumber)) {
    return '';
  }

  const parsedPhoneNumber = removeSpacesFromString(removeSpecialCharactersFromString(phoneNumber));

  if (parsedPhoneNumber.startsWith('+')) {
    return parsedPhoneNumber.slice(1);
  }

  return parsedPhoneNumber;
};
