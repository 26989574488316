export default {
  // header
  'dashboard.accounts.header.call.button': 'Llamar',
  'dashboard.accounts.header.assign.to.button': 'Asignar a',
  'dashboard.accounts.header.add.event.button': 'Añadir evento',
  'dashboard.accounts.header.add.account.button': 'Añadir cuenta',

  // events table
  'dashboard.accounts.section.events.table.header': 'Consejero de ventas',
  'dashboard.accounts.section.events.table.date': 'Fecha',
  'dashboard.accounts.section.events.table.products': 'Productos',
  'dashboard.accounts.section.events.table.type': 'Tipo',
  'dashboard.accounts.section.events.table.status': 'Estatuto',
  'dashboard.accounts.section.events.table.notes': 'Cuaderno',

  // list
  'dashboard.accounts.list.name.column.label': 'Nombre completo',
  'dashboard.accounts.list.location.column.label': 'Ubicación',
  'dashboard.accounts.list.products.column.label': 'Productos',
  'dashboard.accounts.list.date.column.label': 'Fecha',
  'dashboard.accounts.list.status.column.label': 'Último estatuto',
};
