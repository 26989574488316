import { ELeadStatus } from '@enums';

export const getLeadStatusToTranslationsMapping = (
  translations: Record<string, string>,
): Record<string, string> => ({
  [ELeadStatus.NEW]: translations.commonLeadStatusNew,
  [ELeadStatus.INVALID]: translations.commonLeadStatusInvalid,
  [ELeadStatus.CALLBACK]: translations.commonLeadStatusCallback,
  [ELeadStatus.DUPLICATE]: translations.commonLeadStatusDuplicate,
  [ELeadStatus.INTERESTED]: translations.commonLeadStatusInterested,
  [ELeadStatus.RESIGNATION]: translations.commonLeadStatusResignation,
});
