import { AxiosResponse } from 'axios';

import { objectToQueryString, prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosCloudflareAuth0Instance } from '@infrastructure';

import { IGetInvitationsResponse } from './get-invitations.types';

const cloudflareApiKey = process.env.REACT_APP_CLOUDFLARE_AUTH0_API_KEY;

export const GET_INVITATIONS_ACTION_KEY = 'get-invitations-action-key';

export const getInvitationsAction =
  (organization: string, invitation: string) =>
  () =>
  // eslint-disable-next-line unicorn/consistent-function-scoping
  async (): Promise<AxiosResponse<IGetInvitationsResponse>> =>
    axiosCloudflareAuth0Instance.get(
      `/invitations${objectToQueryString({ organization, invitation })}`,
      prepareAxiosConfigWithAccessToken(cloudflareApiKey),
    );
