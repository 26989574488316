import { ArrowLeftIcon, Icon, Typography } from '@amperio/amperio-ui';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getTranslationKeyAndPathForHeaderFromUrl, useTranslation } from '@utils';

import './mobile-header.styles.scss';

export const MobileHeader = (): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const translation = useMemo(() => getTranslationKeyAndPathForHeaderFromUrl(pathname), [pathname]);
  const translations = useTranslation([`dashboard.header.${translation.path}`]);

  return (
    <div className="mobile-header-wrapper">
      <Icon
        pointer
        data-testid="mobile-header--back"
        fill="none"
        stroke="primary"
        onClick={() => navigate(-1)}
      >
        <ArrowLeftIcon />
      </Icon>
      <Typography strong as="h6">
        {translations[translation.key]}
      </Typography>
    </div>
  );
};
