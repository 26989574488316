export default {
  'dashboard.header.home': 'Home',
  'dashboard.header.create-lead': 'Create lead',

  // events
  'dashboard.header.events.list': 'Events',
  'dashboard.header.events.upsert-event': 'Events / Upsert event',
  'dashboard.header.events.upsert-account': 'Events / Upsert event / Upsert account',

  // accounts
  'dashboard.header.accounts.list': 'Accounts',
  'dashboard.header.accounts.upsert-account': 'Accounts / Upsert account',
  'dashboard.header.accounts.upsert-event': 'Accounts / Upsert account / Upsert event',

  // leads
  'dashboard.header.leads.list': 'Leads',
  'dashboard.header.leads.upsert-lead': 'Leads / Upsert lead',
  'dashboard.header.leads.upsert-account': 'Leads / Upsert lead / Upsert account',
  'dashboard.header.leads.upsert-event': 'Leads / Upsert lead / Upsert account / Upsert event',
};
