import * as pages from '@application/pages';

import { ERoutes } from '@enums';

import { IRoutingConfig } from './routing.types';

export const routingConfig: Record<ERoutes, IRoutingConfig> = {
  [ERoutes.LOGIN]: {
    Component: pages.LoginPage,
  },
  [ERoutes.FORBIDDEN]: {
    Component: pages.ForbiddenPage,
  },
  [ERoutes.NOT_FOUND]: {
    Component: pages.NotFoundPage,
  },
  [ERoutes.INTERNAL_SERVER_ERROR]: {
    Component: pages.InternalServerErrorPage,
  },
  [ERoutes.DASHBOARD]: {
    authorize: true,
    isDescendantWrapper: true,
    Component: pages.DashboardPage,
    targetRouteToMatch: ERoutes.DASHBOARD,
  },
  [ERoutes.ANY]: {
    Component: pages.NotFoundPage,
  },
};
