import { AxiosResponse } from 'axios';

import { objectToQueryString, prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosCloudflareAuth0Instance } from '@infrastructure';

const cloudflareApiKey = process.env.REACT_APP_CLOUDFLARE_AUTH0_API_KEY;

export const GET_INVITATION_FLOW_ACTION_KEY = 'get-invitation-flow-action-key';

export const getInvitationFlowAction =
  // eslint-disable-next-line unicorn/consistent-function-scoping
  (organization: string, invitation: string) => () => async (): Promise<AxiosResponse<string>> =>
    axiosCloudflareAuth0Instance.get(
      `/invitation-flow${objectToQueryString({ organization, invitation })}`,
      prepareAxiosConfigWithAccessToken(cloudflareApiKey),
    );
