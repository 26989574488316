import { useUserClaims } from '@utils';

import { HomeViewManager, HomeViewSeller } from './organisms';

import './home.styles.scss';

export const Home = (): JSX.Element => {
  const { isSalesManager, isSalesRepresentative } = useUserClaims();

  return (
    <div className="home-wrapper">
      {isSalesManager() && <HomeViewManager />}
      {isSalesRepresentative() && <HomeViewSeller />}
    </div>
  );
};
