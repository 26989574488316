import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { UpsertAccount, UpsertEvent } from '@ui';

import { objectToQueryString } from '@utils';

import { EDashboardAccountsRoutes, EDashboardRoutes, ERoutes } from '@enums';

import { AccountsList } from './organisms';

export const Accounts = (): JSX.Element => {
  const navigate = useNavigate();

  const onBackButtonClick = () => navigate(-1);

  // accounts list - actions
  const onAccountsListRowClick = (accountId: string) =>
    navigate(`${EDashboardAccountsRoutes.UPSERT_ACCOUNT}${objectToQueryString({ accountId })}`);

  const onAccountsListAddAccountButtonClick = () =>
    navigate(EDashboardAccountsRoutes.UPSERT_ACCOUNT);

  // upsert account - actions
  const onUpsertAccountAddEventClick = (accountId: string) =>
    navigate(`${EDashboardAccountsRoutes.UPSERT_EVENT}${objectToQueryString({ accountId })}`);

  const onEventsTableRowClick = (eventId: string) =>
    navigate(
      `/${ERoutes.DASHBOARD}/${EDashboardRoutes.ACCOUNTS}/${EDashboardAccountsRoutes.UPSERT_EVENT}?eventId=${eventId}`,
    );

  return (
    <Routes>
      <Route
        element={
          <AccountsList
            onAddNewAccountButtonClick={onAccountsListAddAccountButtonClick}
            onTableRowClick={onAccountsListRowClick}
          />
        }
        path={EDashboardAccountsRoutes.LIST}
      />
      <Route
        element={
          <UpsertAccount
            onAddEventClick={onUpsertAccountAddEventClick}
            onBackClick={onBackButtonClick}
            onEventsTableRowClick={onEventsTableRowClick}
          />
        }
        path={EDashboardAccountsRoutes.UPSERT_ACCOUNT}
      />
      <Route
        element={<UpsertEvent onBackClick={onBackButtonClick} />}
        path={EDashboardAccountsRoutes.UPSERT_EVENT}
      />
      <Route element={<Navigate replace to={ERoutes.NOT_FOUND} />} path={ERoutes.ANY} />
    </Routes>
  );
};
