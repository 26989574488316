export const translationStrings = [
  'dashboard.homepage.welcome.message',
  'dashboard.homepage.manager.alerts',
  'dashboard.homepage.manager.leads.go.to',
  'dashboard.homepage.manager.leads.leads',
  'dashboard.homepage.manager.alerts.absent',
  'dashboard.homepage.manager.leads.summary.leads',
  'dashboard.homepage.manager.leads.summary.meetings',

  'dashboard.homepage.manager.leads.description',
  'dashboard.homepage.manager.leads.summary.label',
  'dashboard.homepage.manager.leads.no.description',
  'dashboard.homepage.manager.alerts.missed.meetings',
] as const;
