import {
  DatePickerWithController,
  DropdownWithController,
  IconButton,
  Paper,
  PhoneInputWithController,
  ProfileIcon,
  TextInputWithController,
  Typography,
  useDeviceDetect,
} from '@amperio/amperio-ui';

import {
  useAccountsNamesActionQuery,
  useGhostMode,
  useLocale,
  useTranslation,
  useUserClaims,
  useUsersActionQuery,
} from '@utils';

import { DEFAULT_PHONE_INPUT_COUNTRY, PHONE_INPUT_ONLY_COUNTRIES } from '@infrastructure';

import {
  getEventTypeDropdownOptions,
  meetingHourDropdownOptions,
  meetingLengthDropdownOptions,
  translationStrings,
} from './general-form.defaults';
import { IGeneralFormProps } from './general-form.types';

import './general-form.styles.scss';

export const GeneralForm = ({
  control,
  disableProfileIcon,
  isEditMode,
  isNewEvent,
  onProfileIconClick,
  setError,
}: IGeneralFormProps): JSX.Element => {
  const { locale } = useLocale();
  const { deviceData } = useDeviceDetect();
  const { isGhostMode } = useGhostMode();
  const { isSalesRepresentative } = useUserClaims();
  const translations = useTranslation(translationStrings);

  const { isUsersDataLoading, ownersDropdownOptions } = useUsersActionQuery();
  const { customersDropdownOptions, isAccountsNamesDataLoading } = useAccountsNamesActionQuery();

  const eventTypeDropdownOptions = getEventTypeDropdownOptions(translations);

  return (
    <Paper className="general-form-wrapper" padding={deviceData.isMobile ? 'sm' : 'md'}>
      <div className="general-form-wrapper__header">
        <Typography strong as={deviceData.isMobile ? 'h3' : 'h2'} display="inline-block">
          {translations.formGeneralHeader}
        </Typography>
        {!disableProfileIcon && !isEditMode && (
          <IconButton
            background="primary"
            fill="none"
            padding="xs"
            stroke="white"
            onClick={onProfileIconClick}
          >
            <ProfileIcon />
          </IconButton>
        )}
      </div>
      <div className="general-form-wrapper__form">
        <DropdownWithController
          controllerProps={{
            control,
            name: 'general.customer',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
          dropdownProps={{
            options: customersDropdownOptions,
            labelId: 'customers-dropdown-label',
            label: translations.formGeneralCustomerLabel,
          }}
          formControlProps={{
            disabled: isAccountsNamesDataLoading || !isEditMode,
          }}
        />
        <PhoneInputWithController
          controllerProps={{
            control,
            name: 'general.phoneNumber',
          }}
          emptyValueErrorMessage={translations.commonFormFieldErrorRequired}
          phoneInputProps={{
            inputProps: {
              disabled: true,
              variant: 'standard',
              label: translations.formGeneralPhoneNumberLabel,
            },
            jumpCursorToEnd: true,
            disableDropdown: true,
            countryCodeEditable: false,
            country: DEFAULT_PHONE_INPUT_COUNTRY,
            onlyCountries: PHONE_INPUT_ONLY_COUNTRIES,
          }}
          setError={setError}
        />
        <DropdownWithController
          controllerProps={{
            control,
            name: 'general.eventType',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
          dropdownProps={{
            options: eventTypeDropdownOptions,
            labelId: 'event-type-dropdown-label',
            label: translations.formGeneralEventTypeLabel,
          }}
          formControlProps={{
            disabled: isSalesRepresentative() || isUsersDataLoading || !isEditMode,
          }}
        />
        <DropdownWithController
          controllerProps={{
            control,
            name: 'general.owner',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
          dropdownProps={{
            options: ownersDropdownOptions,
            labelId: 'owners-dropdown-label',
            label: translations.formGeneralOwnerLabel,
          }}
          formControlProps={{
            disabled: isSalesRepresentative() || isUsersDataLoading || !isEditMode,
          }}
        />
        <DatePickerWithController
          componentProps={{
            locale,
            disabled: !isEditMode,
            okText: translations.commonButtonsOk,
            minDate: isNewEvent ? new Date() : null,
            clearText: translations.commonButtonsClear,
            cancelText: translations.commonButtonsCancel,
            todayText: translations.commonDatePickerToday,
            toolbarTitle: translations.commonDatePickerSelectedDate,
          }}
          controllerProps={{
            control,
            name: 'general.date',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
          label={translations.formGeneralSelectMeetingDateLabel}
        />
        <DropdownWithController
          controllerProps={{
            control,
            name: 'general.meetingHour',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
          dropdownProps={{
            options: meetingHourDropdownOptions,
            labelId: 'meeting-hour-dropdown-label',
            label: translations.formGeneralSelectMeetingHourLabel,
          }}
          formControlProps={{
            disabled: !isEditMode,
          }}
        />
        <DropdownWithController
          controllerProps={{
            control,
            name: 'general.meetingLengthInMinutes',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
          dropdownProps={{
            options: meetingLengthDropdownOptions,
            labelId: 'meeting-length-dropdown-label',
            label: translations.formGeneralSelectMeetingLengthLabel,
          }}
          formControlProps={{
            disabled: !isEditMode,
          }}
        />
        {isGhostMode() && isNewEvent && (
          <TextInputWithController
            controllerProps={{
              control,
              name: 'general.entryId',
              rules: {
                required: {
                  value: true,
                  message: 'Field is required',
                },
              },
            }}
            inputProps={{
              label: 'EntryId',
              disabled: !isEditMode,
              autoComplete: 'general.entryId',
            }}
          />
        )}
      </div>
    </Paper>
  );
};
