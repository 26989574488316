export const translationStrings = [
  'common.prompt.something.went.wrong',
  'error.page.message.account.already.exist',
  'error.page.message.no.roles',
  'error.page.message.lead.access.error',
  'error.page.message.account.no.required.data',
  'error.page.message.account.form.invalid.format',
  'error.page.message.account.already.exist',
  'error.page.message.account.access.error',
  'error.page.message.event.no.required.data',
  'error.page.message.event.form.invalid.format',
  'error.page.message.event.access.error',
] as const;
