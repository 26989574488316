import {
  buildApiUrl,
  generateFilterQueryParam,
  generateSortQueryParam,
  prepareAxiosConfigWithAccessToken,
} from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IGetUnhandledEventsAttributes } from './get-unhandled-events.types';

export const getUnhandledEventsAction =
  (getAccessToken: TGetAccessToken, userId: string | undefined) =>
  async ({ filters, sorts, ...restProps }: IGetUnhandledEventsAttributes) => {
    const accessToken = await getAccessToken();

    return axiosInstance.get(
      buildApiUrl(EApiEndpoint.CALENDAR_EVENTS_UNHANDLED_EVENTS, {
        userId,
        ...restProps,
        sort: generateSortQueryParam(sorts),
        filter: generateFilterQueryParam(filters),
      }),
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
