import { History } from 'history';
import { useMemo } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import { IRouteAdapterProps } from './route-adapter.types';

export const RouteAdapter = ({ children }: IRouteAdapterProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      replace(location: Location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: Location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate],
  ) as History;

  return children({ history: adaptedHistory, location });
};
