import { AxiosResponse } from 'axios';

import {
  buildApiUrl,
  generateFilterQueryParam,
  generateSortQueryParam,
  prepareAxiosConfigWithAccessToken,
} from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IApiResponseWithPagination } from '@interfaces';

import { IGetAccountsTableDataAttributes } from './get-accounts-table-data.types';

export const getAccountsTableDataAction =
  (getAccessToken: TGetAccessToken, userId: string | undefined) =>
  async ({
    filters,
    sorts,
    ...restProps
  }: IGetAccountsTableDataAttributes): Promise<
    AxiosResponse<IApiResponseWithPagination<Record<string, string>[]>>
  > => {
    const accessToken = await getAccessToken();

    return axiosInstance.get(
      buildApiUrl(EApiEndpoint.ACCOUNTS_OVERVIEW, {
        userId,
        ...restProps,
        sort: generateSortQueryParam(sorts),
        filter: generateFilterQueryParam(filters),
      }),
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
