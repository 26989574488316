export default {
  // success - lead
  'notifications.success.lead.assigned':
    'El cliente potencial ha sido asignado a un asesor de ventas',
  'notifications.success.lead.changes.saved':
    'Se han guardado los cambios en los datos de la pista',

  // success - account
  'notifications.success.account.created': 'Se ha creado una nueva cuenta de usuario',
  'notifications.success.account.changes.saved': 'Se han guardado los cambios en la cuenta',

  // success - absence
  'notifications.success.absence.set': 'La ausencia se ha fijado',
  'notifications.success.absence.delete': 'La ausencia ha sido cancelada',

  // success - event
  'notifications.success.event.created': 'Se ha añadido un nuevo evento al calendario',
  'notifications.success.event.details.changes.saved': 'Se han actualizado los detalles del evento',

  // success - general
  'notifications.success.general.changes.saved': 'Se han guardado todos los cambios',
};
