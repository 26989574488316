import { Chip, Typography } from '@amperio/amperio-ui';
import { useAuth0 } from '@auth0/auth0-react';
import { lightFormat } from 'date-fns';

import { getLeadStatusToTranslationsMapping, useMutation, useTranslation } from '@utils';

import { leadStatusToChipColorMapping } from '@infrastructure';

import { getLeadsTableDataAction } from '../../api';
import { LeadsTable } from '../../molecules';
import { translationStrings } from './leads-list-seller.defaults';
import { ILeadsListSellerProps } from './leads-list-seller.types';

import './leads-list-seller.styles.scss';

export const LeadsListSeller = ({ onTableRowClick }: ILeadsListSellerProps): JSX.Element => {
  const { user } = useAuth0();
  const translations = useTranslation(translationStrings);

  const { isLoading, mutateAsync } = useMutation(getLeadsTableDataAction);

  const leadStatusToTranslationsMapping = getLeadStatusToTranslationsMapping(translations);

  return (
    <div className="leads-list-seller-wrapper">
      <LeadsTable
        columns={[
          {
            minWidth: 250,
            field: 'name',
            sortable: true,
            filterOptions: {
              operator: 'like',
              type: 'search-input',
            },
            headerName: translations.dashboardLeadsListNameColumnLabel,
            renderBodyCell: (value: string) => (
              <Typography ellipsis strong as="span" maxWidth={250}>
                {value || '-'}
              </Typography>
            ),
          },
          {
            minWidth: 150,
            sortable: true,
            field: 'status',
            filterOptions: {
              operator: 'eq',
              type: 'radio-group',
              radioGroupOptions: [
                leadStatusToTranslationsMapping.new,
                leadStatusToTranslationsMapping.invalid,
                leadStatusToTranslationsMapping.callback,
                leadStatusToTranslationsMapping.duplicate,
                leadStatusToTranslationsMapping.interested,
                leadStatusToTranslationsMapping.resignation,
              ],
            },
            headerName: translations.dashboardLeadsListStatusColumnLabel,
            renderBodyCell: (value: string) => {
              const label = leadStatusToTranslationsMapping[value];

              if (!label) {
                return <span>-</span>;
              }

              return (
                <Chip color={leadStatusToChipColorMapping[value]} label={label} size="small" />
              );
            },
          },
          {
            minWidth: 150,
            field: 'modifiedOn',
            headerName: translations.dashboardLeadsListDateColumnLabel,
            renderBodyCell: (value) => {
              if (!value) {
                return <span>-</span>;
              }

              return <Typography as="span">{lightFormat(new Date(value), 'dd/MM/yyy')}</Typography>;
            },
          },
        ]}
        getDataFromServer={async ({ filters, page, rowsPerPage, sorts }) => {
          const { data } = await mutateAsync({
            sorts,
            filters,
            pageNumber: page,
            pageSize: rowsPerPage,
            userId: user?.sub || '',
          });

          return { records: data.data, recordsCount: data.totalCount };
        }}
        isLoading={isLoading}
        noDataMessage={translations.commonTableNoData}
        onRowClick={onTableRowClick}
      />
    </div>
  );
};
