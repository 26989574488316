import {
  ArrowLeftIcon,
  Button,
  Fab,
  FloatingActionMenu,
  Icon,
  PlusIcon,
  Typography,
  useDeviceDetect,
} from '@amperio/amperio-ui';
import { memo, useState } from 'react';

import { addPlusToPhoneNumber } from '@utils';

import { getFloatingActionMenuOptions } from './upsert-event-header.defaults';
import { IUpsertEventHeaderProps } from './upsert-event-header.types';

import './upsert-event-header.styles.scss';

const UpsertEventHeaderComponent = ({
  handleProfileIconClick,
  isCreateEventLoading,
  isDirtyForm,
  isEditMode,
  isGetCalendarEventByIdLoading,
  isUpdateEventLoading,
  onBackClick,
  onCancelClick,
  phoneNumberFieldValue,
  shouldRenderFloatingActionButton,
  toggleEditMode,
  translations,
}: IUpsertEventHeaderProps): JSX.Element => {
  const [isFloatingMenuOpen, setIsFloatingMenuOpen] = useState(false);

  const { deviceData } = useDeviceDetect();

  const toggleIsFloatingMenuOpen = () => setIsFloatingMenuOpen((_prev) => !_prev);

  const handleCallIconClick = () => {
    toggleIsFloatingMenuOpen();

    const parsedPhoneNumber = addPlusToPhoneNumber(phoneNumberFieldValue);

    window.open(`tel:${parsedPhoneNumber}`);
  };

  const handleAccountDetailsIconClick = () => {
    toggleIsFloatingMenuOpen();
    handleProfileIconClick();
  };

  const floatingActionMenuOptions = getFloatingActionMenuOptions(
    translations,
    handleCallIconClick,
    handleAccountDetailsIconClick,
  );

  return (
    <header className="upsert-event-header-wrapper">
      {!deviceData.isMobile ? (
        <>
          <Button
            data-testid="upsert-event--button-back"
            startIcon={<ArrowLeftIcon />}
            variant="outlined"
            onClick={onBackClick}
          >
            {translations.commonButtonsBack}
          </Button>
          {!isEditMode ? (
            <Button
              data-testid="upsert-event--button-edit"
              isLoading={isGetCalendarEventByIdLoading}
              variant="contained"
              onClick={toggleEditMode}
            >
              {translations.commonButtonsEdit}
            </Button>
          ) : (
            <span>
              <Button
                data-testid="upsert-event--button-cancel"
                disabled={!isEditMode || isCreateEventLoading || isUpdateEventLoading}
                variant="contained"
                onClick={onCancelClick}
              >
                {translations.commonButtonsCancel}
              </Button>
              <Button
                data-testid="upsert-event--button-save"
                disabled={!isEditMode || !isDirtyForm}
                isLoading={isCreateEventLoading || isUpdateEventLoading}
                type="submit"
                variant="contained"
              >
                {translations.commonButtonsSave}
              </Button>
            </span>
          )}
        </>
      ) : null}
      {deviceData.isMobile && isDirtyForm ? (
        <Fab
          data-testid="upsert-event--button-add"
          disabled={isGetCalendarEventByIdLoading || isCreateEventLoading || isUpdateEventLoading}
          type="submit"
          variant="extended"
        >
          <Icon stroke="white">
            <PlusIcon />
          </Icon>
          <Typography strong as="span" color="white">
            {translations.commonButtonsSave}
          </Typography>
        </Fab>
      ) : null}
      {shouldRenderFloatingActionButton && (
        <FloatingActionMenu
          withBackdrop
          actions={floatingActionMenuOptions}
          ariaLabel="floating-menu-test"
          open={isFloatingMenuOpen}
          speedDialProps={{
            onOpen: toggleIsFloatingMenuOpen,
            onClose: toggleIsFloatingMenuOpen,
          }}
        />
      )}
    </header>
  );
};

export const UpsertEventHeader = memo(UpsertEventHeaderComponent);
