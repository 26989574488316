import { AxiosResponse } from 'axios';

import { prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IApiAccountsNames } from '@interfaces';

export const GET_ACCOUNTS_NAMES_ACTION_KEY = 'get-accounts-names-action-key';

export const getAccountsNamesAction =
  (getAccessToken: TGetAccessToken) => async (): Promise<AxiosResponse<IApiAccountsNames[]>> => {
    const accessToken = await getAccessToken();

    return axiosInstance.get(
      EApiEndpoint.ACCOUNTS_NAMES,
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
