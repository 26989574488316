export default {
  // FORBIDDEN
  'error.page.forbidden.message': 'Prohibido',
  'error.page.forbidden.back.to.homepage.button': 'Volver a la página de inicio',

  // NOT_FOUND
  'error.page.not.found.message': 'Página no encontrada',
  'error.page.not.found.back.to.homepage.button': 'Volver a la página de inicio',

  // INTERNAL_SERVER_ERROR
  'error.page.internal.server.error.back.to.homepage.button': 'Volver a la página de inicio',
  'error.page.internal.server.error.message':
    'Error interno del servidor. Vuelva a intentarlo en un momento',

  // messages
  'error.page.message.access.error': "No tiene acceso a '[apiUrl]'",
  'error.page.message.no.roles': 'El usuario no tiene asignada ninguna función',
  'error.page.message.lead.access.error': 'No hay acceso a las pistas elegidas',
  'error.page.message.account.no.required.data':
    'Faltan algunos campos obligatorios. No se pueden guardar los cambios',
  'error.page.message.account.form.invalid.format':
    'Formato inválido. No se pueden guardar los cambios',
  'error.page.message.account.already.exist':
    'La cuenta vinculada a este número de teléfono ya existe',
  'error.page.message.account.access.error': 'Falta de acceso a las cuentas seleccionadas',
  'error.page.message.event.no.required.data':
    'Faltan algunos campos obligatorios. No se pueden guardar los cambios',
  'error.page.message.event.form.invalid.format':
    'Formato de campo incorrecto. No se pueden guardar los cambios',
  'error.page.message.event.access.error': 'No hay acceso a los eventos seleccionados',
};
