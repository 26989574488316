import { isArray, isFunction, isNumber, isObject, isString } from '@amperio/amperio-ui';

export const removeUnnecessaryValuesFromObject = (value: unknown) => {
  if (!isObject(value)) {
    return null;
  }

  const keys = Object.keys(value);

  if (keys.length === 0) {
    return null;
  }

  const result: Record<string, unknown> = {};

  for (const _key of keys) {
    const currentValue = (value as Record<string, unknown>)[_key];

    if (isArray(currentValue) && currentValue.length > 0) {
      result[_key] = currentValue;
    }

    if (
      isNumber(currentValue) ||
      (isString(currentValue) && currentValue !== '') ||
      isFunction(currentValue)
    ) {
      result[_key] = currentValue;
    }

    if (isObject(currentValue)) {
      const currentValueResult = removeUnnecessaryValuesFromObject(currentValue);

      if (currentValueResult && Object.keys(currentValueResult).length > 0) {
        result[_key] = currentValueResult;
      }
    }
  }

  return result;
};
