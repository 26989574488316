import {
  Paper,
  PhoneInputWithController,
  TextInputWithController,
  Typography,
  useDeviceDetect,
} from '@amperio/amperio-ui';

import { useTranslation } from '@utils';
import { containEntersOrSpaces, startsOrEndsWithWhitespace } from '@utils/react-hook-form';

import { DEFAULT_PHONE_INPUT_COUNTRY, PHONE_INPUT_ONLY_COUNTRIES } from '@infrastructure';

import { translationStrings } from './account-form.defaults';
import { IAccountFormProps } from './account-form.types';

import './account-form.styles.scss';

export const AccountForm = ({ control, isEditMode, setError }: IAccountFormProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  return (
    <Paper className="account-form-wrapper" padding={deviceData.isMobile ? 'sm' : 'md'}>
      <Typography strong as={deviceData.isMobile ? 'h3' : 'h2'}>
        {translations.formAccountHeader}
      </Typography>
      <div className="account-form-wrapper__form">
        <TextInputWithController
          controllerProps={{
            control,
            name: 'account.fullName',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
              maxLength: {
                value: 100,
                message: translations.formAccountNameMaxLengthError,
              },
              validate: {
                startsOrEndsWithWhitespace: startsOrEndsWithWhitespace(
                  translations.commonFormFieldErrorStartsOrEndsWithWhitespace,
                ),
                containEntersOrSpaces: containEntersOrSpaces(
                  translations.commonFormFieldErrorContainEntersOrSpaces,
                ),
              },
            },
          }}
          inputProps={{
            disabled: !isEditMode,
            autoComplete: 'account.fullName',
            label: translations.formAccountNameLabel,
          }}
        />
        <PhoneInputWithController
          controllerProps={{
            control,
            name: 'account.phoneNumber',
          }}
          emptyValueErrorMessage={translations.commonFormFieldErrorRequired}
          invalidPhoneNumberErrorMessage={translations.formAccountPhoneNumberPatternMatchError}
          phoneInputProps={{
            inputProps: {
              variant: 'standard',
              disabled: !isEditMode,
              label: translations.formAccountPhoneNumberLabel,
            },
            jumpCursorToEnd: true,
            countryCodeEditable: false,
            disableDropdown: !isEditMode,
            country: DEFAULT_PHONE_INPUT_COUNTRY,
            onlyCountries: PHONE_INPUT_ONLY_COUNTRIES,
          }}
          setError={setError}
        />
        <TextInputWithController
          controllerProps={{
            control,
            name: 'account.email',
            rules: {
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: translations.formAccountEmailPatternMatchError,
              },
              maxLength: {
                value: 100,
                message: translations.formAccountEmailMaxLengthError,
              },
            },
          }}
          inputProps={{
            disabled: !isEditMode,
            autoComplete: 'account.email',
            label: translations.formAccountEmailLabel,
          }}
        />
      </div>
    </Paper>
  );
};
