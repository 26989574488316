export default {
  // list
  'dashboard.events.list.header.calendar.switch': 'Fines de semana',
  'dashboard.events.list.header.add.event.button': 'Añadir evento',
  'dashboard.events.list.header.set.absence.button': 'Establecer ausencia',
  'dashboard.events.list.header.unset.absence.button': 'Ausencia no establecida',
  'dashboard.events.list.header.select.seller': 'Seleccionar Asesor de ventas',

  // upsert event
  'dashboard.events.upsert.event.floating.action.menu.call': 'Llamar',
  'dashboard.events.upsert.event.floating.action.menu.account.details': 'Cuenta',
};
