import { capitalize } from '@amperio/amperio-ui';

import { EMonth } from '@enums';

const monthNameByMonthIdMapper: Record<number, EMonth> = {
  0: EMonth.JANUARY,
  1: EMonth.FEBRUARY,
  2: EMonth.MARCH,
  3: EMonth.APRIL,
  4: EMonth.MAY,
  5: EMonth.JUNE,
  6: EMonth.JULY,
  7: EMonth.AUGUST,
  8: EMonth.SEPTEMBER,
  9: EMonth.OCTOBER,
  10: EMonth.NOVEMBER,
  11: EMonth.DECEMBER,
};

export const getMonthTranslationKeyByMonthId = (monthId: number) => {
  const absMonthId = Math.abs(monthId);
  const monthName = monthNameByMonthIdMapper[absMonthId] || EMonth.JANUARY;
  const capitalizedMonthName = capitalize(monthName);

  return `commonMonth${capitalizedMonthName}`;
};
