import {
  DropdownWithController,
  Paper,
  PhoneInputWithController,
  TextInputWithController,
  Typography,
  ZIPCODE_SPAIN_REGEX,
  useDeviceDetect,
} from '@amperio/amperio-ui';

import { containEntersOrSpaces, startsOrEndsWithWhitespace } from '@utils/react-hook-form';

import { DEFAULT_PHONE_INPUT_COUNTRY, PHONE_INPUT_ONLY_COUNTRIES } from '@infrastructure';

import {
  countryDropdownOptions,
  productCategoriesDropdownOptions,
} from './create-lead-form.defaults';
import { ICreateLeadFormProps } from './create-lead-form.types';

import './create-lead-form.styles.scss';

export const CreateLeadForm = ({
  control,
  isEditMode,
  setError,
}: ICreateLeadFormProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();

  return (
    <Paper className="create-lead-form-wrapper" padding={deviceData.isMobile ? 'sm' : 'md'}>
      <Typography strong as={deviceData.isMobile ? 'h3' : 'h2'}>
        Lead
      </Typography>
      <div className="create-lead-form-wrapper__form">
        <TextInputWithController
          controllerProps={{
            control,
            name: 'name',
            rules: {
              maxLength: {
                value: 100,
                message: 'Maximum length is 100',
              },
              validate: {
                startsOrEndsWithWhitespace: startsOrEndsWithWhitespace(
                  'The field cannot begin or end with a space',
                ),
                containEntersOrSpaces: containEntersOrSpaces(
                  'The field cannot contain blank lines or contain double spaces',
                ),
              },
            },
          }}
          inputProps={{
            label: 'Full name',
            disabled: !isEditMode,
            autoComplete: 'fullName',
          }}
        />
        <PhoneInputWithController
          controllerProps={{
            control,
            name: 'phoneNumber',
            rules: {
              required: true,
            },
          }}
          emptyValueErrorMessage="Field is required"
          invalidPhoneNumberErrorMessage="Incorrect phone number format"
          phoneInputProps={{
            inputProps: {
              variant: 'standard',
              label: 'Phone number',
              disabled: !isEditMode,
            },
            jumpCursorToEnd: true,
            countryCodeEditable: false,
            disableDropdown: !isEditMode,
            country: DEFAULT_PHONE_INPUT_COUNTRY,
            onlyCountries: PHONE_INPUT_ONLY_COUNTRIES,
          }}
          setError={setError}
        />
        <TextInputWithController
          controllerProps={{
            control,
            name: 'zipCode',
            rules: {
              pattern: {
                value: ZIPCODE_SPAIN_REGEX,
                message: 'Incorrect zipcode format',
              },
            },
          }}
          inputProps={{
            label: 'Zip code',
            disabled: !isEditMode,
            autoComplete: 'zipCode',
            inputProps: { inputMode: 'numeric' },
          }}
        />
        <DropdownWithController
          controllerProps={{
            control,
            name: 'products',
          }}
          dropdownProps={{
            multiple: true,
            label: 'Products',
            options: productCategoriesDropdownOptions,
            labelId: 'product-categories-dropdown-label',
          }}
          formControlProps={{
            disabled: !isEditMode,
          }}
        />
        <DropdownWithController
          controllerProps={{
            control,
            name: 'country',
            rules: {
              required: {
                value: true,
                message: 'Field is required',
              },
            },
          }}
          dropdownProps={{
            label: 'Country',
            options: countryDropdownOptions,
            labelId: 'country-dropdown-label',
          }}
          formControlProps={{
            disabled: !isEditMode,
          }}
        />
        <TextInputWithController
          controllerProps={{
            control,
            name: 'entryId',
            rules: {
              required: {
                value: true,
                message: 'Field is required',
              },
            },
          }}
          inputProps={{
            label: 'EntryId',
            disabled: !isEditMode,
            autoComplete: 'entryId',
          }}
        />
      </div>
    </Paper>
  );
};
