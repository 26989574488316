import {
  MEDIUM_SCREEN_BREAKPOINT,
  isArray,
  toast,
  useDeviceDetect,
  useLocalStorage,
} from '@amperio/amperio-ui';
import { addMonths, addWeeks, sub, subMonths, subWeeks } from 'date-fns';
import { useEffect, useState } from 'react';

import { MinimalCalendarWrapper } from '@ui';

import {
  getMonthTranslationKeyByMonthId,
  transformCalendarApiResponse,
  useAccountsNamesActionQuery,
  useAxiosError,
  useGhostMode,
  useMutation,
  useTranslation,
  useUsersActionQuery,
} from '@utils';

import {
  EVENTS_MANAGER_SELECTED_USER_ID_KEY,
  EVENTS_SHOULD_RENDER_WEEKENDS_KEY,
} from '@infrastructure';

import { deleteAbsencesAction, getCalendarEventsAction, setAbsencesAction } from '../../api';
import { EventsViewManagerHeader } from '../../molecules';
import { includeDateInArray } from '../../utils';
import { translationStrings } from './events-view-manager.defaults';
import { IEventsViewManagerProps } from './events-view-manager.types';

import './events-view-manager.styles.scss';

export const EventsViewManager = ({
  onAddNewEventButtonClick,
  onCalendarEventClick,
}: IEventsViewManagerProps): JSX.Element => {
  const { isGhostModeAccountId, user } = useGhostMode();
  const { findCustomerById, usersData } = useAccountsNamesActionQuery();
  const { findUserById, isUsersDataLoading, ownersDropdownOptions } = useUsersActionQuery();

  const { windowSize } = useDeviceDetect();
  const translations = useTranslation(translationStrings);
  const { receiveErrorMessageFromError } = useAxiosError();

  const [absenceData, setAbsenceData] = useState<Date[]>([]);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [isAbsenceSelected, setIsAbsenceSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isWorkingDaySelected, setIsWorkingDaySelected] = useState(false);

  const [selectedUserId, setSelectedUserId] = useLocalStorage(
    EVENTS_MANAGER_SELECTED_USER_ID_KEY,
    '',
  );
  const [shouldRenderWeekends, setShouldRenderWeekends] = useLocalStorage(
    EVENTS_SHOULD_RENDER_WEEKENDS_KEY,
    true,
  );

  const {
    data: calendarEventsData,
    isLoading: isGetCalendarEventsLoading,
    mutate: mutateCalendarData,
    mutateAsync: mutateAsyncGetCalendarEvents,
  } = useMutation(getCalendarEventsAction);
  const { isLoading: isSetAbsencesLoading, mutateAsync: mutateAsyncSetAbsences } =
    useMutation(setAbsencesAction);

  const { isLoading: isDeleteAbsencesLoading, mutateAsync: mutateAsyncDeleteAbsences } =
    useMutation(deleteAbsencesAction);

  const monthNameTranslationKey =
    selectedDate && getMonthTranslationKeyByMonthId(selectedDate.getMonth());

  const transformedCalendarData = transformCalendarApiResponse(
    calendarEventsData?.data,
    findCustomerById,
    translations.calendarReservationTitle,
    !usersData?.length,
  );

  useEffect(() => {
    if (
      !user?.sub ||
      selectedUserId ||
      isGhostModeAccountId(user?.sub) ||
      ownersDropdownOptions.length === 0
    ) {
      return;
    }

    const foundUser = findUserById(user?.sub);

    if (!foundUser) {
      return;
    }

    setSelectedUserId(foundUser?.id);
  }, [ownersDropdownOptions.length]);

  useEffect(() => {
    if (!calendarEventsData) {
      return;
    }

    setAbsenceData(transformedCalendarData.absenceData);
  }, [JSON.stringify(transformedCalendarData)]);

  useEffect(() => {
    if (!selectedDate || !selectedUserId) {
      return;
    }

    setAbsenceData([]);
    mutateCalendarData({ selectedDate, userId: selectedUserId });
  }, [selectedUserId, monthNameTranslationKey]);

  useEffect(() => {
    if (selectedDates.length === 0) {
      setIsAbsenceSelected(false);
      setIsWorkingDaySelected(false);

      return;
    }

    const absenceDay = selectedDates.every(includeDateInArray(absenceData));
    const workingDay = selectedDates.every((element) => !includeDateInArray(absenceData)(element));

    if (isAbsenceSelected !== absenceDay) {
      setIsAbsenceSelected(absenceDay);
    }

    if (isWorkingDaySelected !== workingDay) {
      setIsWorkingDaySelected(workingDay);
    }
  }, [selectedDates]);

  const isCalendarOneWeekMode = windowSize.width < MEDIUM_SCREEN_BREAKPOINT;

  const onArrowRightClick = () => {
    const functionToExecute = isCalendarOneWeekMode ? addWeeks : addMonths;
    const newSelectedDate = functionToExecute(selectedDate, 1);

    setSelectedDate(newSelectedDate);
  };

  const onArrowLeftClick = () => {
    const functionToExecute = isCalendarOneWeekMode ? subWeeks : subMonths;
    const newSelectedDate = functionToExecute(selectedDate, 1);

    setSelectedDate(newSelectedDate);
  };

  const onAddEventClick = () => {
    const dateFromCalendar = selectedDates[0];
    const selectedEventDate = dateFromCalendar ? new Date(dateFromCalendar) : new Date();

    onAddNewEventButtonClick(selectedEventDate, selectedUserId || '');
  };

  const onSetAbsenceClick = async (dates?: Date[]) => {
    const absencesToSet = (isArray(dates) && dates) || selectedDates;

    mutateAsyncSetAbsences({ selectedDates: absencesToSet, userId: selectedUserId })
      .then(() => {
        setIsWorkingDaySelected(false);
        setAbsenceData([...absenceData, ...absencesToSet]);
        setSelectedDates([]);

        toast.success(translations.notificationsSuccessAbsenceSet);
      })
      .catch((error) => toast.error(receiveErrorMessageFromError(error)));
  };

  const onUnsetAbsenceClick = async (dates?: Date[]) => {
    if (!selectedDate) {
      return;
    }

    const absencesToDelete = (isArray(dates) && dates) || selectedDates;
    const newCalendarEventsData = await mutateAsyncGetCalendarEvents({
      selectedDate,
      userId: selectedUserId,
    });

    const absencesToDeleteIds = newCalendarEventsData.data.absences
      .filter((absence) => includeDateInArray(absencesToDelete)(absence.start))
      .map((absence) => absence.id);

    mutateAsyncDeleteAbsences(absencesToDeleteIds)
      .then(() => {
        setIsAbsenceSelected(false);
        setAbsenceData(
          absenceData.filter((absence) => !includeDateInArray(absencesToDelete)(absence)),
        );

        setSelectedDates([]);

        toast.success(translations.notificationsSuccessAbsenceDelete);
      })
      .catch((error) => toast.error(receiveErrorMessageFromError(error)));
  };

  const onWeekendsSwitchClick = () => setShouldRenderWeekends(!shouldRenderWeekends);

  const shouldDisableAddEventButton =
    !selectedUserId ||
    isSetAbsencesLoading ||
    isDeleteAbsencesLoading ||
    selectedDates.length > 1 ||
    selectedDates.some((date) => sub(new Date(date), { days: -1 }) < new Date());

  return (
    <div className="events-view-manager-wrapper">
      <EventsViewManagerHeader
        disableAddEventButton={shouldDisableAddEventButton}
        isAbsenceSelected={isAbsenceSelected}
        isWorkingDaySelected={isWorkingDaySelected}
        monthNameTranslationKey={monthNameTranslationKey}
        selectedDate={selectedDate}
        selectedUserId={selectedUserId}
        sellerDropdownOptions={ownersDropdownOptions}
        setSelectedUserId={setSelectedUserId}
        shouldRenderWeekends={shouldRenderWeekends}
        translations={translations}
        onAddEventClick={onAddEventClick}
        onArrowLeftClick={onArrowLeftClick}
        onArrowRightClick={onArrowRightClick}
        onSetAbsenceClick={onSetAbsenceClick}
        onUnsetAbsenceClick={onUnsetAbsenceClick}
        onWeekendsSwitchClick={onWeekendsSwitchClick}
      />
      <MinimalCalendarWrapper
        absences={absenceData}
        data-testid="event-view--calendar"
        displayWeekends={shouldRenderWeekends}
        events={transformedCalendarData.calendarEvents}
        isLoading={isUsersDataLoading || isGetCalendarEventsLoading}
        selectedDate={selectedDate}
        selectedDates={selectedDates}
        setAbsenceData={onSetAbsenceClick}
        unsetAbsenceData={onUnsetAbsenceClick}
        onEventClick={onCalendarEventClick}
        onSelectedDatesChange={setSelectedDates}
      />
    </div>
  );
};
