import { AxiosResponse } from 'axios';

import { prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IApiAbsence } from '@interfaces';

import { IAssignUserToLeadsAttributes } from './assign-user-to-leads.types';

export const assignUserToLeadsAction =
  (getAccessToken: TGetAccessToken) =>
  async ({
    leadsIds,
    userId,
  }: IAssignUserToLeadsAttributes): Promise<AxiosResponse<IApiAbsence[]>> => {
    const accessToken = await getAccessToken();

    return axiosInstance.patch(
      EApiEndpoint.LEADS_USER_ASSIGNMENTS,
      { userId, leadsIds },
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
