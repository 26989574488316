import {
  DropdownWithController,
  Paper,
  PhoneInputWithController,
  TextInputWithController,
  Typography,
  useDeviceDetect,
} from '@amperio/amperio-ui';

import { useTranslation } from '@utils';
import { containEntersOrSpaces, startsOrEndsWithWhitespace } from '@utils/react-hook-form';

import { DEFAULT_PHONE_INPUT_COUNTRY, PHONE_INPUT_ONLY_COUNTRIES } from '@infrastructure';

import { getLeadStatusDropdownOptions, translationStrings } from './leads-form.defaults';
import { ILeadsFormProps } from './leads-form.types';

import './leads-form.styles.scss';

export const LeadsForm = ({ control, isEditMode, setError }: ILeadsFormProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  const leadStatusDropdownOptions = getLeadStatusDropdownOptions(translations);

  return (
    <Paper className="leads-form-wrapper" padding={deviceData.isMobile ? 'sm' : 'md'}>
      <Typography strong as={deviceData.isMobile ? 'h4' : 'h2'}>
        {translations.dashboardLeadHeaderForm}
      </Typography>
      <div className="leads-form-wrapper__form">
        <TextInputWithController
          controllerProps={{
            control,
            name: 'name',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
              maxLength: {
                value: 100,
                message: translations.formLeadNameMaxLengthError,
              },
              validate: {
                startsOrEndsWithWhitespace: startsOrEndsWithWhitespace(
                  translations.commonFormFieldErrorStartsOrEndsWithWhitespace,
                ),
                containEntersOrSpaces: containEntersOrSpaces(
                  translations.commonFormFieldErrorContainEntersOrSpaces,
                ),
              },
            },
          }}
          inputProps={{
            autoComplete: 'name',
            disabled: !isEditMode,
            label: translations.formLeadNameLabel,
          }}
        />
        <PhoneInputWithController
          controllerProps={{
            control,
            name: 'phoneNumber',
          }}
          emptyValueErrorMessage={translations.commonFormFieldErrorRequired}
          invalidPhoneNumberErrorMessage={translations.formLeadPhoneNumberPatternMatchError}
          phoneInputProps={{
            inputProps: {
              fullWidth: true,
              variant: 'standard',
              disabled: !isEditMode,
              label: translations.formLeadPhoneNumberLabel,
            },
            jumpCursorToEnd: true,
            countryCodeEditable: false,
            disableDropdown: !isEditMode,
            country: DEFAULT_PHONE_INPUT_COUNTRY,
            onlyCountries: PHONE_INPUT_ONLY_COUNTRIES,
          }}
          setError={setError}
        />
        <DropdownWithController
          controllerProps={{
            control,
            name: 'leadStatus',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
          dropdownProps={{
            options: leadStatusDropdownOptions,
            labelId: 'lead-status-dropdown-label',
            label: translations.formLeadStatusLabel,
          }}
          formControlProps={{
            disabled: !isEditMode,
          }}
        />
      </div>
    </Paper>
  );
};
