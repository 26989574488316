import { addPlusToPhoneNumber } from '@utils';

import { IUpsertAccountFormFields } from '@interfaces';

export const transformUpsertAccountFormData = (accountData: IUpsertAccountFormFields) => ({
  contactInfo: {
    gender: 'NotDisclosed',
    email: accountData.account.email,
    fullName: accountData.account.fullName,
    phoneNumber: addPlusToPhoneNumber(accountData.account.phoneNumber),
    phoneNumberAlternative: addPlusToPhoneNumber(accountData.account.phoneNumber),
  },
  primaryAddress: {
    region: accountData.address.region,
    street: accountData.address.street,
    zipCode: accountData.address.zipCode,
    district: accountData.address.district,
    houseNo: accountData.address.houseNumber,
    postOfficeCity: accountData.address.city,
  },
});
