import { AxiosResponse } from 'axios';
import { add, endOfMonth, startOfMonth, sub } from 'date-fns';

import { buildApiUrl, prepareAxiosConfigWithAccessToken } from '@utils';

import { axiosInstance } from '@infrastructure';

import { EApiEndpoint } from '@enums';

import { IApiCalendarResponse } from '@interfaces';

import { IGetCalendarEventsAttributes } from './get-calendar-events.types';

export const getCalendarEventsAction =
  (getAccessToken: TGetAccessToken, loggedUserId: string | undefined) =>
  async ({
    selectedDate,
    userId,
  }: IGetCalendarEventsAttributes): Promise<AxiosResponse<IApiCalendarResponse>> => {
    const accessToken = await getAccessToken();

    const correctUserId = userId || loggedUserId;
    const end = add(endOfMonth(selectedDate), { weeks: 1 }).toISOString();
    const start = sub(startOfMonth(selectedDate), { weeks: 1 }).toISOString();

    return axiosInstance.get(
      buildApiUrl(EApiEndpoint.CALENDAR, { end, start, userId: correctUserId }),
      prepareAxiosConfigWithAccessToken(accessToken),
    );
  };
