import { LabelWithAvatar, useDeviceDetect } from '@amperio/amperio-ui';

import { getAvatarApiUrl, useQuery } from '@utils';

import { GET_USERS_NAMES_ACTION_KEY, getUsersAction } from '@infrastructure';

export const useUsersActionQuery = () => {
  const { deviceData } = useDeviceDetect();

  const { data: usersData, isLoading: isUsersDataLoading } = useQuery(
    GET_USERS_NAMES_ACTION_KEY,
    getUsersAction,
    {
      staleTime: Number.POSITIVE_INFINITY,
    },
  );

  const ownersDropdownOptions =
    usersData?.data
      ?.filter((_record) => !_record.isGhost)
      .map((_record) => ({
        value: _record.id,
        label: (
          <LabelWithAvatar
            ellipsis
            as={deviceData.isMobile ? 'span' : 'h5'}
            src={getAvatarApiUrl(_record.name)}
          >
            {_record.name}
          </LabelWithAvatar>
        ),
      })) || [];

  const ownersDropdownOptionsWithoutAvatars =
    usersData?.data
      ?.filter((_record) => !_record.isGhost)
      .map((_record) => ({
        value: _record.id,
        label: _record.name,
      })) || [];

  const findUserById = (userId: string) =>
    usersData?.data?.find((_option) => _option.id === userId);

  return {
    findUserById,
    isUsersDataLoading,
    ownersDropdownOptions,
    usersData: usersData?.data,
    ownersDropdownOptionsWithoutAvatars,
  };
};
