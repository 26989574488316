import {
  DropdownWithController,
  Paper,
  TextInputWithController,
  Typography,
  useDeviceDetect,
} from '@amperio/amperio-ui';

import { useTranslation } from '@utils';
import { containEntersOrSpaces, startsOrEndsWithWhitespace } from '@utils/react-hook-form';

import { getStatusAfterEventDropdownOptions, translationStrings } from './summary-form.defaults';
import { ISummaryFormProps } from './summary-form.types';

import './summary-form.styles.scss';

export const SummaryForm = ({ control, isEditMode }: ISummaryFormProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  const statusAfterEventDropdownOptions = getStatusAfterEventDropdownOptions(translations);

  return (
    <Paper className="summary-form-wrapper" padding={deviceData.isMobile ? 'sm' : 'md'}>
      <Typography strong as={deviceData.isMobile ? 'h3' : 'h2'}>
        {translations.formSummaryHeader}
      </Typography>
      <div className="summary-form-wrapper__form">
        <DropdownWithController
          controllerProps={{
            control,
            name: 'summary.eventStatus',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
          dropdownProps={{
            labelId: 'summary-dropdown-label',
            options: statusAfterEventDropdownOptions,
            label: translations.formSummaryStatusLabel,
          }}
          formControlProps={{
            disabled: !isEditMode,
          }}
        />
        <TextInputWithController
          controllerProps={{
            control,
            name: 'summary.notes',
            rules: {
              maxLength: {
                value: 500,
                message: translations.formSummaryNotesMaxLengthError,
              },
              validate: {
                startsOrEndsWithWhitespace: startsOrEndsWithWhitespace(
                  translations.commonFormFieldErrorStartsOrEndsWithWhitespace,
                ),
                containEntersOrSpaces: containEntersOrSpaces(
                  translations.commonFormFieldErrorContainEntersOrSpaces,
                ),
              },
            },
          }}
          inputProps={{
            multiline: true,
            disabled: !isEditMode,
            autoComplete: 'summary.notes',
            label: translations.formSummaryNotesLabel,
          }}
        />
      </div>
    </Paper>
  );
};
