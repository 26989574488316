import {
  DropdownWithController,
  Paper,
  TextInputWithController,
  Typography,
  useDeviceDetect,
} from '@amperio/amperio-ui';

import { useTranslation } from '@utils';

import { EProductCategory } from '@enums';

import {
  getCurrentTypeOfHeatingDropdownOptions,
  getInstallationSiteDropdownOptions,
  getLevelOfInsulationDropdownOptions,
  getMonthOfTheGivenBillDropdownOptions,
  getPaymentMethodDropdownOptions,
  getProductCategoriesDropdownOptions,
  getRoofOrGroundDropdownOptions,
  translationStrings,
} from './product-categories-form.defaults';
import { IProductCategoriesFormProps } from './product-categories-form.types';

import './product-categories-form.styles.scss';

export const ProductCategoriesForm = ({
  control,
  isEditMode,
  watch,
}: IProductCategoriesFormProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  const selectedProductCategories = watch('productCategories.productCategories');

  const isHeatingFoils = selectedProductCategories.includes(EProductCategory.HEATING_FOILS);
  const isHeatingPumps = selectedProductCategories.includes(EProductCategory.HEATING_PUMPS);
  const isPhotovoltaics = selectedProductCategories.includes(EProductCategory.PHOTOVOLTAICS);

  const roofOrGroundDropdownOptions = getRoofOrGroundDropdownOptions(translations);
  const paymentMethodDropdownOptions = getPaymentMethodDropdownOptions(translations);
  const installationSiteDropdownOptions = getInstallationSiteDropdownOptions(translations);
  const productCategoriesDropdownOptions = getProductCategoriesDropdownOptions(translations);
  const levelOfInsulationDropdownOptions = getLevelOfInsulationDropdownOptions(translations);
  const monthOfTheGivenBillDropdownOptions = getMonthOfTheGivenBillDropdownOptions(translations);
  const currentTypeOfHeatingDropdownOptions = getCurrentTypeOfHeatingDropdownOptions(translations);

  return (
    <Paper className="product-categories-form-wrapper" padding={deviceData.isMobile ? 'sm' : 'md'}>
      <Typography strong as={deviceData.isMobile ? 'h3' : 'h2'}>
        {translations.formProductCategoriesHeader}
      </Typography>
      <div className="product-categories-form-wrapper__form">
        <DropdownWithController
          controllerProps={{
            control,
            name: 'productCategories.productCategories',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
          dropdownProps={{
            multiple: true,
            options: productCategoriesDropdownOptions,
            labelId: 'product-categories-dropdown-label',
            label: translations.formProductCategoriesLabel,
          }}
          formControlProps={{
            disabled: !isEditMode,
          }}
        />
        {selectedProductCategories.length > 0 ? (
          <DropdownWithController
            controllerProps={{
              control,
              name: 'productCategories.paymentMethod',
            }}
            dropdownProps={{
              options: paymentMethodDropdownOptions,
              labelId: 'payment-method-dropdown-label',
              label: translations.formProductCategoriesPaymentMethodLabel,
            }}
            formControlProps={{
              disabled: !isEditMode,
            }}
          />
        ) : null}
        {isHeatingPumps || isHeatingFoils ? (
          <>
            <TextInputWithController
              controllerProps={{
                control,
                name: 'productCategories.houseArea',
                rules: {
                  min: {
                    value: 80,
                    message: translations.formProductCategoriesHouseAreaMinError,
                  },
                  max: {
                    value: 5000,
                    message: translations.formProductCategoriesHouseAreaMaxError,
                  },
                },
              }}
              inputProps={{
                type: 'number',
                disabled: !isEditMode,
                autoComplete: 'productCategories.houseArea',
                label: translations.formProductCategoriesHouseAreaLabel,
              }}
            />
            <DropdownWithController
              controllerProps={{
                control,
                name: 'productCategories.levelOfInsulation',
              }}
              dropdownProps={{
                options: levelOfInsulationDropdownOptions,
                labelId: 'level-of-insulation-dropdown-label',
                label: translations.formProductCategoriesLevelOfInsulationLabel,
              }}
              formControlProps={{
                disabled: !isEditMode,
              }}
            />
            <DropdownWithController
              controllerProps={{
                control,
                name: 'productCategories.currentTypeOfHeating',
              }}
              dropdownProps={{
                options: currentTypeOfHeatingDropdownOptions,
                labelId: 'current-type-of-heating-dropdown-label',
                label: translations.formProductCategoriesCurrentTypeOfHeatingLabel,
              }}
              formControlProps={{
                disabled: !isEditMode,
              }}
            />
          </>
        ) : null}
        {isPhotovoltaics ? (
          <>
            <DropdownWithController
              controllerProps={{
                control,
                name: 'productCategories.installationSite',
              }}
              dropdownProps={{
                multiple: true,
                options: installationSiteDropdownOptions,
                labelId: 'installation-site-dropdown-label',
                label: translations.formProductCategoriesInstallationSiteLabel,
              }}
              formControlProps={{
                disabled: !isEditMode,
              }}
            />
            <DropdownWithController
              controllerProps={{
                control,
                name: 'productCategories.roofOrGround',
              }}
              dropdownProps={{
                options: roofOrGroundDropdownOptions,
                labelId: 'roof-or-ground-dropdown-label',
                label: translations.formProductCategoriesRoofOrGroundLabel,
              }}
              formControlProps={{
                disabled: !isEditMode,
              }}
            />
            <TextInputWithController
              controllerProps={{
                control,
                name: 'productCategories.roofArea',
                rules: {
                  min: {
                    value: 20,
                    message: translations.formProductCategoriesRoofAreaMinError,
                  },
                  max: {
                    value: 1000,
                    message: translations.formProductCategoriesRoofAreaMaxError,
                  },
                },
              }}
              inputProps={{
                type: 'number',
                disabled: !isEditMode,
                autoComplete: 'productCategories.roofArea',
                label: translations.formProductCategoriesRoofAreaLabel,
              }}
            />
            <TextInputWithController
              controllerProps={{
                control,
                name: 'productCategories.monthlyElectricBill',
                rules: {
                  min: {
                    value: 20,
                    message: translations.formProductCategoriesMonthlyElectricBillMinError,
                  },
                  max: {
                    value: 5000,
                    message: translations.formProductCategoriesMonthlyElectricBillMaxError,
                  },
                },
              }}
              inputProps={{
                type: 'number',
                disabled: !isEditMode,
                autoComplete: 'productCategories.monthlyElectricBill',
                label: translations.formProductCategoriesMonthlyElectricBillLabel,
              }}
            />
            <DropdownWithController
              controllerProps={{
                control,
                name: 'productCategories.monthOfTheGivenBill',
              }}
              dropdownProps={{
                options: monthOfTheGivenBillDropdownOptions,
                labelId: 'month-of-the-given-bill-dropdown-label',
                label: translations.formProductCategoriesMonthOfTheGivenBillLabel,
              }}
              formControlProps={{
                disabled: !isEditMode,
              }}
            />
          </>
        ) : null}
      </div>
    </Paper>
  );
};
