export default {
  // success - lead
  'notifications.success.lead.assigned': 'Lead has been assigned to a sales advisor',
  'notifications.success.lead.changes.saved': 'Changes to the lead data have been saved',

  // success - account
  'notifications.success.account.created': 'A new user account has been created',
  'notifications.success.account.changes.saved': 'Changes to the account have been saved',

  // success - absence
  'notifications.success.absence.set': 'The absence has been set',
  'notifications.success.absence.delete': 'The absence has been deleted',

  // success - event
  'notifications.success.event.created': 'A new event has been added to the calendar',
  'notifications.success.event.details.changes.saved': 'Event details have been updated',

  // success - general
  'notifications.success.general.changes.saved': 'All changes have been saved',
};
