export const translationStrings = [
  'dashboard.accounts.section.events.table.header',
  'dashboard.accounts.section.events.table.date',
  'dashboard.accounts.section.events.table.products',
  'dashboard.accounts.section.events.table.type',
  'dashboard.accounts.section.events.table.status',
  'dashboard.accounts.section.events.table.notes',
  'common.table.no.data',
  'common.event.status.sale',
  'common.event.status.missed',
  'common.event.status.planned',
  'common.event.status.rejected',
  'common.event.status.resignation',
  'common.event.status.bid.submitted',
  'common.event.status.sales.opportunity',
] as const;
