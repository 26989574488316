import { IModalProps, Modal as ModalAtom } from '@amperio/amperio-ui';
import { useCallback, useState } from 'react';

export const useModal = (defaultIsOpenState: boolean = false, preventClose: boolean = false) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpenState);
  const show = () => setIsOpen(true);
  const hide = () => setIsOpen(false);

  const Modal = ({ children, onClose, ...restProps }: Omit<IModalProps, 'open'>): JSX.Element => {
    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (!preventClose) {
        hide();
      }

      if (onClose) {
        onClose(event, reason);
      }
    };

    return (
      <ModalAtom open={isOpen} onClose={handleClose} {...restProps}>
        {children}
      </ModalAtom>
    );
  };

  const memoizedModal = useCallback(Modal, [Modal]);

  return {
    show,
    hide,
    isOpen,
    Modal: memoizedModal,
  };
};
