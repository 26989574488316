import { Button, LogoutIcon } from '@amperio/amperio-ui';
import { useAuth0 } from '@auth0/auth0-react';
import { StringParam, useQueryParams } from 'use-query-params';

import { Error } from '@domain';

import { isSomeEnum, mapErrorCodeToTranslationKey, useLocale, useTranslation } from '@utils';

import { ECustomHttpCode, ERoutes } from '@enums';

import { translationStrings } from './forbidden.defaults';

const redirectToHomepage = () => window.location.assign(`/${ERoutes.DASHBOARD}`);

export const ForbiddenPage = (): JSX.Element => {
  const [queryParams] = useQueryParams({
    apiUrl: StringParam,
    message: StringParam,
    errorCode: StringParam,
    showLogoutButton: StringParam,
    showBackToHomepageButton: StringParam,
  });

  const { logout } = useAuth0();
  const { formatMessage } = useLocale();
  const translations = useTranslation(translationStrings);

  const isValidErrorCode = isSomeEnum(ECustomHttpCode)(queryParams.errorCode);
  const errorCodeTranslation = isValidErrorCode
    ? formatMessage({
        id: `error.page.message.${mapErrorCodeToTranslationKey(queryParams.errorCode)}`,
      })
    : '';

  const accessErrorMessage = queryParams.apiUrl
    ? translations.errorPageMessageAccessError.replace('[apiUrl]', queryParams.apiUrl)
    : '';

  const message =
    accessErrorMessage ||
    errorCodeTranslation ||
    queryParams.message ||
    translations.errorPageForbiddenMessage;

  return (
    <Error code="403" message={message}>
      {queryParams.showBackToHomepageButton === 'true' ? (
        <Button variant="contained" onClick={redirectToHomepage}>
          {translations.errorPageForbiddenBackToHomepageButton}
        </Button>
      ) : null}
      {queryParams.showLogoutButton === 'true' ? (
        <Button startIcon={<LogoutIcon />} variant="outlined" onClick={() => logout()}>
          {translations.commonButtonsLogout}
        </Button>
      ) : null}
    </Error>
  );
};
